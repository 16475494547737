<template>
  <div class="x-checkbox" :data-help="computedHelp">
    <v-checkbox
        :input-value="value"
        :label="label"
        :disabled="disabled"
        hide-details="auto"
        @change="$emit('input', $event)"/>
    <HelpButton :id="id" :help="computedHelp"/>
  </div>
</template>

<script>
import HelpButton from '@/components/basic/HelpButton.vue';

export default {
  name: 'XCheckbox',
  components: {HelpButton},
  props: {
    value: Boolean,
    label: String,
    disabled: Boolean,
    id: String,
    help: String,
  },
  computed: {
    computedHelp() {
      if (this.help) return this.help;
      return this.id;
    },
  },
};
</script>

<style scoped>
.x-checkbox {
  display: flex;
  align-items: center;
}

.x-checkbox >>> .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>