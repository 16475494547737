<template>
  <div>
    <slot :valid="valid"/>
  </div>
</template>

<script>
export default {
  name: 'FormDialogContent',
  provide() {
    const formState = {};

    Object.defineProperty(formState, 'disabled', {
      enumarable: true,
      get: () => this.formState.disabled,
    });

    return {
      formState,
    };
  },
  data() {
    return {
      formState: {
        disabled: this.disabled,
      },
    };
  },
  props: {
    valid: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    disabled(newVal) {
      this.$set(this.formState, 'disabled', newVal);
    },
  },
};
</script>

<style scoped>

</style>