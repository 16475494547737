<template>
  <InputRow>
    <XSwitch label="Enabled" v-model="dataValue.enabled" @input="emitInput"/>
    <XSelect
        label="Restart Type" v-model="dataValue.restart.type" :items="['always']" @input="emitInput" required/>
    <DurationTextField
        label="Restart Timer" v-model="dataValue.restart.timer" type="range" @input="emitInput" required/>
    <PortTextField
        v-if="service.value === 'iperf' || service.value === 'udpst'"
        label="Port"
        v-model="dataValue.port"
        @input="emitInput"
        validate-immediately
        required/>
  </InputRow>
</template>

<script>
import XSwitch from '@/components/basic/XSwitch.vue';
import XSelect from '@/components/basic/XSelect.vue';
import InputRow from '@/components/basic/InputRow.vue';
import DurationTextField from '@/components/extended/DurationTextField.vue';
import PortTextField from '@/components/extended/PortTextField.vue';
import {deepCopy} from '@/js/general';

export default {
  name: 'ExplorerLocalServiceForm',
  components: {
    PortTextField,
    DurationTextField,
    InputRow,
    XSelect,
    XSwitch,
  },
  props: {
    value: Object,
    service: Object,
  },
  data() {
    return {
      dataValue: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.dataValue = deepCopy(value);
      },
    },
  },
  methods: {
    emitInput() {
      this.$emit('input', this.dataValue);
    },
  },
};
</script>

<style scoped>

</style>