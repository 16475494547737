import { ref } from "vue"
import { useBroadcastChannel } from "@/composition/web-api/use-broadcast-channel"
import { authService } from "@/api"
import { relogin } from "@/auth-tools";
import { useStore } from "@/store"

// generated only once
const ID = Math.random().toString(36).slice(2);

export function useSwitchUser() {
  const MSG = "user-switch";
  const _subs = ref([]);
  const _run = (event) => {
    const { msg, id } = event.data;
    if (msg !== MSG || id === ID) {
      return;
    }

    for (const sub of _subs.value) {
      sub();
    }
  }
  const subscribe = (fn) => {
    _subs.value.push(fn);
  };
  const { send } = useBroadcastChannel({ name: "user-switch-channel", onmessage: _run });

  const notifyUserSwitch = () => {
    send({ msg: MSG, id: ID })
  };

  const store = useStore();
  const doUserSwitch = async ({ userId }) => {
    const { accessToken } = await authService.v1.switchUser(userId.toString());
    relogin({ access_token: accessToken, refresh_token: "foo" });
    await store.dispatch("fetchEssentialData", { isRefresh: true });
    notifyUserSwitch();
  };

  return {
    subscribeToUserSwitch: subscribe,
    notifyUserSwitch,
    doUserSwitch,
  };
}
