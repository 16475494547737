<template>
  <div>
    <v-row>
      <v-spacer> </v-spacer>
      <v-col>
        <associatedOption
          type="select"
          :fieldAttributes="{
            type: 'select',
            associatedOption: typeSelector,
            setEmpty: false,
            sort: false,
            name: 'Type',
            ignoreEmptyAdd: true,
          }"
          v-model="dataType"
          :hideDetails="true"
          ref="type"
        ></associatedOption>
      </v-col>
      <v-col>
        <associatedOption
          type="select"
          :fieldAttributes="{
            type: 'select',
            associatedOption: sourceSelector,

            sort: false,
            name: 'Source',
            setEmpty: false,
            ignoreEmptyAdd: true,
          }"
          v-model="dataSource"
          :hideDetails="true"
          ref="source"
        ></associatedOption>
      </v-col>
      <v-col>
        <searchInput
          v-model="localSearch"
          key="explorerLogSearch"
        ></searchInput>
      </v-col>

      <v-col cols="1" class="ml-3" style="min-width: 200px">
        <datePickerExtend
          v-model="info.data.timerange"
          @updateUnixDates="updateDates"
          :interval="refreshInterval"
          ref="datePicker"
        ></datePickerExtend>
      </v-col>
      <v-col id="dashboardRefreshInterval_container" style="max-width: 200px">
        <associatedOption
          type="select"
          :fieldAttributes="{
            type: 'select',
            associatedOption: [
              [0, 'off'],
              [10, '10s'],
              [30, '30s'],
              [60, '1m'],
              [120, '2m'],
              [300, '5m'],
              [600, '10m'],
            ],
            setEmpty: false,
            sort: false,
            name: 'Refresh',
            ignoreEmptyAdd: true,
          }"
          v-model="refreshInterval"
          :hideDetails="true"
        ></associatedOption>
      </v-col>
    </v-row>
    <v-data-table
      :headers="HEADERS"
      :items="filteredStatusLog"
      :loading="loading"
      sort-by="datetime"
      :sort-desc="true"
      :search="localSearch"
    >
      <template v-slot:[`item.datetime`]="{ item }">
        {{ formatTime(item.datetime) }}
      </template>
    </v-data-table>
    <v-row>
      <v-col>
        <primaryTextfield
          label="Message"
          v-model="message"
          :fieldAttrInput="{ disabled: messageLoading, id: 'manuelLogInput' }"
        ></primaryTextfield>
      </v-col>
      <v-col>
        <primaryButton
          label="Add Manual Entry"
          icon="mdi-send"
          @click="addStatusLog"
          type="button"
          color="primary"
          id="send_button"
          data-type="send_button"
          :loading="messageLoading"
          :disabled="disabled"
        ></primaryButton>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import searchInput from "@/commonComponents/searchInput.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
import datePickerExtend from "@/commonComponents/datePickerExtend.vue";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import {removeDuplicateAoA} from "@/js/helper";
import requests from "@/js/requests";

const HEADERS = [
  {
    text: "Date",
    value: "datetime",
    width: 200,
  },
  { text: "Type", value: "type", width: 150 },
  { text: "Source", value: "source", width: 200 },
  { text: "Messsage", value: "msg" },
];

const DAY = 86400;

export default defineComponent({
  components: {
    primaryButton,
    primaryTextfield,
    datePickerExtend,
    associatedOption,
    searchInput,
  },

  props: {
    explorerName: String,
    disabled: Boolean
  },

  setup() {
    const untilTime = Math.floor(Date.now() / 1000);
    const fromTime = untilTime - DAY;
    const pickerOptions = reactive({
      period: 0.084,
      untilTime,
      fromTime,
    });

    return {
      HEADERS,
      pickerOptions,
    }
  },

  data() {
    return {
      dataType: "",
      dataSource: "",
      localSearch: "",
      interval: null,
      messageLoading: false,
      message: "",
      statusLog: [],
      filteredStatusLog: [],
      loading: true,
      manuallyChanged: false,
      info: {
        data: {
          timerange: "Last 7 days",
          begin: "now-24h",
          end: "now",
        },
      },
      refreshInterval: 0,
    };
  },
  created() {
    this.getStatusLog();
  },
  watch: {
    statusLog() {
      this.updateFilter();
    },
    dataType() {
      this.updateFilter();
    },
    dataSource() {
      this.updateFilter();
    },
  },
  beforeDestroy() {
    clearTimeout(this.interval);
  },
  computed: {
    typeSelector() {
      let typeSelector = [["", "All"]];
       this.statusLog.forEach((element) => {
         let value = element.type;
         if(value=='manual'){
           value = "Manual Message";
         }
           typeSelector.push([element.type,value]);
      });
      return removeDuplicateAoA(typeSelector);
    },
    sourceSelector() {
      let typeSelector = [["", "All"]];
      this.statusLog.forEach((element) => {
        if (!typeSelector.includes(element.source)) {
          typeSelector.push(element.source);
        }
      });
      return typeSelector;
    },
  },
  methods: {
    removeDuplicateAoA,

    formatTime(timeStamp) {
      const date = new Date(timeStamp);
      const hours = "0" + date.getHours();
      const minutes = "0" + date.getMinutes();
      const seconds = "0" + date.getSeconds();
      const dateString = date.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return (
        dateString +
        " " +
        hours.substr(-2) +
        ":" +
        minutes.substr(-2) +
        ":" +
        seconds.substr(-2)
      );
    },
    updateFilter() {
      this.filteredStatusLog = [];

      this.statusLog.forEach((element) => {
        if ((element.type == this.dataType || this.dataType == "") &&
          (element.source == this.dataSource || this.dataSource == "")) {
          if(element.type=='manual'){
            element.type = 'Manual Message';
          }
          this.filteredStatusLog.push(element);
        }
      });
    },

    updateDates(from, to) {
      this.pickerOptions.untilTime = to;
      this.pickerOptions.fromTime = from;
      this.getStatusLog();
    },

    fetchStatusLog() {
      return requests.phpServicePostRequest(
        "?f=administration&f2=explorers",
        {
          function: "getStatusLog",
          name: this.explorerName,
          from: this.pickerOptions.fromTime,
          to: this.pickerOptions.untilTime,
        }
      );
    },

    async getStatusLog() {
      this.loading = true;
      clearTimeout(this.interval);
      try {
        const resp = await this.fetchStatusLog();
        if (resp?.data?.result?.statusLog) {
          this.statusLog = resp.data.result.statusLog;
        }
      } finally {
        this.loading = false;
        this.interval = setTimeout(() => {
          this.getStatusLog();
        }, 60000);
      }
    },

    fetchAddStatusLog(to) {
      return requests.phpServicePostRequest(
        "?f=administration&f2=explorers",
        {
          function: "addStatusLog",
          name: this.explorerName,
          message: this.message,
          from: this.pickerOptions.fromTime,
          to: to,
        }
      );
    },

    async addStatusLog() {
      this.messageLoading = true;
      const to = Date.now() / 1000;
      this.pickerOptions.untilTime = to;
      try {
        const resp = await this.fetchAddStatusLog(to);

        if (resp?.data?.result?.statusLog) {
          this.statusLog = resp.data.result.statusLog;
        }
      } finally {
        this.message = "";
        this.messageLoading = false;
      }
    },
  },
});
</script>
