<template>
  <div class="primary-text-field">
    <v-text-field
      :placeholder="placeholder"
      v-model="inputValue"
      :type="type"
      :name="name"
      v-bind="fieldAttrInput"
      :label="localLabel"
      outlined
      dense
      :counter="counter"
      :rules="rules"
      @change="emitChange(...arguments)"
      @focus="$emit('focus')"
      @click="$emit('click')"
      :id="id"
      :error-messages="errors"
      :disabled="disabled"
      @input="errors = []"
      oninput="if(this.type=='number' && this.value < 0) this.value = 0;"
      :hide-details="
        typeof this.hideDetails != 'undefined' && this.hideDetails === true
          ? true
          : false
      "
    ></v-text-field>
    <div
      :class="
        typeof this.hideDetails != 'undefined' && this.hideDetails === true
          ? 'mb-2'
          : ''
      "
    ></div>
    <HelpButton  :id="id"/>
  </div>
</template>
<script>
import { getRules } from "@/js/validators.js";
import { getPlaceholder, getFieldId } from "@/js/helper.js";
import HelpButton from "@/components/basic/HelpButton.vue";
export default {
  name: "primaryTextfield",
  components: {HelpButton},
  data() {
    return {
      errors: [],
      // checkError:this.checkValue(),
      //checkColor: this.checkRequiredColor(),
    };
  },
  watch: {
    inputValue() {
      this.errors = [];
    },
  },
  created() {
    let errors = [];
    this.rules.forEach((rule) => {
      let text = rule(this.inputValue);
      if (typeof text == "string") {
        errors.push(text);
      }
    });
    this.errors = errors;
  },
  computed: {
    rules() {
      return getRules(this.localAttrs, this.templateContent, this.name, this);
    },
    counter() {
      let counter = this.fieldAttrInput.maxlength;
      if (this.disabled) {
        counter = false;
      }
      return counter;
    },
    disabled() {
     // console.log(this.name )
      let disabled = false;
      if (
        (typeof this.templateContent != "undefined" &&
          typeof this.templateContent.disableAllInputs != "undefined" &&
          this.templateContent.disableAllInputs == true && this.name!="c_change_message") ||
        this.fieldAttrInput.disabled == true
      ) {
        disabled = true;
      }
      return disabled;
    },
    id() {
      return getFieldId(this.fieldAttrInput, this.templateContent, this.name);
    },
    localLabel() {
      let localLabel = this.label;
      /* if (
        typeof this.fieldAttrInput.class != "undefined" &&
        this.fieldAttrInput.class.includes("required") &&
        localLabel != ""
      ) {
        localLabel = localLabel + "*";
      }*/
      return localLabel;
    },
    localAttrs() {
      let localAttrs = this.fieldAttrInput;
      if (this.type == "number") {
        localAttrs.class = localAttrs.class + " number";
      }
      return localAttrs;
    },
    inputValue: {
      get: function () {
        return this.value;
      },
      set: function (inputValue) {
        if (inputValue != "") {
          //this.checkError=false;
          //this.checkColor = "";
        }
        this.$store.commit("setFieldDataMutated", true);
        this.$emit("input", inputValue);
      },
    },
    placeholder() {
      return getPlaceholder(this.fieldAttrInput);
    },
    hideDetailsTxt() {
      let result = false;
      if (typeof this.hideDetails != "undefined" && this.hideDetails === true) {
        result = true;
      }
      return result;
    },
  },
  methods: {
    /*    checkRequiredColor() {
      let className = "";
      if (
        typeof this.inputValue == "undefined" &&
        this.fieldAttrInput != null &&
        typeof this.fieldAttrInput.class != "undefined" &&
        this.fieldAttrInput.class.includes("required")
      ) {
        if (typeof this.value == "undefined" || this.value == "") {
          className = "requiredColor";
        }
      }
      if (typeof this.inputValue != "undefined" && this.inputValue != "") {
        className = "";
      }
      console.log("inputValue", this.inputValue);
      console.log("value", this.value);
      console.log(this.fieldAttrInput.class);
      console.log("className--", className);
      return className;
    },*/
    /* checkValue(){
      var type = false;
      console.log(this.inputValue);
      console.log(this.value);
      console.log(this.fieldAttrInput.class);
      if(typeof this.inputValue=="undefined" &&
        typeof this.fieldAttrInput.class != "undefined" &&
        this.fieldAttrInput.class.includes("required")
      ){
        if( typeof this.value=="undefined"  ||   this.value==""){
          type = true;
        }
      }
      if( typeof this.inputValue!="undefined"  &&  this.inputValue!=""){
        type = false;
      }
      console.log("check Error Text",type);
      return type;
    },*/
    getRules: getRules,
    emitChange(value) {
      //console.log(value);
      this.$emit("change", value);
    },
  },
  props: {
    hideDetails: { type: Boolean, default: false },
    type: { type: String, default: "text" },
    name: {},
    fieldAttrInput: {
      default() {
        return {};
      },
    },
    label: {},
    value: {},
    templateContent: {},
  },
};
</script>
<style scoped>
.primary-text-field {
  display: flex;
}
.primary-text-field >>> .help-button {
  margin-top: 2px;
}
</style>