<script setup>
import { inject, useAttrs, computed, defineProps } from "vue";
import XBtn from '@/components/basic/XBtn.vue';
import { useHelpStore } from "@/composition/help/use-help-store";

const props = defineProps({
  help: {
    type: String,
  }
});

const attrs = useAttrs();

const helpId = computed(() => {
  return props.help || attrs["data-help"] || attrs.id;
})

const { helpList } = useHelpStore();

const isVisible = computed(() => {
  return helpList.value.some((id) => id === helpId.value);
})

const { openDialog } = inject("DialogsRoot");

const openHelpDialog = async () => {
  await openDialog("HelpDialog", { helpId: helpId.value }, { isUiDialog: true });
}
</script>

<template>
  <XBtn
    v-if="isVisible"
    icon="mdi-help-circle"
    @click.stop="() => openHelpDialog()"
  />
</template>

