<template>
  <div class="date-time-range-and-refresh">
    <DateTimeRange
      :value="value.range"
      @input="emitInput('range', $event)"
    />

    <XSelect
        :value="value.refresh"
        label="Refresh"
        :items="refreshTimes"
        @input="emitInput('refresh', $event)"
        class="refresh-select"
        :autocomplete="false"
        y-offset/>
  </div>
</template>

<script>
import XSelect from '@/components/basic/XSelect';
import DateTimeRange from '@/components/basic/DateTimeRange';
import {getDefaultRangeAndRefresh, getURLSearchParam, setURLSearchParam} from '@/js/general';

export default {
  name: 'DateTimeRangeAndRefresh',
  components: {
    DateTimeRange,
    XSelect,
  },
  props: {
    value: Object,
    save: Boolean,
  },
  data() {
    return {
      selectItems: ['Last 24 hours'],
      refreshTimes: [
        {
          text: 'off',
          value: 0,
        },
        {
          text: '30s',
          value: 30,
        },
        {
          text: '1m',
          value: 60,
        },
        {
          text: '2m',
          value: 120,
        },
        {
          text: '5m',
          value: 300,
        },
        {
          text: '10m',
          value: 600,
        },
      ],
      menu: false,
    };
  },
  created() {
    let emitted = false;
    if (this.save) {
      const rangeAndRefresh = getURLSearchParam('range_and_refresh');
      if (rangeAndRefresh) {
        const rangeAndRefreshValues = rangeAndRefresh.split(',');
        this.emitInput(
            'both',
            {
              from: new Date(parseInt(rangeAndRefreshValues[0])),
              to: new Date(parseInt(rangeAndRefreshValues[1])),
              seconds: parseInt(rangeAndRefreshValues[2]),
            },
            parseInt(rangeAndRefreshValues[3]),
        );
        emitted = true;
      }
    }
    if(!this.value || this.value.range === undefined || this.value.refresh === undefined && !emitted) {
      this.$emit('input', getDefaultRangeAndRefresh())
    }
  },
  methods: {
    emitInput(type, value, secondValue) {
      let newValue = {};
      if (type === 'range') {
        newValue = {
          ...this.value,
          range: value,
        };
      } else if (type === 'refresh') {
        newValue = {
          ...this.value,
          refresh: value,
        };
      } else if (type === 'both') {
        newValue = {
          range: value,
          refresh: secondValue,
        };
      }

      if (this.save) {
        const range = newValue.range;
        setURLSearchParam(
            'range_and_refresh',
            `${range.from ? range.from.getTime() : 0},${range.to ? range.to.getTime() : 0},${range.seconds ? range.seconds : 0},${newValue.refresh}`,
        );
      }
      this.$emit('input', newValue);
    },
  },
};
</script>

<style scoped>
.date-time-range-and-refresh {
  display: flex;
  gap: 10px;
  align-items: center;
}

.refresh-select {
  width: 116px;
}
</style>
