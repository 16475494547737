import Vue from "vue";
import VueRouter from "vue-router";
import { translatePhpRouteToVueRoute } from "@/js/general";
import { tokenRefresher } from "@/auth-tools";
import { routes } from "./routes";

Vue.use(VueRouter);


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const originalPush = router.push;
const originalReplace = router.replace;

const _errorHandler = (err) => {
  if (err.name !== "NavigationDuplicated") {
    throw err;
  }
};

router.push = function push(location) {
  return originalPush.call(this, location).catch(_errorHandler);
};

router.replace = function replace(location) {
  return originalReplace.call(this, location).catch(_errorHandler);
};

router.beforeEach((to, _, next) => {
  let newStyleRoute = "";

  if (to.query.f) {
    newStyleRoute = translatePhpRouteToVueRoute(to)
  }

  const isAuth = tokenRefresher.isAuthenticated;

  if ((to.name === "login" || newStyleRoute === "/login" || to.name==="register" || newStyleRoute === "/register" ) && isAuth) {
    next({
      name: "home",
    });
    return;
  }

  if (
    (!newStyleRoute && to.matched.some((record) => record.meta.isAuthRequired) && !isAuth) ||
      newStyleRoute === "/logout" ||
      to.name === "logout"
  ) {
    const isHomeOrLogin =
      to.name === "login" ||
      to.name === "home" ||
      newStyleRoute === "/login" ||
      newStyleRoute === "/home";

    next({
      name: "login",
      ...(isHomeOrLogin ? {} : { query: { redirect: newStyleRoute || to.fullPath } }),
    });
  } else {
    newStyleRoute ? next(newStyleRoute) : next();
  }
});

export default router;
