<template>
  <DashboardWidget
      :value="value"
      :range="range"
      class="standalone-widget"
      :style="`width: ${width}px; height: ${height}px;`"
      public
      :uuid="uuid"
      :project-api-key="projectApiKey"/>
</template>

<script>
import DashboardWidget from '@/components/specific/Dashboard/DashboardWidget.vue';
import { durationToSeconds, getGetParameter } from "@/js/general";

export default {
  name: 'StandaloneWidget',
  components: {
    DashboardWidget,
  },
  data() {
    return {
      durationToSeconds: durationToSeconds,
      getGetParameter: getGetParameter,
      value: {
        name: this.getGetParameter('title'),
        type: 'echart',
        chartId: this.$route.fullPath.match(/(?<=\/charts\/)[a-z0-9-]+/)[0],
        filter: this.getGetParameter('filter', '[]'),
      },
      range: this.getRange(),
      width: this.getGetParameter('width', 640),
      height: this.getGetParameter('height', 480),
      uuid: this.getGetParameter('id', ''),
      projectApiKey: this.getGetParameter('project-api-key', ''),
    };
  },
  methods: {
    getRange() {
      const from = this.getGetParameter('from', 'now-1d');
      const to = this.getGetParameter('to', 'now');

      const unixFrom = this.durationToSeconds(from);
      const unixTo = this.durationToSeconds(to);

      let seconds = 0;
      if (to === 'now') seconds = unixTo - unixFrom;

      return {
        from: new Date(unixFrom * 1000),
        to: new Date(unixTo * 1000),
        seconds: seconds,
      };
    },
  },
};
</script>

<style scoped>

</style>