<template>
  <XTextField
      :label="label"
      :value="dataValue"
      @input="handleInput"
      :rules="computedRules"
      validate-immediately
      tooltip="Value between 0 and 65535."/>
</template>

<script>
import XTextField from '@/components/basic/XTextField.vue';
import { getRequiredRule } from "@/js/general";

export default {
  name: 'PortTextField',
  components: {XTextField},
  props: {
    value: Number,
    label: String,
    required: Boolean,
    rules: Array,
    validateImmediately: Boolean,
  },
  data() {
    return {
      dataValue: '',
      getRequiredRule: getRequiredRule,
      regex: /^\d+$/,
    };
  },
  computed: {
    computedRules() {
      const computedRules = [];
      if (this.rules) computedRules.push(...this.rules);
      if (this.required) computedRules.push(getRequiredRule());
      computedRules.push(v => this.regex.test(v) || 'Invalid port.');
      computedRules.push(
          v => this.regex.test(v) && parseInt(v) >= 0 && parseInt(v) <= 65535 || 'Port is out of range (0-65535).');
      return computedRules;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value !== undefined) this.dataValue = value.toString();
      },
    },
  },
  methods: {
    handleInput(value) {
      if (this.regex.test(value)) {
        value = parseInt(value);
        if (value >= 0 && value <= 65535) {
          this.$emit('input', parseInt(value));
        }
      }
    },
  },
};
</script>

<style scoped>

</style>