import { ref } from "vue"
import { relogin } from "@/auth-tools"
import { useBroadcastChannel } from "@/composition/web-api/use-broadcast-channel"
import { useStore } from "@/store"
import authService from "@/js/services/AuthService"

// generated only once
const ID = Math.random().toString(36).slice(2)

export const useSwitchContext = () => {
  const MSG = "context-switch"
  const _subs = ref([])
  const _run = (event) => {
    const { msg, id } = event.data
    if (msg !== MSG || id === ID) {
      return
    }

    for (const sub of _subs.value) {
      sub()
    }
  }
  const subscribe = (fn) => {
    _subs.value.push(fn)
  }
  const { send } = useBroadcastChannel({ name: "context-switch", onmessage: _run })

  const notfiyContextSwitch = () => {
    send({ msg: MSG, id: ID })
  }

  const store = useStore()
  const doContextSwitch = async ({ projID, orgID }) => {
    const tokens = await authService.switchContext(parseInt(projID), parseInt(orgID))
    await relogin(tokens)
    await store.dispatch("fetchEssentialData", { isRefresh: true })
    notfiyContextSwitch()
  }

  return {
    subscribeToContextSwitch: subscribe,
    notfiyContextSwitch,
    doContextSwitch,
  }
}
