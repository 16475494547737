export const MENU_ITEMS = {
  configureAndRun: { text: "Configure and Run Tests", routeName: "configure-and-run-tests" },
  scheduleTests: { text: "Schedule Tests", routeName: "schedule-tests" },
  testStatus: { text: "Test Status", routeName: "test-status" },
  explorerTests: { text: "Explorer Tests", routeName: "explorer-tests" },
  currentlyRunning: { text: "Currently Running Tests", routeName: "currently-running-tests" },
  previousTests: { text: "Previous Tests", routeName: "previous-tests" },
  receivedSms: { text: "Received SMS", routeName: "received-sms" },
  receivedKafka: { text: "Received Messages (Kafka)", routeName: "received-messages-kafka" },
  receivedCdr: { text: "Received Messages (CDR)", routeName: "received-messages-cdr" },
  receivedPackets: { text: "Received Packets", routeName: "received-packets" },
};

export const RESTRICTED_ORGANIZATIONS = new Set([
  "Vodafone GmbH",
  "Deutsche GigaNetz"
]);

// Roles: "super_admin", 1, 2, 3, 4, 5
export const ROLE_GROUPS = {
  FIRST_GROUP: ["super_admin", 1, 2],
  SECOND_GROUP: [3, 4, 5]
}

// Role-based menu configurations
export const MENU_SECTIONS = new Map([
  // Super Admin and Roles [1, 2]
  [ROLE_GROUPS.FIRST_GROUP, {
    configuration: ["configureAndRun", "scheduleTests"],
    monitoring: ["testStatus", "explorerTests", "currentlyRunning", "previousTests"],
    messaging: ["receivedSms", "receivedKafka", "receivedCdr", "receivedPackets"]
 }],

  // Other roles (3, 4, 5)
  [ROLE_GROUPS.SECOND_GROUP, {
    configuration: ["configureAndRun", "scheduleTests"],
    monitoring: ["testStatus", "explorerTests", "currentlyRunning", "previousTests"]
 }]
]);
