<template>
  <XDialog
      :buttons="buttons"
      :text="text"
      :title="title"
      :value="value"
      :width="width"
      :persistent="persistent"
      @input="$emit('input', $event)">
    <template #dialog-content>
      <slot/>
    </template>
  </XDialog>
</template>

<script>
import { defineComponent, inject } from "vue"
import XDialog from '@/components/basic/XDialog';

export default defineComponent({
  name: 'YesNoDialog',
  components: {XDialog},
  props: {
    value: Boolean,
    title: String,
    text: String,
    width: {
      type: [Number, String],
    },
    yesText: String,
    yesColor: String,
    yesIcon: String,
    yesTextColor: String,
    yesDisabled: Boolean,
    noText: String,
    noColor: String,
    noIcon: String,
    noTextColor: String,
    persistent: Boolean,
  },

  setup() {
    const { getCloseMethod } = inject("DialogsRoot")
    const closeDialog = getCloseMethod("YesNoDialog")

    return {
      closeDialog,
    }
  },

  data() {
    return {
      buttons: [
        {
          text: this.yesText ? this.yesText : 'Yes',
          icon: this.yesIcon ? this.yesIcon : 'mdi-check',
          color: this.yesColor ? this.yesColor : 'primary',
          textColor: this.yesTextColor ? this.yesTextColor : 'white',
          disabled: () => {
            return this.yesDisabled;
          },
          click: this.yes,
        },
        {
          text: this.noText ? this.noText : 'No',
          icon: this.noIcon ? this.noIcon : 'mdi-close',
          color: this.noColor ? this.noColor : 'secondary',
          textColor: this.noTextColor ? this.noColor : '',
          click: this.no,
        },
      ],
    };
  },
  methods: {
    yes() {
      if (this.closeDialog) {
        this.closeDialog({ isYes: true })
        return
      }
      this.$emit('yes');
      this.$emit('input', false);
    },
    no() {
      if (this.closeDialog) {
        this.closeDialog({ isYes: false })
        return
      }
      this.$emit('no');
      this.$emit('input', false);
    },
  },
});
</script>

<style scoped></style>