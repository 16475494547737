<template>
  <div :data-help="computedHelp" class="x-textarea-field">
    <v-textarea
        ref="textarea"
        v-model="dataValue"
        :label="label"
        :rules="computedRules"
        :height="height"
        auto-grow
        dense
        outlined
        hide-details="auto"
        @input="$emit('input', $event)"/>
    <HelpButton :id="id" :help="computedHelp"/>
  </div>
</template>
<script>
import getRule from '@/js/rules';
import HelpButton from '@/components/basic/HelpButton.vue';
import {getRequiredRule} from '@/js/general';

export default {
  name: 'XTextarea',
  components: {HelpButton},
  props: {
    value: String,
    label: String,
    required: Boolean,
    rules: Array,
    validateImmediately: Boolean,
    id: String,
    height: [Number, String],
    help: String,
  },
  data() {
    return {
      dataValue: '',
      getRequiredRule: getRequiredRule,
    };
  },
  watch: {
    value(val) {
      this.dataValue = val;
    },
  },
  computed: {
    computedRules() {
      let computedRules = [];
      if (this.required) {
        computedRules.push(getRequiredRule());
      }
      if (this.rules) {
        for (const rule of this.rules) {
          if (typeof rule === 'string') computedRules.push(getRule(rule));
          else computedRules.push(rule);
        }
      }
      if (!computedRules.length) computedRules = undefined;
      return computedRules;
    },
    computedHelp() {
      if (this.help) return this.help;
      return this.id;
    },
  },
  mounted() {
    if (this.validateImmediately) {
      this.dataValue = ' ';
      this.$nextTick(() => {
        this.dataValue = this.value;
      });
    } else {
      this.dataValue = this.value;
    }
  },
};
</script>

<style scoped>
.x-textarea-field {
  display: flex;
}

.x-textarea-field >>> .help-button {
  margin-top: 2px;
}
</style>
