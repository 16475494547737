import Vue from 'vue';
import Notifications from 'vue-notification';
import App from './App.vue';
import router from './router';
import VueGeoLocation from 'vue-browser-geolocation';
import * as VueGoogleMaps from 'vue2-google-maps';
import vuetify from '@/plugins/vuetify';
import 'vuetify/dist/vuetify.min.css'; // Ensure you are using css-loader
import axios from 'axios';
import VueAxios from 'vue-axios';
import Vuex from 'vuex';
import 'leaflet/dist/leaflet.css';
import VueMeta from "vue-meta";

import vuejquery from 'vue-jquery';
import AsyncComputed from 'vue-async-computed';

import {mixin} from '@/js/mixin.js';
import {useStore} from '@/store';

import VTooltip from 'v-tooltip';
import defaultValueMixin from '@/mixins/default-value-mixin';
import notificationMixin from '@/mixins/notification-mixin';

Vue.use(VTooltip);
Vue.use(VueMeta);

Vue.use(Notifications);

Vue.use(AsyncComputed);
Vue.config.productionTip = false;

Vue.use(vuejquery);
Vue.use(Vuex);

const store = useStore();

Vue.mixin(mixin);
Vue.mixin(defaultValueMixin);
Vue.mixin(notificationMixin);

Vue.use(VueAxios, axios);

Vue.use(VueGeoLocation);
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCKmrnIZ_WcrXQ5IXrFwfQ0RmG7NbsGYy0',
        libraries: 'places',
    },
});

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
}).$mount('#app');
