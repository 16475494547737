<template>
  <XSelect
      :value="value"
      :items="items"
      :label="label"
      @input="$emit('input', $event)"
      :item-value="!hash ? 'id' : 'hash'"
      item-text="name"
      :required="!!value && required"/>
</template>

<script>
import XSelect from '@/components/basic/XSelect';
import userService from "@/js/services/UserService";

export default {
  name: 'ProjectSelect',
  components: {XSelect},
  props: {
    value: [Number, String],
    hash: Boolean,
    label: String,
    required: Boolean,
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    if (!this.value && this.required) {
      const project = !this.hash ? this.$store.state.project.id : this.$store.state.project.hash;
      this.$emit('input', project);
    }

    if (!this.hash) {
      userService.getProjectNamesByOrganization((projects) => {
        this.items = projects;
      });
    } else {
      userService.getProjectNamesHashByOrganization((projects) => {
        this.items = projects;
      });
    }
  },
  watch: {
    items(value) {
      if (value.length && !value.includes(this.value)) {
        let projectValue;
        if (!this.hash) {
          projectValue = value[0].id;
        } else {
          projectValue = value[0].hash;
        }
        this.$emit('input', projectValue);
      }
    },
  },
};
</script>

<style scoped>

</style>