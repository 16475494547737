import {getValueByFunctionOrDirectly} from '@/js/general';

const defaultValueMixin = {
    methods: {
        emitDefaultValue(defaultValue, unsetCheck) {
            if (!defaultValue) defaultValue = this.default;
            if (!unsetCheck) unsetCheck = () => this.value === undefined || this.value === null;
            if (unsetCheck() && !!getValueByFunctionOrDirectly(defaultValue)) {
                if (!this.$store.state.emittingDefaultValue) {
                    this.$store.commit('setEmittingDefaultValue', true);
                    this.$emit('input', getValueByFunctionOrDirectly(defaultValue));
                    this.$nextTick(() => {
                        this.$store.commit('setEmittingDefaultValue', false);
                    });
                } else {
                    this.$nextTick(() => {
                        this.emitDefaultValue(defaultValue, unsetCheck);
                    });
                }
            }
        },
    },
};

export default defaultValueMixin;