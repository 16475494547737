<template>
  <XDialog
      :persistent="modified"
      :title="title"
      :value="value"
      content-height="768"
      unpadded
      width="1800"
      maximizable
      @input="close"
      @click:outside="close">
    <template #dialog-content>
      <div class="explorer-dialog-content">
        <v-tabs v-model="selectedTab">
          <v-tab
              v-for="tab in tabs"
              :key="tab"
              :disabled="(!configurationValid && tab !== tabs[selectedTab]) || tab === 'Logging'"
              v-text="tab"/>
        </v-tabs>

        <v-tabs-items v-model="selectedTab">
          <v-tab-item>
            <ExplorerStatistics class="padded" :explorer-name="dataExplorer.name"/>
          </v-tab-item>

          <v-tab-item>
            <ExplorerConfiguration
                class="padded"
                v-model="dataExplorer"
                :disabled="history || disabled"
                @input="configurationModified = true"/>
          </v-tab-item>

          <v-tab-item>
            <ExplorerSystemInfo  :disabled="disabled" class="padded" :value="dataExplorer"/>
          </v-tab-item>

          <v-tab-item>
            <StatusLog :disabled="disabled" class="padded" :explorer-name="dataExplorer.name"/>
          </v-tab-item>

          <v-tab-item/>

          <v-tab-item>
            <ExplorerTestResults
              v-if="dataExplorer.id"
              class="padded"
              :explorer-id="dataExplorer.id"
              @redirect="$emit('input', false)"
            />
          </v-tab-item>

          <v-tab-item>
            <ExplorerLocalServices
                :disabled="disabled"
                v-model="dataExplorer.localServices"
                v-if="dataExplorer.localServices"
                @valid="localServicesValid = $event"
                @input="localServicesModified = true"/>
          </v-tab-item>

          <v-tab-item v-if="dashboard">
            <ExplorerDashboard :value="dashboard" :explorer-name="dataExplorer.name"/>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <UnsavedChangedDialog v-model="unsavedChangesDialog" @yes="$emit('input', false)"/>

      <LoadingDialog v-model="loading"/>
    </template>

    <template #dialog-bottom>
      <InputRow v-if="dataExplorer.history">
        <XTextField v-model="dataExplorer.history.comment" label="Comment" class="comment"/>
        <XBtn
            :text="!history ? 'Save' : 'Restore'"
            color="save"
            :icon="!history ? 'mdi-content-save' : 'mdi-backup-restore'"
            :disabled="!valid || disabled"
            @click="save"/>
        <XBtn text="Cancel" color="secondary" icon="mdi-cancel" @click="close"/>
      </InputRow>
    </template>
  </XDialog>
</template>

<script>
import { defineComponent, inject } from "vue"
import XDialog from '@/components/basic/XDialog.vue';
import LoadingDialog from '@/components/basic/LoadingDialog.vue';
import UnsavedChangedDialog from '@/components/extended/UnsavedChangesDialog.vue';
import ExplorerStatistics from '@/components/specific/Explorer/Dialog/ExplorerStatistics.vue';
import ExplorerConfiguration from '@/components/specific/Explorer/Dialog/ExplorerConfiguration.vue';
import ExplorerSystemInfo from '@/components/legacy/explorerSystemInfo.vue';
import StatusLog from '@/components/legacy/statusLog.vue';
import ExplorerTestResults from '@/components/specific/Explorer/Dialog/ExplorerTestResults.vue';
import ExplorerLocalServices from '@/components/specific/Explorer/Dialog/ExplorerLocalServices.vue';
import InputRow from '@/components/basic/InputRow.vue';
import XTextField from '@/components/basic/XTextField.vue';
import XBtn from '@/components/basic/XBtn.vue';
import explorerStatusService from '@/js/services/ExplorerStatusService';
import ExplorerDashboard from '@/components/specific/Explorer/Dialog/ExplorerDashboard.vue';
import chartService from '@/js/services/ChartService';
import { useFiberNet } from "@/composition/menu/use-fiber-net";
import {deepCopy, setDefaultValues} from '@/js/general';
import cockpitExplorerService from '@/js/services/CockpitExplorerService';

export default defineComponent({
  name: 'ExplorerDialog',

  components: {
    ExplorerDashboard,
    XBtn,
    XTextField,
    InputRow,
    ExplorerLocalServices,
    ExplorerTestResults,
    StatusLog,
    ExplorerSystemInfo,
    ExplorerConfiguration,
    ExplorerStatistics,
    UnsavedChangedDialog,
    LoadingDialog,
    XDialog,
  },

  props: {
    value: Boolean,
    explorer: Object,
    explorerId: Number,
    explorerName: String,
    disabled: Boolean,
  },

  setup() {
    const { getCloseMethod } = inject("DialogsRoot")
    const closeMethod = getCloseMethod("ExplorerDialog")
    const { isEnabled: isFiberNetEnabled } = useFiberNet();

    return {
      closeMethod,
      isFiberNetEnabled,
    }
  },

  data() {
    return {
      selectedTab: 5,
      refreshInterval: '0',
      configurationValid: true,
      unsavedChangesDialog: false,
      modified: false,
      configurationModified: false,
      localServicesModified: false,
      dataExplorer: {},
      loading: false,
      localServicesValid: true,
      dashboard: null,
      setDefaultValues: setDefaultValues,
    };
  },

  computed: {

    title() {
      let title = 'Explorer';
      if (this.dataExplorer.name) {
        title = this.dataExplorer.name;
        if (this.isFiberNetEnabled) {
          title = title.replace('eue', 'mym');
        }
        if(this.dataExplorer?.type==='sim-server'){
          title = `SIM-Server: ${title}`;
        }else{
          title = `Explorer: ${title}`;
        }
        if (this.history) {
          title += ` (version: ${this.dataExplorer.viewingHistory.version})`;
        }

      }
      return title;
    },

    history() {
      return this.dataExplorer.viewingHistory && !!this.dataExplorer.viewingHistory.action;
    },

    valid() {
      return this.configurationValid && this.localServicesValid;
    },

    tabs() {
      let tabs = [
        'Statistic',
        'Configuration',
        'System information',
        'Logbook',
        'Logging',
        'Last test results',
        'Local services',
      ];
      if(this.explorer?.type==='sim-server'){
         tabs = [
          'Statistic',
          'Configuration',
          'System information',
          'Logbook',
          'Logging',
        ];
      }
      if (this.dashboard) tabs.push('Dashboard');
      return tabs;
    },
  },

  async created() {
    if (this.explorer) {
      return
    }

    let explorer = null;

    if (this.explorerId) {
      explorer = await cockpitExplorerService.getExplorer(this.explorerId, 0)
      explorer.id = this.explorerId;
    }

    if (this.explorerName) {
      explorer = await cockpitExplorerService.getExplorer(this.explorerName.replace('mym', 'eue'), 0)
    }
    this.setDataExplorer(explorer);
  },

  watch: {
    explorer: {
      immediate: true,
      handler(value) {
        if (!value) return;
        this.setDataExplorer(value);
      },
    },
  },

  methods: {
    setDataExplorer(value) {
      const dataExplorer = deepCopy(value);
      if (dataExplorer.localServices && dataExplorer.localServices['PNSolDeltaQ']) {
        dataExplorer.localServices['PNSolDeltaQ'] = [dataExplorer.localServices['PNSolDeltaQ']];
      }

      setDefaultValues(dataExplorer, {
        localServices: {
          'PNSolDeltaQ': [],
          'iperf': [],
          'udpst': [],
        },
      });
      if (!dataExplorer.configuration.location ||
          !dataExplorer.configuration.location.latitude || !dataExplorer.configuration.location.longitude) {
        dataExplorer.configuration.location = {
          latitude: '',
          longitude: '',
        };
      }
      if (!dataExplorer.history) dataExplorer.history = {};
      dataExplorer.history.comment = '';
      dataExplorer.name = dataExplorer.name.replace('mym', 'eue');
      chartService.getExplorerDashboard(dataExplorer.name, (dashboard) => {
        this.dashboard = dashboard;
      });
      this.dataExplorer = dataExplorer;
      this.modified = false;
      this.configurationModified = false;
      this.localServicesModified = false;
    },

    save() {
      if (!this.valid) return;
      this.loading = true;
      const dataExplorer = deepCopy(this.dataExplorer);
      delete dataExplorer.localServices;
      if (!dataExplorer.configuration.location.latitude || !dataExplorer.configuration.location.longitude) {
        delete dataExplorer.configuration.location;
      }
      if (this.configurationModified) {
        cockpitExplorerService.updateExplorer(dataExplorer, this.saveLocalServices);
      } else if (this.localServicesModified) {
        this.saveLocalServices();
      } else {
        this.closeAfterSave();
      }
    },

    saveLocalServices() {
      const convertedLocalServices = deepCopy(this.dataExplorer.localServices);
      convertedLocalServices['PNSolDeltaQ'] = convertedLocalServices['PNSolDeltaQ'][0];
      if (this.localServicesModified) {
        explorerStatusService.saveServiceConfiguration(this.dataExplorer.id, convertedLocalServices, () => {
          this.closeAfterSave();
        });
      } else {
        this.closeAfterSave();
      }
    },

    close() {
      if (this.modified) {
        this.unsavedChangesDialog = true;
        return
      }

      if (this.closeMethod) {
        this.closeMethod();
        return;
      }

      this.$emit('input', false);
    },

    closeAfterSave() {
      this.loading = false;
      if (this.closeMethod) {
        this.closeMethod(this.dataExplorer);
        return;
      }

      this.$emit('input', false);
      this.$emit('update-explorer', this.dataExplorer);
    },
  },
});
</script>

<style scoped>
.padded {
  padding: 20px;
}

.statistic {
  overflow-y: scroll;
  height: 600px;
}

.comment {
  width: 500px;
}
</style>
