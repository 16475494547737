<template>
  <v-menu
    offset-y
    offset-x
    :close-on-content-click="false"
    v-model="customPeriodDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-2"
        color="primary"
        id="dateRangePicker"
        width="150"
        v-bind="attrs"
        v-on="on"
      >
        {{ value }}
        <v-icon right dark> mdi-menu-down </v-icon>
      </v-btn>
    </template>

    <v-card width="900"
      ><v-card-text>
        <v-row>
          <v-col cols="4">
            <v-date-picker
              v-model="dates"
              @input="sortDates"
              range
              :max="this.getDate(new Date())"
            ></v-date-picker>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :value="minDate"
              @change="minDate = arguments[0]"
              label="from"
              prepend-icon="mdi-calendar"
              id="calenderFrom"
            ></v-text-field>
            <v-text-field
              :value="maxDate"
              @change="maxDate = arguments[0]"
              label="to"
              prepend-icon="mdi-calendar"
              id="calenderFrom"
            ></v-text-field>
            <!-- <v-btn
                  color="buttonBackground"
                  @click="updateRanges"
                  id="datePickerOK"
                >
                  Apply time range
                </v-btn> -->
          </v-col>

          <v-col cols="4">
            <!-- <inputTypes
                  :info="info.meta.timerange"
                  v-model="info.data.timerange"
                ></inputTypes> -->
            <v-card class="mx-auto" max-width="200" tile elevation="0" outlined>
              <v-list dense id="datePickerRanges">
                <v-subheader style="font-weight: bold; color: black"
                  >Predefined Time Ranges</v-subheader
                >
                <v-list-item-group v-model="localValue" color="primary">
                  <v-list-item v-for="(item, i) in timerange" :key="i">
                    <v-list-item-content>
                      <v-list-item-title v-text="item"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col> </v-row
      ></v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="primary" @click="okClicked" id="datePickerOK"> OK </v-btn>
        <v-btn
          color="primary"
          @click="customPeriodDialog = false"
          id="datePickerCancel"
        >
          Cancel
        </v-btn></v-card-actions
      >
    </v-card>
  </v-menu>
</template>
<script>
export default {
  props: ["value", "interval", "begin", "end"],
  data() {
    return {
      intervalPointer: null,
      customPeriodDialog: false,
      dates: [],
      times: ["00:00:00", "23:59:59"],
      minDate: "",
      maxDate: "",

      timerange: [
        "Last 5 min",
        "Last 15 min",
        "Last 30 min",
        "Last 1 hour",
        "Last 6 hours",
        "Last 12 hours",
        "Last 24 hours",
        "Last 2 days",
        "Last 7 days",
        "Last 14 days",
        "Last 30 days",
      ],
      valueMapping: {
        "Last 5 min": { from: "now-5m", to: "now" },
        "Last 15 min": { from: "now-15m", to: "now" },
        "Last 30 min": { from: "now-30m", to: "now" },
        "Last 1 hour": { from: "now-1h", to: "now" },
        "Last 6 hours": { from: "now-6h", to: "now" },
        "Last 12 hours": { from: "now-12h", to: "now" },
        "Last 24 hours": { from: "now-24h", to: "now" },
        "Last 2 days": { from: "now-48h", to: "now" },
        "Last 7 days": { from: "now-168h", to: "now" },
        "Last 14 days": { from: "now-336h", to: "now" },
        "Last 30 days": { from: "now-720h", to: "now" },
      },
    };
  },
  watch: {
    interval(newVal) {
      this.checkRefreshInterval(newVal);
    },
    dates() {
      if (
        typeof this.dates[0] != "undefined" &&
        typeof this.dates[1] != "undefined"
      ) {
        this.minDate = this.dates[0] + " " + this.times[0];
        this.maxDate = this.dates[1] + " " + this.times[1];
      }
    },
    minDate(newVal, oldValue) {
      if (newVal != "invalid") {
        if (!this.setTimeDate(newVal, 0) && oldValue != "") {
          this.minDate = "invalid";
          this.$nextTick(() => {
            this.minDate = oldValue;
          });
        }
      }
    },
    maxDate(newVal, oldValue) {
      if (newVal != "invalid") {
        if (!this.setTimeDate(newVal, 1) && oldValue != "") {
          this.maxDate = "invalid";
          this.$nextTick(() => {
            this.maxDate = oldValue;
          });
        }
      }
    },
  },
  created() {
    this.minDate = this.valueMapping[this.value].from;
    this.maxDate = this.valueMapping[this.value].to;
    this.updateRanges();
  },
  computed: {
    localValue: {
      get() {
        return this.timerange.indexOf(this.value);
      },
      set(newVal) {
        if (typeof newVal != "undefined") {
          let val = this.timerange[newVal];
          console.log(val, newVal);
          this.$emit("input", val);
          this.minDate = this.valueMapping[val].from;
          this.maxDate = this.valueMapping[val].to;
          this.$emit(
            "updateDates",
            this.calculateSentDate(this.valueMapping[val].from),
            this.calculateSentDate(this.valueMapping[val].to)
          );

          let minDate = this.calculateSentDate(this.minDate);
          let maxDate = this.calculateSentDate(this.maxDate);

          this.$emit("updateUnixDates", minDate, maxDate);
          // this.dates = [];
        }
        // this.$emit("closeDialog");
        this.customPeriodDialog = false;
      },
    },
  },
  methods: {
    okClicked() {
      this.updateRanges();
      this.customPeriodDialog = false;
    },
    checkRefreshInterval(newVal) {
      let caller = this;
      clearInterval(this.intervalPointer);
      if (newVal != 0) {
        this.intervalPointer = setInterval(function () {
          caller.updateRanges();
        }, newVal * 1000);
      }
    },
    calculateSentDate(stringTime) {
      let minDate;
      let parseMinDate = Math.floor(
        new Date(stringTime.replace(" ", "T")).getTime()
      );
      if (!isNaN(parseMinDate)) {
        minDate = parseMinDate / 1000;
        this.$emit("input", "custom");
      } else {
        let stringDate = this.calculateStringToTime(stringTime);
        //console.log(stringDate, stringTime);
        if (stringDate) {
          minDate = stringDate;
        }
      }
      return minDate;
    },
    calculateStringToTime(stringTime) {
      const regexString = /now(-(\d+)([hms]))*/;
      let match = stringTime.match(regexString);
      let time;
      if (match != null && match.length > 0) {
        time = this.getCurrentTime();
        if (typeof match[2] != "undefined" && typeof match[3] != "undefined") {
          let unit = match[3];
          let value = match[2];
          let add = 0;
          if (unit == "s") {
            add = value;
          } else if (unit == "m") {
            add = value * 60;
          } else if (unit == "h") {
            add = value * 3600;
          }
          time -= add;
        }
      } else {
        time = false;
      }
      return time;
    },
    getCurrentTime: function () {
      return Math.floor(Date.now() / 1000);
    },
    toInt(x, base) {
      const parsed = parseInt(x, base);
      if (isNaN(parsed)) {
        return 0;
      }
      return parsed;
    },
    setTimeDate(newVal, index) {
      let dateObject = new Date(newVal.replace(" ", "T"));
      //console.log(dateObject);
      if (this.checkDate(dateObject)) {
        let time = this.getTime(dateObject);
        let date = this.getDate(dateObject);
        this.$set(this.dates, index, date);
        this.$set(this.times, index, time);
        return true;
      } else {
        //check time values
        //now-2n
        if (/now(-\d+[hms])*/.test(newVal)) {
          return true;
        } else {
          this.dates = [];
          return false;
        }
      }
    },
    checkDate(d) {
      if (Object.prototype.toString.call(d) === "[object Date]") {
        // it is a date
        if (isNaN(d.getTime())) {
          // d.valueOf() could also work
          // date is not valid
          return false;
        } else {
          // date is valid
          return true;
        }
      } else {
        // not a date
        return false;
      }
    },
    getTime(dateObject) {
      let time =
        ("0" + dateObject.getHours()).slice(-2) +
        ":" +
        ("0" + dateObject.getMinutes()).slice(-2) +
        ":" +
        ("0" + dateObject.getSeconds()).slice(-2);
      return time;
    },
    updateRanges() {
      let minDate = this.calculateSentDate(this.minDate);
      let maxDate = this.calculateSentDate(this.maxDate);

      this.$emit("updateDates", minDate, maxDate);
      this.$emit("updateUnixDates", minDate, maxDate);
      // this.$emit("input", "custom");
    },
    sortDates() {
      var dates = this.dates.map(function (x) {
        return new Date(x);
      });

      let sortedDates = dates.sort((a, b) => a - b);
      let caller = this;
      let mappedDates = sortedDates.map(function (x) {
        return caller.getDate(x);
      });
      // console.log(this.dates, sortedDates, mappedDates);
      this.dates = mappedDates;
    },
    getDate(x) {
      var year = x.getFullYear();
      var month = ("0" + (x.getMonth() + 1)).slice(-2);
      var day = ("0" + x.getDate()).slice(-2);

      return year + "-" + month + "-" + day;
    },
  },
};
</script>
