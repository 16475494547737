<template>
  <v-row v-if="!loading" class="explorer-system-information">
    <v-col class="mt-n5" cols="6">
      <v-row>
        <v-col class="subtitle-1 font-weight-bold">
          {{ language.explorerView.cStatus }}
        </v-col>
      </v-row>
      <v-row class="divider">
        <v-col>
          <v-divider/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row class="data-row explorers-status">
            <v-col style="max-width: 174px;">
              <ExplorerStatus :value="explorerStatus"/>
            </v-col>
            <v-col>
              {{ language.explorerView.uTime }}: {{ formattedUptime }} [{{ language.explorerView.lUpdate }}:
              {{ formattedUptimeUpdateTime }} ]
            </v-col>
            <v-col v-if="$store.state.role <= 2" cols="3" class="explorer-system-information-reboot-button">
              <XBtn :disabled="disabled" text="Reboot" icon="mdi-restart" color="primary" @click="rebootExplorer" block/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <template v-if="Object.keys(systemInfo.environment).length > 0">
        <v-row>
          <v-col class="subtitle-1 font-weight-bold">
            {{ language.explorerView.environment }}
          </v-col>
        </v-row>
        <v-row class="divider">
          <v-col>
            <v-divider/>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="typeof systemInfo.environment.ipv4!='undefined'">
            <div class="font-weight-black">{{ language.explorerView.ipv4 }}</div>
            <v-row class="data-row">
              <v-col class="" style="max-width: 175px;">{{ language.explorerView.lAddress }}:</v-col>
              <v-col>{{ systemInfo.environment.ipv4.localAddress }}</v-col>
            </v-row>
            <v-row class="data-row">
              <v-col style="max-width: 175px;">{{ language.explorerView.pAddress }}:</v-col>
              <v-col>{{ systemInfo.environment.ipv4.publicAddress }}</v-col>
            </v-row>
            <v-row class="data-row">
              <v-col style="max-width: 175px;">{{ language.explorerView.gateway }}:</v-col>
              <v-col>{{ systemInfo.environment.ipv4.gateway }}</v-col>
            </v-row>
          </v-col>
          <v-col v-if="typeof systemInfo.environment.ipv6!='undefined'">
            <div class="font-weight-black">{{ language.explorerView.ipv6 }}</div>
            <v-row class="data-row">
              <v-col class="" style="max-width: 118px;">{{ language.explorerView.lAddress }}:</v-col>
              <v-col>{{ systemInfo.environment.ipv6.address }}</v-col>
            </v-row>
            <v-row class="data-row">
              <v-col class="" style="max-width: 118px;">{{ language.explorerView.gateway }}:</v-col>
              <v-col>{{ systemInfo.environment.ipv6.gateway }}</v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="typeof systemInfo.environment.router!='undefined'">
          <v-col>
            <div class="font-weight-black">{{ language.explorerView.router }}</div>
            <v-row class="data-row">
              <v-col style="max-width: 175px;">{{ language.explorerView.rMac }}:</v-col>
              <v-col>{{ systemInfo.environment.router }}</v-col>
            </v-row>
            <v-row class="data-row">
              <v-col style="max-width: 175px;">{{ language.explorerView.manufacturer }}:</v-col>
              <v-col>{{ systemInfo.environment.routerManufacturer }}</v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="typeof systemInfo.environment.mtu!='undefined'">
          <v-col>
            <div class="font-weight-black">{{ language.explorerView.network }}</div>
            <v-row class="data-row">
              <v-col style="max-width: 175px;">{{ language.explorerView.mtu }}:</v-col>
              <v-col>{{ systemInfo.environment.mtu }}</v-col>
            </v-row>
            <v-row class="data-row">
              <v-col style="max-width: 175px;">{{ language.explorerView.speed }}:</v-col>
              <v-col>{{ systemInfo.environment.speed }}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-if="Object.keys(systemInfo.systemType).length > 0">
        <v-row>
          <v-col class="subtitle-1 font-weight-bold">
            {{ language.explorerView.sType }}
          </v-col>
        </v-row>
        <v-row class="divider">
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row v-for="(value, index) in systemInfo.systemType" :key="index" class="data-row">
              <v-col style="max-width: 175px;">{{ language.systemType[index] }}:</v-col>
              <template v-if="typeof value==='object'">
                <v-col cols="7">
                  <div v-for="(objVal, objKey) in value" :key="objKey">
                    {{ objKey }}: {{ objVal }}
                  </div>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="7">
                  {{ customValue(value, index) }}
                </v-col>
              </template>
            </v-row>
          </v-col>
          <v-col>
            <explorer-hw-brand :degust-image-version="systemInfo.systemType.degustImageVersion" size="48"/>
          </v-col>
        </v-row>
      </template>
    </v-col>
    <v-col class="mt-n5" cols="6">
      <template v-if="typeof systemInfo.location != 'undefined'">
        <v-row>
          <v-col class="subtitle-1 font-weight-bold">
            {{ language.explorerView.location }}
          </v-col>
        </v-row>
        <v-row class="divider">
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row class="data-row">
              <v-col style="max-width: 175px;">{{ language.explorerView.region }}:</v-col>
              <v-col>{{ systemInfo.location.region }}</v-col>
            </v-row>
            <v-row class="data-row">
              <v-col style="max-width: 175px;">{{ language.explorerView.city }}:</v-col>
              <v-col>{{ systemInfo.location.city }}</v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="mapOption!=null" class="map">
          <v-col style="height:500px;max-width:500px;border: 1px solid grey;">
            <chartMap :option="mapOption"></chartMap>
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
  <div v-else class="explorer-system-information-progress-circular">
    <v-progress-circular indeterminate color="primary" size="100" width="10"/>
  </div>
</template>
<script>
import chartService from "@/js/services/ChartService";
import explorerStatusService from "@/js/services/ExplorerStatusService";
import chartMap from '@/components/legacy/chartMap.vue';
import ExplorerHwBrand from '@/components/specific/Explorer/ExplorerHwBrand.vue';
import ExplorerStatus from '@/components/specific/Explorer/ExplorerStatus.vue';
import XBtn from '@/components/basic/XBtn';
import {unixToDateTimeString} from '@/js/general';

export default {
  components: {
    XBtn,
    ExplorerStatus,
    ExplorerHwBrand,
    chartMap,
  },
  props: {
    value: Object,
    explorerName: String,
    disabled:Boolean
  },
  data() {
    return {
      attributes: {},
      mapOption: null,
      dataExplorerStatus: {},
      dataSystemInfo: {
        environment: {},
        location: {},
        systemType: {},
      },
      loading: true,
    };
  },
  watch: {
    explorerName: {
      immediate: true,
      handler() {
        if (!this.value) {
          explorerStatusService.getStatus(this.explorerName, (status) => {
            this.explorerStatus = status;
            explorerStatusService.getStatusSystemInfo(this.explorerName, (statusSystemInfo) => {
              this.dataSystemInfo.systemType = statusSystemInfo;
              explorerStatusService.getSystemInfo(this.explorerName, (systemInfo) => {
                this.dataSystemInfo.environment = systemInfo;
                this.dataSystemInfo.location = systemInfo.location;
                this.loading = false;
              });
            });
          });
        } else {
          this.loading = false;
        }
        this.getOptions();
      },
    },
  },
  methods: {
    customValue(val, index) {
      let resultVal = val;
      if (index == 'imageInstallationDate') {
        resultVal = val.replace('T', ' ').split('.')[0];
      }
      return resultVal;
    },
    getOptions() {
      chartService.getExplorerMapByName(this.computedExplorerName, (mapOption) => {
        this.mapOption = mapOption;
      });
    },
    rebootExplorer() {
      this.$store.commit('notification', {
        text: `Sent reboot request to Explorer '${this.computedExplorerName}'. Please wait.`,
        length: 5000,
      });
      console.log(this.computedExplorerName);
      explorerStatusService.reboot(this.computedExplorerName, (response) => {
        console.log('Rebooting: ', response);
        if (response.status) {
          this.$store.commit('notification', {
            text: `Explorer '${this.computedExplorerName}' is rebooting.`,
            length: 5000,
          });
        } else {
          this.$store.commit('notification', {
            text: `Explorer could not reboot.`,
            length: 5000,
          });
        }
      }, () => {
        this.$store.commit('notification', {
          text: `Explorer could not reboot.`,
          length: 5000,
        });
      });
    },
  },
  mounted() {
    this.getOptions();
  },
  computed: {
    formattedUptime() {
      const days = parseInt(this.explorerStatus.uptime / 86400);
      const hours = parseInt(this.explorerStatus.uptime % 86400 / 3600);
      const minutes = parseInt(this.explorerStatus.uptime % 3600 / 60);
      const seconds = parseInt(this.explorerStatus.uptime % 60);

      let result = '';
      if (days) result += `${days}d `;
      if (hours) result += `${hours}h `;
      if (minutes) result += `${minutes}m `;
      if (seconds) result += `${seconds}s`;

      return result.trim();
    },
    formattedUptimeUpdateTime() {
      return unixToDateTimeString(this.explorerStatus.uptimeUpdateTime);
    },
    explorerStatus() {
      return this.value ? this.value.status : this.dataExplorerStatus;
    },
    systemInfo() {
      return this.value ? this.value.systemInfo : this.dataSystemInfo;
    },
    computedExplorerName() {
      return this.value ? this.value.name : this.explorerName;
    },
  },
};
</script>

<style scoped>
.explorer-system-information {
  align-items: normal;
}

.explorer-system-information .row + .divider.row {
  margin: 4px 0 0 0;
}

.explorer-system-information .divider > .col {
  padding: 0;
}

.explorer-system-information >>> .row + .row {
  margin-top: 0;
}

.explorer-system-information .data-row.row {
  margin: 0 0 0 16px;
}

.explorer-system-information .data-row:not(.data-row:last-child) {
  margin-bottom: 6px;
}

.explorer-system-information .data-row >>> .col {
  padding: 0;
  overflow-wrap: anywhere;
}

.explorers-status {
  align-items: center;
}

.font-weight-black {
  margin-bottom: 6px;
}

.row + .map.row {
  margin: 12px 0 0 16px;
}

.explorer-system-information-progress-circular {
  display: flex;
  min-height: 600px;
  justify-content: center;
  align-items: center;
}

.explorer-system-information-reboot-button {
  display: flex;
  justify-content: flex-end;
}
</style>