var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',[_c('v-spacer'),_c('v-col',[_c('associatedOption',{ref:"type",attrs:{"type":"select","fieldAttributes":{
          type: 'select',
          associatedOption: _vm.typeSelector,
          setEmpty: false,
          sort: false,
          name: 'Type',
          ignoreEmptyAdd: true,
        },"hideDetails":true},model:{value:(_vm.dataType),callback:function ($$v) {_vm.dataType=$$v},expression:"dataType"}})],1),_c('v-col',[_c('associatedOption',{ref:"source",attrs:{"type":"select","fieldAttributes":{
          type: 'select',
          associatedOption: _vm.sourceSelector,

          sort: false,
          name: 'Source',
          setEmpty: false,
          ignoreEmptyAdd: true,
        },"hideDetails":true},model:{value:(_vm.dataSource),callback:function ($$v) {_vm.dataSource=$$v},expression:"dataSource"}})],1),_c('v-col',[_c('searchInput',{key:"explorerLogSearch",model:{value:(_vm.localSearch),callback:function ($$v) {_vm.localSearch=$$v},expression:"localSearch"}})],1),_c('v-col',{staticClass:"ml-3",staticStyle:{"min-width":"200px"},attrs:{"cols":"1"}},[_c('datePickerExtend',{ref:"datePicker",attrs:{"interval":_vm.refreshInterval},on:{"updateUnixDates":_vm.updateDates},model:{value:(_vm.info.data.timerange),callback:function ($$v) {_vm.$set(_vm.info.data, "timerange", $$v)},expression:"info.data.timerange"}})],1),_c('v-col',{staticStyle:{"max-width":"200px"},attrs:{"id":"dashboardRefreshInterval_container"}},[_c('associatedOption',{attrs:{"type":"select","fieldAttributes":{
          type: 'select',
          associatedOption: [
            [0, 'off'],
            [10, '10s'],
            [30, '30s'],
            [60, '1m'],
            [120, '2m'],
            [300, '5m'],
            [600, '10m'],
          ],
          setEmpty: false,
          sort: false,
          name: 'Refresh',
          ignoreEmptyAdd: true,
        },"hideDetails":true},model:{value:(_vm.refreshInterval),callback:function ($$v) {_vm.refreshInterval=$$v},expression:"refreshInterval"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.HEADERS,"items":_vm.filteredStatusLog,"loading":_vm.loading,"sort-by":"datetime","sort-desc":true,"search":_vm.localSearch},scopedSlots:_vm._u([{key:`item.datetime`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatTime(item.datetime))+" ")]}}],null,true)}),_c('v-row',[_c('v-col',[_c('primaryTextfield',{attrs:{"label":"Message","fieldAttrInput":{ disabled: _vm.messageLoading, id: 'manuelLogInput' }},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('v-col',[_c('primaryButton',{attrs:{"label":"Add Manual Entry","icon":"mdi-send","type":"button","color":"primary","id":"send_button","data-type":"send_button","loading":_vm.messageLoading,"disabled":_vm.disabled},on:{"click":_vm.addStatusLog}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }