import { onBeforeMount, readonly, shallowRef } from "vue"
import { useRoute, useRouter } from "vue-router/composables"

/**
 * @typedef {Object} SortingData
 * @property {string} sortBy
 * @property {boolean} descending
 */


/**
 * @param {string} headerValue
 * @param {SortingData} sortingData
 * @returns {SortingData}
 */
export function getUpdatedSortBy(headerValue, sortingData) {
  const _s = {
    sortBy: sortingData.sortBy,
    descending: sortingData.descending,
  }

  if (_s.sortBy !== headerValue) {
    _s.sortBy = headerValue;
    _s.descending = false;
    return _s
  }

  if (!_s.descending) {
    _s.descending = true;
  } else {
    _s.sortBy = '';
    _s.descending = false;
  }

  return _s
}

export function useTableSortingWithRouter() {
  const route = useRoute()
  const router = useRouter()
  const sortingData = shallowRef({
    sortBy: '',
    descending: false,
  })

  /**
   * @param {string} headerValue
   */
  const updateSortingData = (headerValue) => {
    const newQuery = { ...route.query }
    sortingData.value = getUpdatedSortBy(headerValue, sortingData.value)
    if (sortingData.value.sortBy) {
      newQuery.sortBy = sortingData.value.sortBy
      newQuery.descending = sortingData.value.descending.toString()
    } else {
      delete newQuery.sortBy
      delete newQuery.descending
    }

    router.replace({ ...route, query: newQuery })
        .catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err
          }
        })
  }

  const init = () => {
    const sortBy = route.query.sortBy || ""
    const descending = route.query.descending || false
    if (sortBy && typeof sortBy === "string") {
      sortingData.value = {
        sortBy,
        descending: descending === "true",
      }
    }
  }

  onBeforeMount(() => {
    init()
  })

  return {
    sortingData: readonly(sortingData),
    updateSortingData,
  }
}
