import Service from '@/js/service.js';

const explorersService = new Service(`${process.env.VUE_APP_EXPLORER_STATUS_SERVICE}/explorers`);
const csvImportService = new Service(`${process.env.VUE_APP_EXPLORER_STATUS_SERVICE}/csv-import`);
const explorerManagementService = new Service(`${process.env.VUE_APP_EXPLORER_STATUS_SERVICE}/explorer-management`);
const explorerConfigurationService = new Service(`${process.env.VUE_APP_EXPLORER_STATUS_SERVICE}/explorer-configuration`);
const systemInfoService = new Service(`${process.env.VUE_APP_EXPLORER_STATUS_SERVICE}/systeminfo`);
const statusService = new Service(`${process.env.VUE_APP_EXPLORER_STATUS_SERVICE}/explorer-status-service`);
const statusViewService = new Service(`${process.env.VUE_APP_EXPLORER_STATUS_SERVICE}/status-view`);
const testResultsService = new Service(`${process.env.VUE_APP_EXPLORER_STATUS_SERVICE}/test-results`);


const explorerStatusService = {
    getExplorers(status, tags, online, params, then, error) {
        const combinedParams = {
            status: status !== undefined ? status : -1,
            tags: tags !== undefined ? tags : '',
            online: online !== undefined ? online : -1,
            ...params,
        };
        explorersService.getRequest(1, 'explorers',
            combinedParams, then, error,
        );
    },
    getExplorerCounts(status, tags, search) {
      const params = {
          status: status !== undefined ? status : -1,
          tags: tags !== undefined ? tags : '',
          search: search !== undefined ? search : '',
      };

      return new Promise((resolve, reject) => {
        explorersService.getRequest(1, 'explorers/counts',
            params, resolve, reject,
        );
      })
    },
    deleteExplorer(id, then, error) {
        explorersService.deleteRequest(1, 'explorer/{id}', null, then, error, id);
    },
    toggle(id, active, then, error) {
        explorersService.patchRequest(1, 'explorer/{id}/toggle/{active}',
            null, then, error, id, active,
        );
    },
    getCsvImportStatus(then, error) {
        csvImportService.getRequest(1, 'csv-import-status',
            null, then, error,
        );
    },
    importCsv(csv, type, then, error) {
        const formData = new FormData();
        formData.append('csv', csv);
        csvImportService.putRequestWithHeaders(
            1, 'import-csv/{function}',
            formData, {
                'Content-Type': 'multipart/form-data',
            }, then, error, type,
        );
    },
    reboot(explorerName, then, error) {
        explorerManagementService.postRequest(1, 'reboot/{name}',
            null, then, error, explorerName,
        );
    },
    getExplorerByName(explorerName, then, error) {
        explorerConfigurationService.getRequest(
            2, 'explorer/{name}', null, then, error, explorerName);
    },
    updateExplorerByName(explorerName, configuration, then, error) {
        explorerConfigurationService.patchRequest(2, 'explorer/{name}',
            configuration, then, error, explorerName,
        );
    },
    getSystemInfo(explorerName, then, error) {
        systemInfoService.getRequest(
            1, 'systeminfo/{name}',
            null, then, error, explorerName,
        );
    },
    getStatus(explorerName, then, error) {
        statusViewService.getRequest(2, 'status/{name}',
            null, then, error, explorerName,
        );
    },
    getStatusSystemInfo(explorerName, then, error) {
        systemInfoService.getRequest(
            1, 'systeminfo/{name}',
            null, then, error, explorerName,
        );
    },
    getTestResults(explorerName, testStatus, params, then, error) {
        const combinedParams = {
            testStatus: testStatus !== undefined ? testStatus : -1, ...params,
        };
        testResultsService.getRequest(
            2, '{name}',
            combinedParams, then, error, explorerName,
        );
    },
    getTestResultsStatusCounts(explorerName, params, then, error) {
        testResultsService.getRequest(
            2, 'status-counts/{name}',
            params, then, error, explorerName,
        );
    },
    checkTagSyntaxCb(query, then, error) {
      statusService.postRequest(1, 'check-tag-syntax',
          {
              query: query,
          }, then, error,
      );
    },
    checkTagSyntax(query) {
      return new Promise((resolve, reject) => {
        statusService.postRequest(1, 'check-tag-syntax',
            {
                query: query,
            }, resolve, reject,
        );
      })
    },
    saveServiceConfiguration(explorerId, serviceConfiguration, then, error) {
        explorerConfigurationService.patchRequest(2, '{id}/services', serviceConfiguration, then, error, explorerId);
    },
    getExplorerList(then, error) {
        explorersService.getRequest(1, 'list',
            null, then, error,
        );
    },
};

export default explorerStatusService;
