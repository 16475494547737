<template>
  <div>
    <StandaloneWidget v-if="route === 'charts'"/>
  </div>
</template>

<script>
import StandaloneWidget from '@/components/specific/Dashboard/StandaloneWidget.vue';

export default {
  name: 'PublicContent',
  components: {StandaloneWidget},
  computed: {
    route() {
      return this.$route.fullPath.match(/(?<=\/)[^/]+/)[0];
    },
  },
};
</script>

<style scoped>

</style>