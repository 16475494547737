import { computed, ref } from "vue";
import { useStore } from "@/store";

export const useHelpStore = () => {
  const store = useStore();
  const isLoading = ref(false);

  const _getCached = (id) => store.getters["help/getFromCache"](id);

  const helpList = computed(() => store.getters["help/list"]);

  const getHelpText = async ({ id, isRefresh = false }) => {
    const cached = _getCached(id);
    if (cached && !isRefresh) {
      return cached;
    }

    isLoading.value = true;
    try {
      await store.dispatch("help/fetchHelpTextById", { id, isRefresh });
    } finally {
      isLoading.value = false;
    }

    return _getCached(id);
  }

  return {
    getHelpText,
    isLoading,
    helpList,
  }
};
