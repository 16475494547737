import { computed } from "vue";
import { useStore } from "@/store";
import { MenuItem } from "./MenuItem";
import { MENU_ITEMS, ROLE_CONFIGS, ROLE_GROUPS } from "./config/administration-menu";

const createMenuItems = (section, items) => {
  return items.map(key => MenuItem(MENU_ITEMS[section][key]));
};

const getRoleConfig = (orgaRole = null) => {
  if (!orgaRole) {
    return ROLE_CONFIGS.get(ROLE_GROUPS.SUPER_ADMIN);
  }

  for (const [roles, config] of ROLE_CONFIGS) {
    if (roles.includes(orgaRole)) {
      return config;
    }
  }

  return ROLE_CONFIGS.get(ROLE_GROUPS.SUPER_ADMIN);
};

export function useAdministrationMenu() {
  const store = useStore();
  const orgaRole = computed(() => store.getters["orgaRole"]);
  const isSuperAdmin = computed(() => store.getters["isSuperAdmin"]);

  const getMenuLinks = (section) => computed(() => {
    const config = getRoleConfig(isSuperAdmin.value ? null : orgaRole.value);
    return createMenuItems(section, config[section]);
  });

  return {
    usersLinks: getMenuLinks("user"),
    projectsLinks: getMenuLinks("project"),
    organizationLinks: getMenuLinks("organization"),
  };
}
