const notificationMixin = {
    methods: {
        showNotification(message, length = 2000) {
            this.$store.commit('notification', {
                text: message,
                length: length,
            });
        },
    },
};

export default notificationMixin;