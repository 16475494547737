import Service from '@/js/service';

const service = new Service(`${process.env.VUE_APP_COCKPIT_TEST_STATUS_SERVICE}/cockpit-test-status-service`);

const cockpitTestStatusService = {
    getTestStatus(groupStartType, status, params, then, error) {
        service.getRequest(2, 'test-status/default',
            {
                ...params,
                'group-start-type': groupStartType,
                status: status,
            }, then, error,
        );
    },
    getTestStatusCounts(from, to, search) {
        return new Promise((resolve, reject) => {
            service.getRequest(1,
                'test-status/default/counts',
                {from: from, to: to, search: search},
                resolve,
                reject,
            );
        });
    },
    getExplorerTestStatus(explorerId, status, currentProject, params, then, error) {
        const combinedParams = {
            status: status !== undefined ? status : -1,
            'current-project': currentProject,
            ...params,
        };
        service.getRequest(3,
            'test-status/explorer/{id}',
            combinedParams, then, error,
            explorerId);
    },
    getExplorerTestStatusCounts(explorerId, currentProject, params) {
        const combinedParams = {
            'current-project': currentProject, ...params,
        };

        return new Promise((resolve, reject) => {
            service.getRequest(
                1,
                'test-status/explorer/{id}/counts', combinedParams, resolve, reject, explorerId,
            );
        });
    },
    getGroupName(groupId) {
        return new Promise((resolve, reject) => {
            service.getRequest(
                1,
                'group/{id}/name',
                null, resolve, reject, groupId,
            );
        });
    },
    getGroupTestStatus(groupId, status, currentProject, params, then, error) {
        const combinedParams = {
            status: status !== undefined ? status : -1,
            'current-project': currentProject,
            ...params,
        };
        service.getRequest(
            3,
            'test-status/group/{id}',
            combinedParams, then, error, groupId,
        );
    },
    getGroupTestStatusCounts(explorerId, params) {
        return new Promise((resolve, reject) => {
            service.getRequest(
                1,
                'test-status/group/{id}/counts',
                params, resolve, reject, explorerId,
            );
        });
    },
    getPreviousTestStatus(status, testQueueIds, params, then, error) {
        const combinedParams = {
            status: status !== undefined ? status : -1,
            'test-queue-ids': testQueueIds && testQueueIds.length ? testQueueIds.join(',') :
                '', ...params,
        };
        service.getRequest(
            3,
            'test-status/previous',
            combinedParams, then, error,
        );
    },
    getPreviousTestStatusCounts(testQueueIds, params) {
        const combinedParams = {
            'test-queue-ids': testQueueIds && testQueueIds.length ? testQueueIds.join(',') :
                '', ...params,
        };
        return new Promise((resolve, reject) => {
            service.getRequest(
                1,
                'test-status/previous/counts',
                combinedParams, resolve, reject,
            );
        });
    },

    getRunningTestStatus(params, then, error) {
        service.getRequest(
            3,
            'test-status/running',
            params,
            then,
            error,
        );
    },

    getRunningTestStatusCounts(params, then, error) {
        service.getRequest(
            1,
            'test-status/running/counts',
            null,
            then,
            error,
        );
    },

    getRecentlyCompletedTests(then, error) {
        service.getRequest(
            3,
            'test-status/recently-completed',
            null,
            then,
            error,
        );
    },
    getTestQueueDetails(testQueueId) {
        return new Promise((resolve, reject) => {
            service.getRequest(
                3,
                'test-group/{id}/context',
                null, resolve, reject, testQueueId,
            );
        });
    },
};

export default cockpitTestStatusService;
