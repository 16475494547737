<template>
  <div
      ref="xDataTable"
      class="x-data-table"
      :style="height ? `height: ${typeof height === 'number' ? `${height}px` : height};` : ''">
    <div
        v-if="crudRequests && crudRequests.create || title || range && !customRange || (search || $slots['search-input-slot'])
         || $slots['before-title'] || $slots['after-title']
         || $slots['before-search'] || $slots['after-search'] || $slots['below-top']"
        ref="top"
        :class="`top ${!noElevation ? 'elevated' : ''}`">
      <div
          v-if="crudRequests && crudRequests.create || title || range && !customRange || (search || $slots['search-input-slot']) || historyItem"
          class="top-main">
        <div v-if="crudRequests && crudRequests.create || title || historyItem" class="top-left">
          <div v-if="crudRequests && crudRequests.create || historyItem || newButton">
            <slot name="title-button-slot" :load-data="loadData" :history-item="historyItem" :close-history="closeHistory">
              <XBtn v-if="!historyItem" icon="mdi-plus" text="New" color="primary" @click="openDialog(item, false)"/>

              <XBtn v-else icon="mdi-step-backward" text="Back" color="primary" @click="closeHistory"/>
            </slot>
          </div>

          <template v-if="!historyItem">
            <slot name="after-new"/>

            <div v-if="csvHeaders" class="csv">
              <XBtn :disabled="disabled" text="Export as CSV" color="primary" @click="loadData(true)"/>
            </div>

            <slot name="before-title"/>
          </template>

          <div v-if="title" class="title">
            <span v-if="!historyItem">{{ title }}</span>

            <span v-else>History of {{ historyItem[nameHeader] }}</span>

            <span v-if="showTotal"> ({{ count || metadata.totalCount || 0 }})</span>
          </div>

          <template v-if="!historyItem">
            <slot name="after-title"/>
          </template>
        </div>

        <div class="search-area">

          <slot name="before-range"/>

          <slot name="time-range-slot">
            <DateTimeRange
              v-if="range && !customRange"
              v-model="settings.rangeValue"
              :default-range="0.041667"
              @input="saveRange"
              :default-seconds="rangeDefaultSeconds"
            />
          </slot>

          <template v-if="!historyItem">
            <slot name="before-search"/>
          </template>

          <slot name="search-input-slot">
            <XTextField
                v-if="search"
                v-model="settings.searchText"
                @input="handleSearchInput"
                delay
                clearable
                label="Search"
                append-icon="mdi-magnify"
                class="search-text-field"
            />
          </slot>

          <template v-if="!historyItem">
            <slot name="after-search"/>
          </template>
        </div>
      </div>
      <div>
        <slot name="below-top"/>
      </div>
    </div>
    <div
        :class="`table-wrapper ${!noElevation ? 'elevated' : ''}`"
        :style="`min-height: ${tableHeight}px; max-height: ${tableHeight}px;`">
      <div ref="basicInformation">
        <HeadlineBox
            v-if="historyItem" headline="Basic Information" content-padding="10" no-border>
          <div class="basic-information">
            <div>
              <div>
                Last Name:
              </div>
              <div>
                {{ historyItem[nameHeader] }}
              </div>
            </div>
            <div>
              <div>
                Created At:
              </div>
              <div>
                {{ unixToDateTimeString(historyItem.history.createDate) }}
              </div>
            </div>
          </div>
        </HeadlineBox>
      </div>
      <div ref="table" class="table">
        <div ref="aboveTable" class="above-table" v-show="$slots['above-table'] && !historyItem">
          <slot name="above-table"/>
        </div>
        <div v-if="historyItem" ref="historyInformation" class="history-information">
          <div :style="`width: ${getColumnWidthSum(0, 4)}px;`">Journal Information</div>
          <div :style="`width: ${getColumnWidthSum(5)}px;`">Object Information</div>
        </div>
        <div ref="thead" class="thead">
          <div class="tr">
            <div
                v-for="(header, i) of computedHeaders"
                :key="i"
                :style="`min-width: ${columnWidths[i]}; max-width: ${columnWidths[i]};`"
                class="th"
            >
              <span v-if="!header.sortable" class="th-content">
                {{ header.text }}
              </span>

              <div
                v-else-if="sortingData"
                :class="`th-content sortable ${header.value === sortingData.sortBy ? 'active' : ''}`"
                @click="() => handleHeaderClick(header)"
              >
                {{ header.text }}

                <v-icon
                  small
                  :class="`table-header-sort-arrow ${header.value === sortingData.sortBy ? 'active' : ''} ${sortingData.descending ? 'descending' : ''}`"
                >
                  mdi-arrow-up
                </v-icon>
              </div>

              <div
                v-else
                :class="`th-content sortable ${header.value === settings.sortBy ? 'active' : ''}`"
                @click="() => handleHeaderClick(header)"
              >
                {{ header.text }}

                <v-icon
                  small
                  :class="`table-header-sort-arrow ${header.value === settings.sortBy ? 'active' : ''} ${settings.descending ? 'descending' : ''}`"
                >
                  mdi-arrow-up
                </v-icon>
              </div>
            </div>
          </div>
        </div>
        <div :style="`height: ${tbodyHeight}px`" class="tbody">
          <div v-if="!computedLoading">
            <div v-if="dataItems.length">
              <div
                v-for="(item, i) of dataItems"
                :key="i"
                class="tr"
                :style="getRowStyle(item)"
              >
                <div
                  v-for="(header, j) of computedHeaders"
                  :key="j"
                  :style="`min-width: ${columnWidths[j]}; max-width: ${columnWidths[j]};`"
                  class="td"
                >
                  <XCheckbox
                      v-if="header.value === 'select'"
                      v-model="selectedItems[item.id]"
                      dense
                      class="x-data-table-select-checkbox"
                  />

                  <div
                    class="d-flex row-actions"
                    v-if="header.value === 'rowActions'"
                  >
                    <div v-for="(rowAction, k) of computedRowActions" :key="k">
                      <XBtn
                          :icon="getValueByFunctionOrDirectly(rowAction.icon, item)"
                          :color="getValueByFunctionOrDirectly(rowAction.color, item)"
                          :disabled="disabled || getValueByFunctionOrDirectly(rowAction.disabled, item)"
                          @click="rowAction.click(item, i, loadData)"/>
                    </div>

                    <v-menu v-if="computedAdditionalRowActions.length" offset-y>
                      <template #activator="{ on, attrs }">
                        <XBtn :disabled="disabled" icon="mdi-dots-horizontal" v-bind="attrs" v-on="on"/>
                      </template>

                      <v-list>
                        <v-list-item
                            v-for="(additionalRowAction, l) in computedAdditionalRowActions"
                            :key="l"
                            class="additional-row-action"
                            :style="getAdditionalRowActionStyle(l)"
                            @mouseenter="additionalRowActionHover = l"
                            @mouseleave="additionalRowActionHover = -1"
                            @click="additionalRowAction.click(item, i, loadData)">
                          <v-icon :color="getAdditionalRowActionTextColor(l)">
                            {{
                              typeof additionalRowAction.icon === 'function' ? additionalRowAction.icon(item, i) :
                                  additionalRowAction.icon
                            }}
                          </v-icon>

                          <div
                              :style="getAdditionalRowActionTextColor(l) ?
                                  `color: var(--v-${getAdditionalRowActionTextColor(l)}-base);` : ''">
                            {{
                              typeof additionalRowAction.text === 'function' ? additionalRowAction.text(item, i) :
                                  additionalRowAction.text
                            }}
                          </div>

                          <v-tooltip v-if="additionalRowAction.text === 'History'" right>
                            <template #activator="{on, attrs}">
                              <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                            </template>

                            <div class="version-information">Version Information</div>

                            <div
                                v-for="(versionInformationKey, m) of getFilteredVersionInformationKeys(item.history)"
                                :key="m"
                                class="version-information-row">
                              <div class="version-information-key">{{ versionInformationKey.text }}:</div>

                              <div>{{
                                  versionInformationKey.formatter ? versionInformationKey.formatter(
                                          item.history[versionInformationKey.value]) :
                                      item.history[versionInformationKey.value]
                                }}
                              </div>
                            </div>
                          </v-tooltip>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>

                  <slot
                      v-else-if="$scopedSlots[`item.${header.value}`]"
                      :name="`item.${header.value}`"
                      :value="getValueByHeader(item, header)"
                      :row="item"
                      :row-index="i"
                      :result-index="computedItemsPerPage * (settings.page - 1) + i"
                      :items-request="itemsRequest"
                      :items-params="getItemsParams"
                      :count="count"
                      :handle-name-click="handleNameClick"/>

                  <ClickableText
                      v-else-if="header.click"
                      :text="header.formatter ? header.formatter(getValueByHeader(item, header), item) : getValueByHeader(item, header)"
                      @click="header.click(item[header.value], item, i)"/>

                  <ClickableText
                      v-else-if="header.value === nameHeader"
                      :text="header.formatter ? header.formatter(getValueByHeader(item, this.nameHeader), item) : getValueByHeader(item, header)"
                      @click="handleNameClick(item, i)"/>

                  <ClickableText
                      v-else-if="header.value === 'history.version' && !!item.history.action"
                      :text="header.formatter ? header.formatter(getValueByHeader(item, header), item) : getValueByHeader(item, header)"
                      @click="handleVersionClick(item, i)"/>
                  <a
                      v-else-if="header.href"
                      :href="typeof header.href === 'function' ? header.href(getValueByHeader(item, header), item) : header.href">
                    {{
                      header.formatter ? header.formatter(getValueByHeader(item, header), item) :
                          getValueByHeader(item, header)
                    }}
                  </a>

                  <span v-else-if="header.formatter">
                    {{ header.formatter(getValueByHeader(item, header), item) }}
                  </span>

                  <span v-else>
                    {{ getValueByHeader(item, header) }}
                  </span>
                </div>
              </div>
            </div>

            <div v-else class="no-records-text">No records found.</div>
          </div>

          <div v-else class="x-data-table-progress-circular">
            <v-progress-circular indeterminate color="primary" size="100" width="10"/>
          </div>
        </div>
      </div>

      <div ref="footer" class="footer">
        <div v-if="selectActions" ref="selectActions" class="x-data-table-select-actions-container">
          <div class="x-data-table-select-actions">
            <XCheckbox
              label="Select All"
              dense
              :value="Boolean(Object.values(selectedItems).length) &&
                (Object.values(selectedItems).length === dataItems.length) &&
                Object.values(selectedItems).every(x => x)"
              @input="(v) => handleSelectAll(v)"
            />

            <ClickableText
                v-for="(selectAction, i) of selectActions"
                :value="selectAction"
                :icon-color="selectAction.iconColor"
                :key="i"
                :disabled="!Object.values(selectedItems).includes(true) || disabled "
                @click="() => handleSelectActionClick(selectAction)"
            />
          </div>
        </div>

        <div
          v-if="!noPageControls"
          class="page-controls"
        >
          <slot name="pagination-slot" :metadata="metadata">
            <XBtn icon="mdi-chevron-left" :disabled="settings.page === 1" @click="settings.page--"/>

            <div
              v-if="pages <= 999 && pagesShown.length"
              class="page-buttons"
              ref="pageButtons"
            >
              <div v-for="(pageValue, i) of pagesShown" :key="i" class="button-container">
                <x-btn
                  v-if="typeof pageValue === 'number'"
                  :text="pageValue"
                  class="page-button"
                  :color="pageValue === settings.page ? 'primary' : ''"
                  @click="settings.page = pageValue"
                />

                <div
                  v-else
                  class="page-ellipsis"
                >
                  {{ pageValue }}
                </div>
              </div>
            </div>

            <XSelect
              v-else
              v-model="settings.page"
              label="Page"
              :items="getSimplePagesShown()"
              class="page-select"
              required
            />

            <XBtn icon="mdi-chevron-right" :disabled="settings.page === pages" @click="settings.page++"/>
          </slot>

          <slot name="items-per-page-slot">
            <XSelect
              v-model="settings.itemsPerPage"
              :items="[10, 25, 50, 100, 250, 500, 1000]"
              label="Items per page"
              class="items-per-page"
              required
              :autocomplete="false"
            />
          </slot>
        </div>
      </div>
    </div>

    <slot
        v-if="dialog"
        name="dialog"
        :value="dialog"
        :item="item"
        :id="dataItemId"
        :version="dataItemVersion"
        :viewing-history="!!historyItem"
        :close="closeDialog"
        :save="handleIndependentDialogSave"
    />

    <HistoryFormDialog
        v-if="dialog && !$scopedSlots['dialog']"
        v-model="dialog"
        :item="item"
        :title="dialogTitle"
        :width="dialogWidth"
        @save="handleSave"
        :unpadded="unpaddedDialog"
        :history-mode="history"
        :viewing-history="!!historyItem"
        :item-name="itemName"
        :name-header="nameHeader"
    >
      <template #dialog-content="{valid}">
        <slot name="dialog-form" :item="item" :valid="valid"/>
      </template>
    </HistoryFormDialog>

    <LoadingDialog :value="easedDialogLoading"/>

    <ReallyDeleteDialog v-model="reallyDeleteDialog" :item-name="this.itemToDelete.name" @yes="deleteItem"/>
  </div>
</template>

<script>
import { defineComponent, inject, ref, shallowRef, computed } from "vue"
import XBtn from '@/components/basic/XBtn.vue';
import XSelect from '@/components/basic/XSelect.vue';
import XTextField from '@/components/basic/XTextField.vue';
import XCheckbox from '@/components/basic/XCheckbox.vue';
import ClickableText from '@/components/basic/ClickableText.vue';
import DateTimeRange from '@/components/basic/DateTimeRange.vue';
import LoadingDialog from '@/components/basic/LoadingDialog.vue';
import ReallyDeleteDialog from '@/components/extended/ReallyDeleteDialog.vue';
import HeadlineBox from '@/components/basic/HeadlineBox.vue';
import HistoryFormDialog from '@/components/extended/HistoryFormDialog.vue';
import requests from '@/js/requests';
import {
  clamp,
  dateToIsoDateString,
  deepCopy,
  downloadStringAsFile,
  generateUUID, getDefaultRange,
  getInt,
  getValueByFunctionOrDirectly,
  getValueByHeader,
  parseBoolean,
  setDefaultValues,
  unixToDateTimeString,
} from '@/js/general';
import { debounce } from "lodash-es"

const MIN_SEARCH_LENGTH = 3

/**
  * @typedef {Array<Object>} DataItems
  */

/**
  * @typedef {Object} DataItemsWithMetadata
  * @property {DataItems} records
  * @property {Object} _metadata
  */

export default defineComponent({
  name: 'XDataTable',

  components: {
    HistoryFormDialog,
    HeadlineBox,
    ReallyDeleteDialog,
    LoadingDialog,
    DateTimeRange,
    ClickableText,
    XCheckbox,
    XTextField,
    XSelect,
    XBtn,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    headers: Array,
    value: Array,
    item: Object,
    defaultItem: [Object, Function],
    itemsUrl: String,
    itemsRequest: Function,
    itemsRequestParams: Array,
    crudRequests: Object,
    customRange: Object,
    refresh: Number,
    height: [Number, String],
    range: Boolean,
    rangeDefaultSeconds: {
      type: Number,
      default: undefined,
    },
    search: Boolean,
    searchText: String,
    selectActions: Array,
    localStorageKey: String,
    title: String,
    showTotal: Boolean,
    itemName: {
      type: String,
      default: 'item',
    },
    dialogWidth: [String, Number],
    unpaddedDialog: Boolean,
    rowActions: {
      type: Array,
      default: () => [],
    },
    rowStyle: [String, Function],
    additionalRowActions: {
      type: Array,
      default: () => [],
    },
    noPageControls: Boolean,
    noElevation: Boolean,
    itemsPerPage: Number,
    csvHeaders: Array,
    loading: Boolean,
    history: Boolean,
    noCount: Boolean,
    readyToLoad: {
      type: Boolean,
      default: undefined,
    },
    nameHeader: {
      type: String,
      default: 'name',
    },
    newButton: Boolean,
  },

  setup() {
    /**
     * @type {import("vue").Ref<Object>}
     */
    const metadata = shallowRef({})

    /**
     * @type {import("vue").Ref<DataItems>}
     */
    const _dataItems = ref([])
    const dataItems = computed({
      get() {
        return _dataItems.value
      },

      /**
      * @param {DataItemsWithMetadata|DataItems} newItems
      */
      set(newItems) {
        if (typeof newItems._metadata === "undefined") {
          _dataItems.value = newItems
        } else {
          metadata.value = newItems._metadata
          _dataItems.value = newItems.records
        }
      }
    })

    const handleVisibilityChangeBinded = ref(null)

    const {
      sortingData = null,
    } = inject("x-data-table-refactoring", {})

    return {
      sortingData,
      dataItems,
      metadata,

      handleVisibilityChangeBinded
    }
  },

  data() {
    return {
      pages: 1,
      clamp: clamp,
      count: 0,
      resizeObserver: null,
      pageButtonsResizeObserver: null,
      tableHeight: 0,
      tbodyHeight: 0,
      columnWidths: [],
      tick: null,
      elapsed: 0,
      dataLoading: false,
      getInt: getInt,
      silentLoading: false,
      lastSearch: new Date(),
      skippedFirst: false,
      selectedItems: {},
      setDefaultValues: setDefaultValues,
      pagesShown: [],
      settings: {
        rangeValue: this.customRange ? this.customRange : getDefaultRange(),
        searchText: '',
        sortBy: this.sortBy ? this.sortBy : '',
        descending: false,
        page: 1,
        itemsPerPage: 25,
      },
      skipNextSearch: false,
      rangeInitialized: false,
      dialog: false,
      dialogTitle: '',
      downloadStringAsFile: downloadStringAsFile,
      openingDialog: true,
      dialogLoading: false,
      itemToDelete: {},
      reallyDeleteDialog: false,
      historyItem: null,
      lastResize: new Date(),
      additionalRowActionHover: -1,
      itemHistory: null,
      versionInformationKeys: [
        {
          text: 'Version',
          value: 'version',
        },
        {
          text: 'Created by',
          value: 'createdBy',
        },
        {
          text: 'Created date',
          value: 'createDate',
          formatter: unixToDateTimeString,
        },
        {
          text: 'Modified by',
          value: 'modifiedBy',
        },
        {
          text: 'Modify date',
          value: 'modifyDate',
          formatter: unixToDateTimeString,
        },
        {
          text: 'Comment',
          value: 'comment',
        },
      ],
      requests: [],
      easedDialogLoading: false,
      dataItemId: 0,
      dataItemVersion: 0,
      fullHeight: 1000,
    };
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.handleResize);
    this.resizeObserver.observe(this.$refs.table);

    if (!this.noPageControls && !this.$scopedSlots['pagination-slot']) {
      this.pageButtonsResizeObserver = new ResizeObserver(this.handlePageButtonsResize);
      this.pageButtonsResizeObserver.observe(this.$refs.pageButtons);
    }

    this.loadSettings();

    this.$nextTick(() => {
      this.recalculateTableHeight();
      this.recalculateColumnWidths();
    });

    this.setTimerToRefetch();

    this.handleVisibilityChangeBinded = this.handleVisibilityChange.bind(this);
    document.addEventListener('visibilitychange', this.handleVisibilityChangeBinded);

    this.loadData();
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChangeBinded);
    this.clearTimerToRefetch();
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && this.dataItems !== value) this.dataItems = deepCopy(value);
      },
    },
    searchText: {
      immediate: true,
      handler(value) {
        if (value !== undefined) {
          this.settings.searchText = value;
          this.loadData();
        }
      },
    },
    count: {
      immediate: true,
      handler() {
        this.updatePages();
      },
    },
    'settings.itemsPerPage'() {
      this.updatePages();
      this.saveSettings();
      this.loadData();
    },
    customRange: {
      deep: true,
      handler(value) {
        this.settings.range = value;
        this.loadData();
      },
    },
    'settings.page'() {
      this.saveSettings();
      this.loadData();
    },
    itemsRequestParams: {
      deep: true,
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.loadData();
        }
      },
    },
    readyToLoad(value) {
      if (value) this.loadData();
    },
    dialogLoading(value) {
      if (value) {
        this.loadingSince = new Date();
        this.easedDialogLoading = true;
      } else {
        setTimeout(
            () => this.easedDialogLoading = false,
            this.clamp(100 - (new Date().getTime() - this.loadingSince), 0, 100),
        );
      }
    },
  },
  computed: {
    computedHeaders() {
      const computedHeaders = [];
      if (this.selectActions) {
        computedHeaders.push(
            {
              value: 'select',
              width: 32,
            });
      }
      if (this.rowActions.length || this.computedAdditionalRowActions.length) {
        const width = 36 * this.rowActions.length + 36 * (this.computedAdditionalRowActions.length ? 1 : 0);
        computedHeaders.push(
            {
              value: 'rowActions',
              width: width,
            });
      }
      if (this.historyItem) {
        const historyHeaders = [
          {
            text: 'Version',
            value: 'history.version',
            formatter: (value) => value ? value : this.dataItems.length > 1 ? this.dataItems[1].history.version + 1 : 1,
          },
          {
            text: 'Action',
            value: 'history.action',
            formatter: (value) => {
              if (!value) {
                return 'current';
              } else if (value === 'mod') {
                return 'modified';
              }
              return '';
            },
          },
          {
            text: 'Modified By',
            value: 'history.modifiedBy',
          },
          {
            text: 'Modify Date',
            value: 'history.modifyDate',
            formatter: value => unixToDateTimeString(value),
          },
          {
            text: 'Comment',
            value: 'history.comment',
          },
        ];
        computedHeaders.push(...historyHeaders);
      }
      computedHeaders.push(...this.headers);
      return computedHeaders;
    },
    computedRowActions() {
      if (!this.historyItem) return this.rowActions;
      return [];
    },
    computedAdditionalRowActions() {
      const computedAdditionalRowActions = [];
      if (this.historyItem) return computedAdditionalRowActions;
      computedAdditionalRowActions.push(...this.additionalRowActions);
      if (this.history) {
        computedAdditionalRowActions.push({
          text: 'History',
          icon: 'mdi-history',
          click: this.loadHistory,
        });
      }
      if (this.crudRequests && this.crudRequests.delete) {
        computedAdditionalRowActions.push({
          text: 'Delete',
          icon: 'mdi-delete',
          click: this.openDeleteDialog,
          hoverColors: {
            text: 'white',
            background: 'delete',
          },
        });
      }
      return computedAdditionalRowActions;
    },
    computedSelectedItems() {
      return Object.entries(this.selectedItems).filter(x => x[1]).map(x => parseInt(x[0]));
    },
    computedLoading() {
      return this.loading || this.dataLoading;
    },
    computedItemsPerPage() {
      return !this.itemsPerPage ? this.settings.itemsPerPage : this.itemsPerPage;
    },
  },
  methods: {
    unixToDateTimeString,
    getValueByFunctionOrDirectly,
    getValueByHeader,
    handleVisibilityChange() {
      if (document.visibilityState === "visible") {
        this.setTimerToRefetch();
      } else {
        this.clearTimerToRefetch();
      }
    },
    clearTimerToRefetch() {
      clearInterval(this.tick);
    },
    setTimerToRefetch() {
      this.tick = setInterval(() => {
        if (!this.refresh || this.computedLoading || this.silentLoading) return;
        this.elapsed++;
        if (this.elapsed >= this.refresh && !Object.values(this.selectedItems).find(x => x === true)) {
          this.elapsed = 0;
          this.$emit('refresh');
          this.loadData(false, true);
        }
      }, 1000);
    },
    loadData(csv = false, silent = false) {
      if (this.readyToLoad === false || !this.itemsUrl && !this.itemsRequest) return;

      if (!silent) {
        if (!csv) this.dataLoading = true;
        else this.dialogLoading = true;
      } else {
        this.silentLoading = true;
      }
      this.$emit('loading', true);

      csv = typeof csv === 'boolean' ? csv : false;

      const requestId = generateUUID();
      this.requests.push(requestId);

      const countThen = (count) => {
        if (!this.requests.length || this.requests[this.requests.length - 1] !== requestId) return;
        this.count = count;
        this.recalculatePageButtons();
      };

      let itemsRequestParams = this.itemsRequestParams ? this.itemsRequestParams : [];

      if (!this.noCount) {
        const params = {
          ...this.getParams(),
          full: csv,
          count: true,
        };
        if (this.itemsUrl) {
          requests.getRequest(this.itemsUrl, params, countThen);
        } else if (this.itemsRequest) {
          this.itemsRequest(...itemsRequestParams, params, countThen);
        }
      }

      const then = (result) => {
        if (!this.requests.length || this.requests[this.requests.length - 1] !== requestId) return;
        if (!csv) {
          this.dataLoading = false;
          this.silentLoading = false;
        }
        this.$emit('loading', false);
        if (csv) {
          const formattedDate = dateToIsoDateString(new Date());

          let records = result;
          if (result?.records) records = result.records;
          const csvResult = this.convertToCsv(records);

          this.downloadStringAsFile(
              `${this.title ? `${this.title} ` : ''}Export ${formattedDate}.csv`,
              csvResult,
          );
          this.dialogLoading = false;
        } else if (!this.historyItem) {
          this.dataItems = result;
          this.$emit('input', this.dataItems);
        } else {
          if (this.settings.page === 1) {
            const items = [this.historyItem, ...result];
            if (items.length > this.settings.itemsPerPage) items.splice(items.length - 1, 1);
            this.dataItems = items;
          } else {
            this.dataItems = result;
          }
        }
        this.elapsed = 0;
      };

      const errorHandler = (error) => {
        this.dataLoading = false;
        this.silentLoading = false;
        this.$emit('loading', false);
        this.$emit('reload-error', error);
      };

      const params = {
        ...this.getParams(),
        full: csv,
        count: false,
      };
      if (this.itemsUrl) {
        requests.getRequest(this.itemsUrl, params, then, errorHandler);
      } else if (this.itemsRequest) {
        this.itemsRequest(...itemsRequestParams, params, then, errorHandler);
      }
    },
    updatePages() {
      let pages = this.count / this.settings.itemsPerPage;
      if (pages % 1 > 0) pages++;
      pages = Math.trunc(pages);
      if (pages === 0) pages = 1;
      this.pages = pages;
      if (this.settings.page > this.pages) this.settings.page = this.pages;
      this.pagesShown = this.getSimplePagesShown();
    },
    handleResize() {
      if (new Date().getTime() - this.lastResize.getTime() < 16) return;
      this.$nextTick(() => {
        this.recalculateColumnWidths();
        this.$nextTick(() => {
          this.recalculateTableHeight();
          this.recalculatePageButtons();
          this.lastResize = new Date();
        });
      });
    },
    handlePageButtonsResize() {
      this.$nextTick(() => {
        this.recalculatePageButtons();
      });
    },
    recalculateTableHeight() {
      const xDataTable = this.$refs.xDataTable;
      if (!xDataTable) return;
      const xDataTableHeight = typeof this.height === 'string' && !this.height.includes('vh') ? this.getInt(
          this.height,
          xDataTable.clientHeight,
      ) : xDataTable.clientHeight;
      const topHeight = this.$refs.top ? this.$refs.top.clientHeight : 0;
      const footerHeight = this.$refs.footer.clientHeight;
      let tableHeight = xDataTableHeight - topHeight;
      if (topHeight) tableHeight -= 8;
      const basicInformationHeight = this.$refs.basicInformation.clientHeight;
      const aboveTableHeight = this.$refs.aboveTable.clientHeight;
      const historyInformation = this.$refs.historyInformation;
      const historyInformationHeight = historyInformation ? historyInformation.clientHeight : 0;
      const theadHeight = this.$refs.thead.clientHeight;
      const tbodyHeight = tableHeight - basicInformationHeight - aboveTableHeight - historyInformationHeight -
          theadHeight - footerHeight;
      this.tableHeight = tableHeight;
      this.tbodyHeight = tbodyHeight;
    },
    recalculateColumnWidths() {
      const table = this.$refs.table;
      if (!table) {
        if (!this.columnWidths.length) {
          const columnWidths = Array(this.computedHeaders.length);
          columnWidths.fill(0);
          this.columnWidths = columnWidths;
        }
        return;
      }
      const tableWidth = table.clientWidth - 15;
      const pixelWidths = [];
      let totalFixedPixelWidth = 0;
      for (const header of this.computedHeaders) {
        let pixelWidth = {
          width: parseInt(tableWidth / this.computedHeaders.length),
          fixed: false,
        };
        if (header.width !== undefined) {
          if (typeof header.width === 'number' || header.width.includes('px')) {
            pixelWidth.width = parseInt(header.width);
            pixelWidth.fixed = true;
            totalFixedPixelWidth += pixelWidth.width;
          } else if (header.width.includes('%')) {
            pixelWidth.width = parseFloat(header.width.substring(0, header.width.length - 1)) / 100;
            pixelWidth.fixed = false;
          }
        }
        pixelWidths.push(pixelWidth);
      }

      const columnWidths = [];
      const pixelsLeft = tableWidth - totalFixedPixelWidth;
      for (const pixelWidth of pixelWidths) {
        let realWidth = pixelWidth.width;
        if (!pixelWidth.fixed) realWidth = parseInt(pixelsLeft / pixelWidths.filter(x => !x.fixed).length);
        columnWidths.push(`${realWidth}px`);
      }

      this.columnWidths = columnWidths;
    },
    recalculatePageButtons() {
      if (!this.$refs.xDataTable) return;
      const selectActionsWidth = this.selectActions ? this.$refs.selectActions.clientWidth : 0;
      const pageLeftRightWidth = 36 * 2;
      const itemsPerPageWidth = 104;
      const paddingWidth = 20;
      const footer = this.$refs.footer;
      const footerWidth = footer ? footer.clientWidth : 0;
      let width = footerWidth - selectActionsWidth - pageLeftRightWidth - itemsPerPageWidth - paddingWidth;
      if (width > 430) width = 430;
      if (this.pages <= 999 && this.pages * 44 - 10 > width) {
        const blocksAllowed = parseInt((width + 10) / 44);
        const pagesShown = [];
        if (blocksAllowed >= 9) {
          const ellipsis = '⋯';
          pagesShown.push(1, 2);
          const halfCenter = Math.trunc((blocksAllowed - 6) / 2);
          if (this.settings.page < halfCenter + 4 || this.settings.page > this.pages - halfCenter - 2) {
            for (let i = 3; i < Math.trunc(blocksAllowed / 2) + 1; i++) {
              pagesShown.push(i);
            }
            pagesShown.push(ellipsis);
            for (let i = this.pages - Math.trunc(blocksAllowed / 2) + 2; i < this.pages - 1; i++) {
              pagesShown.push(i);
            }
          } else {
            pagesShown.push(ellipsis);
            for (let i = this.settings.page - halfCenter + 1; i < this.settings.page + halfCenter + 1; i++) {
              pagesShown.push(i);
            }
            pagesShown.push(ellipsis);
          }
          pagesShown.push(this.pages - 1, this.pages);
          this.pagesShown = pagesShown;
        } else {
          this.pagesShown = [];
        }
      } else {
        this.pagesShown = this.getSimplePagesShown();
      }
    },
    getSimplePagesShown() {
      const pagesShown = [];
      for (let i = 1; i <= this.pages; i++) {
        pagesShown.push(i);
      }
      return pagesShown;
    },
    handleHeaderClick(header) {
      if (this.sortingData) {
        this.$emit("sorting-change", header)
        return
      }

      if (this.settings.sortBy === header.value) {
        if (!this.settings.descending) {
          this.settings.descending = true;
        } else {
          this.settings.sortBy = '';
          this.settings.descending = false;
        }
      } else {
        this.settings.sortBy = header.value;
        this.settings.descending = false;
      }
      this.saveSettings();
      this.loadData();
    },
    handleSelectAll(value) {
      const selectedItems = {};
      for (const item of this.dataItems) {
        selectedItems[item.id] = value;
      }
      this.selectedItems = selectedItems;
    },
    handleSelectActionClick(selectAction) {
      if (selectAction.click) selectAction.click(this.computedSelectedItems);
      this.selectedItems = {};
    },
    loadSettings() {
      if (!this.localStorageKey || !parseBoolean(localStorage.getItem('back'))) return;
      localStorage.setItem('back', 'false');
      const settings = JSON.parse(localStorage.getItem(this.localStorageKey));
      if (!settings) return;
      for (const key of Object.keys(this.settings)) {
        if (settings[key]) {
          if (key !== 'rangeValue') {
            this.settings[key] = settings[key];
          } else {
            this.settings[key] = {
              from: new Date(settings.rangeValue.from),
              to: new Date(settings.rangeValue.to),
              seconds: settings.rangeValue.seconds,
            };
          }
        }
      }
      this.loadData();
    },
    saveSettings() {
      if (!this.localStorageKey || parseBoolean(localStorage.getItem('back'))) return;
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.settings));
    },
    saveRange() {
      this.saveSettings();
      this.$emit('range', deepCopy(this.settings.rangeValue));
      if (this.rangeInitialized) {
        this.loadData();
      }
      this.rangeInitialized = true;
    },
    getRowStyle(item) {
      let rowStyle = this.getValueByFunctionOrDirectly(this.rowStyle, item);
      if (!rowStyle) rowStyle = '';
      rowStyle += `align-items: ${this.rowItemAlignment};`;
      return rowStyle;
    },
    openDialog(item, edit, history) {
      if (!edit) {
        this.dialogTitle = `New ${this.itemName}`;
        if (item) {
          if (this.defaultItem) {
            item = {...getValueByFunctionOrDirectly(this.defaultItem)};
          } else {
            this.setDefaultValues(item);
          }
          this.$emit('update:item', item);
        } else {
          this.dataItemId = 0;
          this.dataItemVersion = 0;
        }
        this.dialog = true;
      } else {
        this.dialogTitle = `Edit ${item[this.nameHeader]}`;
        this.dialogLoading = true;
        let version = 0;
        if (item.history && !!item.history.action) version = item.history.version;
        if (this.crudRequests.read) {
          const readHandler = (newItem) => {
            newItem.id = item.id;
            if (item.history) newItem.history = item.history;
            this.$emit('update:item', newItem);
            this.dialog = true;
            this.dialogLoading = false;
          };

          let result = null
          if (!this.history) {
            result = this.crudRequests.read.request(item.id, 0, readHandler);
          } else {
            result = this.crudRequests.read.request(item.id, version, readHandler);
          }

          if (result && typeof result.then === 'function') {
            // It's a promise, so use .then() to handle it
            result.then(readHandler);
          }
        } else {
          this.dataItemId = item.id;
          if (history) this.dataItemVersion = item.history.version;
          else this.dataItemVersion = 0;
          this.$emit('update:item', deepCopy(item));
          this.dialog = true;
          this.dialogLoading = false;
        }
      }
      this.$emit('dialog', true);
    },
    handleNameClick(item, i) {
      this.openDialog(item, true, false);
      this.$emit('name-click', item, i);
    },
    handleVersionClick(item, i) {
      this.openDialog(item, true, true);
      this.$emit('version-click', item, i);
    },
    handleSave(comment) {
      this.historyItem = null;
      this.dialogLoading = true;
      let item = {...this.item};
      if (this.history) item.history = {
        ...item.history,
        comment: comment,
      };
      if (!this.item.id) {
        this.crudRequests.create.request(item, () => {
          this.loadData();
          this.dialogLoading = false;
          this.dialog = false;
        });
      } else {
        this.crudRequests.update.request(item, () => {
          this.loadData();
          this.dialogLoading = false;
          this.dialog = false;
        });
      }
    },
    handleIndependentDialogSave() {
      this.historyItem = null;
      this.loadData();
    },
    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.reallyDeleteDialog = true;
    },
    deleteItem() {
      this.crudRequests.delete.request(this.itemToDelete.id, () => {
        this.loadData();
      }, (error, status) => {
        if (this.crudRequests.delete) {
          this.crudRequests.delete.errorHandler(error, status, this.itemToDelete);
        }
      });
    },
    convertToCsv(items) {
      let csv = '';
      for (const header of this.csvHeaders) {
        csv += `${header.text};`;
      }
      csv += '\n';
      for (const item of items) {
        for (const header of this.csvHeaders) {
          let value = header.formatter !== undefined ? header.formatter(item[header.value], item) : item[header.value];
          if (value === undefined || value === null) value = '';
          if (value[0] !== '"' && value[value.length - 1] !== '"' && value.includes(',')) value = `"${value}"`;
          value = `${value.replaceAll('\r', ' ').replaceAll('\n', ' ').replaceAll('\\r', ' ').replaceAll('\\n', ' ').replaceAll(/\s+/gm, ' ')};`;
          csv += value;
        }
        csv += '\n';
      }
      return csv;
    },
    handleSearchInput: debounce(function (searchStr) {
      this.$emit('search', searchStr);
      if (searchStr.length && searchStr.length < MIN_SEARCH_LENGTH) return;
      this.loadData();
    }, 500),
    getAdditionalRowActionStyle(index) {
      let style = '';
      if (index === this.additionalRowActionHover) {
        const hoverColors = this.computedAdditionalRowActions[index].hoverColors;
        if (hoverColors) {
          if (hoverColors.text) style += `color: var(--v-${hoverColors.text}-base);`;
          if (hoverColors.background) style += `background-color: var(--v-${hoverColors.background}-base);`;
        }
      }
      return style;
    },
    getAdditionalRowActionTextColor(index) {
      if (index === this.additionalRowActionHover) {
        const hoverColors = this.computedAdditionalRowActions[index].hoverColors;
        if (hoverColors && hoverColors.text) return hoverColors.text;
      }
      return '';
    },
    closeDialog() {
      this.dialog = false;
    },
    loadHistory(item) {
      this.historyItem = item;
      this.$nextTick(() => {
        this.recalculateColumnWidths();
        this.recalculateTableHeight();
      });
      this.loadData();
    },
    closeHistory() {
      this.historyItem = null;
      this.$nextTick(() => {
        this.recalculateColumnWidths();
        this.recalculateTableHeight();
      });
      this.loadData();
    },
    getColumnWidthSum(start, end) {
      let sum = 0;
      if (!end) end = this.columnWidths.length - 1;
      for (let i = start; i <= end; i++) {
        sum += parseInt(this.columnWidths[i]);
      }
      return sum;
    },
    getFilteredVersionInformationKeys(history) {
      return this.versionInformationKeys.filter(x => !!history[x.value]);
    },
    getParams() {
      return {
        page: this.settings.page,
        'items-per-page': this.computedItemsPerPage,
        from: this.getFrom(),
        to: this.getTo(),
        search: this.settings.searchText,
        sortBy: this.settings.sortBy,
        descending: this.settings.descending,
        historyFor: this.historyItem ? this.historyItem.id : 0,
        full: false,
      };
    },
    getItemsParams() {
      return {
        ...this.getParams(),
        count: false,
      };
    },
    getFrom() {
      let from = 0;

      const rangeValue = this.customRange !== undefined ? this.customRange : this.settings.rangeValue;

      if (rangeValue) {
        if (rangeValue.seconds) {
          from = Math.trunc(new Date().getTime() / 1000) - rangeValue.seconds;
        } else {
          if (rangeValue.from) {
            from = Math.trunc(rangeValue.from.getTime() / 1000);
          }
        }
      }

      return from;
    },
    getTo() {
      let to = Math.trunc(new Date().getTime() / 1000);

      const rangeValue = this.customRange !== undefined ? this.customRange : this.settings.rangeValue;

      if (rangeValue) {
        if (rangeValue.seconds) {
          to = Math.trunc(new Date().getTime() / 1000);
        } else {
          if (rangeValue.to) {
            to = Math.trunc(rangeValue.to.getTime() / 1000);
          }
        }
      }

      return to;
    },
  },
});
</script>

<style scoped>
.x-data-table {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
}

.main-v2 > .x-data-table {
  margin: 12px;
}

.main-v2 > div > .x-data-table {
  margin: 12px;
}

.title {
  font-size: 20px;
  font-weight: 500;
}

.top {
  display: flex;
  flex-direction: column;
}

.top.elevated {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

.top-main {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  gap: 10px;
}

.top-left {
  display: flex;
  gap: 10px;
  align-items: center;
  flex: 1 1;
}

.csv {
  display: flex;
  gap: 10px;
  align-items: center;
}

.search-area {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 50%;
  flex: 1 1;
  justify-content: flex-end;
}

.search-text-field {
  flex: 1 1 auto;
  max-width: 350px;
}

.table-wrapper {
  border-radius: 4px;
}

.table-wrapper.elevated {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.basic-information {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  padding: 0 6px;
}

.basic-information > * {
  display: flex;
}

.basic-information > * > *:first-child {
  width: 90px;
}

.above-table {
  padding: 10px;
}

.table {
  flex: 1 0 auto;
  min-height: 150px;
}

.tr {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 32px;
}

.tr.deactivated {
  background-color: var(--v-rowDeactivated-base);
}

.tr.clickable {
  cursor: pointer;
}

.history-information {
  background-color: var(--v-primary-base);
  display: flex;
  color: white;
  padding: 6px 16px;
  font-size: 14px;
  gap: 16px;
}

.tbody {
  border-top: 1px solid var(--v-table-border-base);
  overflow-x: hidden;
  overflow-y: auto;
}

.tbody .tr {
  border-bottom: 1px solid var(--v-table-border-base);
}

.tbody .tr:hover {
  background-color: var(--v-tableRowHover-base);
}

th {
  text-align: left;
}

.th-content {
  color: var(--v-tableHeader-base);
  font-weight: 700;
  user-select: none;
}

.th-content.sortable:hover,
.th-content.active {
  color: var(--v-tableHeaderActive-base);
}

.th-content.sortable {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
}

.table-header-sort-arrow {
  visibility: hidden;
}

.th-content:hover .table-header-sort-arrow:not(.active) {
  visibility: visible;
  color: var(--v-tableHeaderSortArrow-base);
}

.table-header-sort-arrow.active {
  visibility: visible;
  color: var(--v-tableHeaderActive-base);
}

.active.descending {
  rotate: -180deg;
}

.th, .td {
  padding: 0 16px;
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
}

.td {
  font-size: 14px;
}

.footer {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.page-controls {
  flex: 0 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 10px;
}

.page-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  height: 40px;
  overflow: hidden;
  align-items: center;
}

.page-button >>> button.v-btn:not(.v-btn--round).v-size--default {
  min-width: 34px;
  padding: 0 0;
}

.items-per-page {
  width: 120px;
  flex-shrink: 0;
}

.x-data-table-progress-circular {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.x-data-table-select-checkbox >>> .v-input--selection-controls__input {
  margin-right: 0;
}

.x-data-table-select-actions-container {
  flex-shrink: 0;
}

.x-data-table-select-actions {
  padding-left: 16px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.button-container {
  width: 34px;
  height: 36px;
}

.no-records-text {
  color: var(--v-no-records-base);
  text-align: center;
  margin-top: 20px;
}

.additional-row-action {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.additional-row-action:hover {
  background-color: var(--v-menuItemHover-base);
}

.page-ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 36px;
}

.page-select {
  width: 120px;
}

.additional-row-action >>> .v-icon {
  transition: none;
}

.version-information {
  font-weight: bold;
  font-size: 16px;
}

.version-information-row {
  display: flex;
  gap: 5px;
}

.version-information-key {
  font-weight: bold;
  width: 85px;
}

.table-wrapper >>> .headline-text {
  font-weight: normal;
  padding-left: 5px;
}

.pages-controls {
  display: flex;
}
</style>
