import { computed } from "vue"
const DOTS = "..."

export const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

/**
 * @typedef {import('vue').Ref<number>} RefNumber
 */

/**
 * @param {Object} props
 * @param {RefNumber} props.totalCount
 * @param {RefNumber} props.itemsPerPage
 * @param {RefNumber} props.siblingCount
 * @param {RefNumber} props.currentPage
 */
export function usePagination({
  totalCount,
  itemsPerPage,
  siblingCount,
  currentPage,
}) {
  const totalPagesCount = computed(() => {
    return Math.ceil(totalCount.value / itemsPerPage.value)
  })

  const paginationRange = computed(() => {
    const totalPageNumbers = siblingCount.value + 5

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPagesCount]
    */
    if (totalPageNumbers >= totalPagesCount.value) {
      return range(1, totalPagesCount.value);
    }

    const leftSiblingIndex = Math.max(currentPage.value - siblingCount.value, 1);
    const rightSiblingIndex = Math.min(
      currentPage.value + siblingCount.value,
      totalPagesCount.value
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex <  totalPagesCount.value - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPagesCount.value;

    let _paginationRange = [];
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount.value;
      let leftRange = range(1, leftItemCount);

      _paginationRange = [...leftRange, DOTS, totalPagesCount.value];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount.value;
      let rightRange = range(
        totalPagesCount.value - rightItemCount + 1,
        totalPagesCount.value 
      );
      _paginationRange = [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      _paginationRange = [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
    return _paginationRange;
  })

  return {
    paginationRange,
    totalPagesCount,
  }
}
