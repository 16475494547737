import { deepReplace } from "@/js/general";
import fiberNetCfg from "@/cfg/fiberNet.json";
import { computed } from "vue";
import { useStore } from "@/store";

export function useFiberNet() {
  const store = useStore();

  const isEnabled = computed(() => store.getters["fiberNet/isEnabled"]);

  const toggleFiberNet = isEnabled => {
    store.commit("fiberNet/toggleFiberNet", isEnabled);
  }

  const replaceData = (data) => {
    if (!isEnabled.value) {
      return data;
    }

    // eslint-disable-next-line no-undef
    let copy = structuredClone(data);
    for (const replacement of fiberNetCfg) {
      copy = deepReplace(
        copy,
        replacement.searchValue,
        replacement.replaceValue,
        replacement.caseSensitive
      );
    }
    return copy;
  }

  return {
    isEnabled,
    replaceData,
    toggleFiberNet,
  }
}
