<template>
  <div class="x-accordion-list-editor">
    <XAccordion
        v-for="(valueItem, i) of value"
        :key="i"
        :title="() => getTitle(valueItem, i)"
        :title-key="titleKey"
        :value="valueItem"
        :open="openItems[i]"
        @input="handleInput($event, i)">
      <template #before-toggle>
        <v-icon class="clickable" @click="handleDelete(i)" color="text">mdi-delete</v-icon>
      </template>
      <template #body="{value, input}">
        <slot name="body" :value="value" :input="input"/>
      </template>
    </XAccordion>
    <XBtn
        :disabled="disabled"
        v-if="max === undefined || value < getInt(max)"
        :text="computedAddButtonText"
        icon="mdi-plus"
        color="primary"
        @click="addAccordion"/>
  </div>
</template>

<script>
import XAccordion from '@/components/basic/XAccordion.vue';
import XBtn from '@/components/basic/XBtn.vue';
import { deepCopy, getInt } from '@/js/general';

export default {
  name: 'AccordionListEditor',
  components: {
    XBtn,
    XAccordion,
  },
  props: {
    disabled: { Boolean , default: false },
    value: Array,
    title: [String, Function],
    titleKey: String,
    itemName: String,
    addButtonText: String,
    max: [String, Number],
    defaultValue: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      getInt: getInt,
      openItems: {},
    };
  },
  created() {
    const openItems = {};
    for (let i = 0; i < this.value.length; i++) {
      openItems[i] = false;
    }
    if (this.value.length === 1) openItems[0] = true;
    this.openItems = openItems;
  },
  computed: {
    computedAddButtonText() {
      if (this.addButtonText) return this.addButtonText;
      if (this.itemName) return `Add ${this.itemName}`;
      return 'Add item';
    },
  },
  methods: {
    handleInput(value, index) {
      const newValue = [];
      for (let i = 0; i <= this.value.length; i++) {
        if (i === index) newValue.push(value);
        else if (i < this.value.length) newValue.push(this.value[i]);
      }
      this.$emit('input', newValue);
    },
    addAccordion() {
      this.openItems[this.value.length] = true;
      this.handleInput(deepCopy(this.defaultValue), this.value.length);
    },
    getTitle(value, index) {
      if (this.itemName) return `${this.itemName} (${index + 1})`;
      if (this.titleKey) return value[index][this.titleKey];
      if (this.title) {
        if (typeof this.title === 'function') return this.title(this.value[index], index);
        return this.title;
      }
      return 'New item';
    },
    handleDelete(i) {
      const newValue = [...this.value];
      newValue.splice(i, 1);
      this.$emit('input', newValue);
    },
  },
};
</script>

<style scoped>
.x-accordion-list-editor {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.clickable {
  cursor: pointer;
}
</style>