<template>
  <div>
    <Dashboard
        v-if="value && !tagMetricsLoading"
        :value="value"
        :tag-metric-values="tagMetricValues"
        :range-and-refresh="rangeAndRefresh"
        :tag-metrics-loading="tagMetricsLoading"
        :selected-filters="selectedFilters"/>
  </div>
</template>

<script>
import chartService from '@/js/services/ChartService';
import { getDefaultRange } from "@/js/general";

export default {
  name: 'ExplorerDashboard',
  components: {
    Dashboard: () => import('@/components/specific/Dashboard/Dashboard.vue'),
  },
  props: {
    value: Object,
    explorerName: String,
  },
  data() {
    return {
      tagMetricValues: null,
      getDefaultRange: getDefaultRange,
      rangeAndRefresh: {
        range: getDefaultRange(),
        refresh: 86400,
      },
      tagMetricsLoading: true,
    };
  },
  async created() {
    this.tagMetricsLoading = true;
    await chartService.getTagValues('explorer')
      .then((tagMetricValues) => {
        this.tagMetricValues = {
          explorer: tagMetricValues,
        };
      })
      .finally(() => {
        this.tagMetricsLoading = false;
      })
  },
  computed: {
    selectedFilters() {
      return {
        explorer: this.explorerName,
      };
    },
  },
};
</script>

<style scoped>

</style>
