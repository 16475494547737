<template>
  <div>
    <l-map
        ref="map"
        :zoom="options.zoom"
        :center="options.center"
        :roam="options.roam"
        :silent="options.silent"
        :bounds="null">
      <l-icon-default></l-icon-default>
      <l-tile-layer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"></l-tile-layer>
      <vue2-leaflet-markercluster :options="clusterOptions">
        <l-marker
            v-for="(marker, i) of options.markers"
            :key="i"
            :lat-lng="latLng(marker.position.lat, marker.position.lng)"
            :icon="getIcon(marker)"
            :options="getOptions(marker)"
            @click="openExplorerDialog(marker)">
          <l-tooltip :content="customMarkerName(marker)"/>
        </l-marker>
      </vue2-leaflet-markercluster>
    </l-map>

    <ExplorerDialog
      v-if="dialog"
      v-model="dialog"
      :explorer-name="explorerName"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue"
import {LMap, LIconDefault, LTileLayer, LMarker, LTooltip} from 'vue2-leaflet';
import {latLng, icon, divIcon, point} from 'leaflet';
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import ExplorerDialog from '@/components/specific/Explorer/Dialog/ExplorerDialog.vue';
import {deepCopy} from '@/js/general';

const iconDefault = icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

const clusterOptions = {
  chunkedLoading: true,
  iconCreateFunction: (cluster) => {
    const stack = [];
    stack.push(cluster);

    const status = {
      online: 0,
      warning: 0,
      offline: 0,
    };

    let markers = cluster.getAllChildMarkers();
    const explorerCount = markers.length;

    for (const marker of markers) {
      const explorer = marker.options.explorer;
      status[explorer.status]++;
    }

    const onlinePercent = (status.online / explorerCount * 100);
    const warningPercent = (status.warning / explorerCount * 100);

    const html = `<div class="pie-chart" style="
                            -webkit-transform: translateZ(0);
                            -moz-transform: translateZ(0);
                            -ms-transform: translateZ(0);
                            -o-transform: translateZ(0);
                            transform: translateZ(0);
                            background:
                              radial-gradient(
                                  circle closest-side,
                                  #333333 60%,
                                  transparent 0%
                              ),
                              conic-gradient(
                                  var(--v-ok-base) 0,
                                  var(--v-ok-base) ${onlinePercent}%,
                                  var(--v-warning-base) 0,
                                  var(--v-warning-base) ${onlinePercent + warningPercent}%,
                                  var(--v-testStatusError-base) 0,
                                  var(--v-testStatusError-base) 100%
                              );">
                            <div style="
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                line-height: 40px;
                                text-align: center;
                                color: white;
                                text-shadow: black 1px 1px 1px;
                                -webkit-transform: translateZ(0);
                                -moz-transform: translateZ(0);
                                -ms-transform: translateZ(0);
                                -o-transform: translateZ(0);
                                transform: translateZ(0);
                            ">
                            ${explorerCount}
                            </div>
                        </div>`;
    return divIcon({
      html: html,
      className: 'cluster',
      iconSize: point(32, 32),
    });
  },
}

export default defineComponent({
  name: 'DashboardMapClustered',
  components: {
    ExplorerDialog,
    LMap,
    LIconDefault,
    LTileLayer,
    LMarker,
    LTooltip,
    Vue2LeafletMarkercluster,
  },
  props: {
    options: Object,
  },
  setup() {
    return {
      clusterOptions,
    }
  },
  data() {
    return {
      attribution: '',
      resizeObserver: null,
      dialog: false,
      explorerName: '',
      staggeredMarkers: [],
      staggering: false,
      progress: 0,
      progressPerTick: 100,
      tickMs: 250,
      markerBuffers: [],
    };
  },
  async mounted() {
    this.resizeObserver = new ResizeObserver(this.handleResize);
    this.resizeObserver.observe(this.$refs.map.$el);
  },
  methods: {
    customMarkerName(marker){
      let eName = marker.name;
      if(typeof marker.alias!=="undefined" && marker.alias!==''){
        eName = eName + " ("+ marker.alias+")";
      }
      return  eName;
    },
    handleResize() {
      const map = this.$refs.map;
      if (map) map.mapObject.invalidateSize();
    },
    latLng(lat, lng) {
      return latLng(lat, lng);
    },
    getOptions(item) {
      return {
        pane: 'markerPane',
        draggable: false,
        opacity: 1,
        explorer: {
          name: item.name,
          status: item.status,
        },
        className: item.status,
      }
    },

    getIcon(item) {
      const icon = deepCopy(iconDefault);
      icon.options.className = `explorer-map-marker-${item.status}`;
      return icon;
    },

    openExplorerDialog(marker) {
      this.explorerName = marker.name;
      this.dialog = true;
    },
  },
});
</script>

<style scoped>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>

<style>
.pie-chart {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0;
  outline: 0.5px solid black;
  border-radius: 100%;
}

.explorer-map-marker-online {
  filter: hue-rotate(260deg);
}

.explorer-map-marker-warning {
  filter: hue-rotate(200deg) brightness(1.5);
}

.explorer-map-marker-offline {
  filter: hue-rotate(160deg) saturate(1.5) contrast(2);
}

.map-loading-overlay {
  position: absolute;
  z-index: 10000;
  top: 0;
  height: 100%;
  width: 100%;
}

.map-loading-message {
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 3px 5px;
}
</style>
