import {ref, computed, onBeforeMount} from "vue";

export default function useBrowserStorageModel(
  storageType,
  key,
  defaultValue = null,
) {
  const $model = ref(defaultValue)

  const setIntoStorage = (val) => {
    if (typeof val === "string") {
      window[storageType].setItem(key, val)
      return
    }
    if (val != null) {
      window[storageType].setItem(key, JSON.stringify(val))
    }
  }

  const getFromStorage = () => {
    const fromStorage = window[storageType].getItem(key)

    try {
      const parsed = JSON.parse(fromStorage)
      return parsed
    } catch (err) {
      if (err instanceof SyntaxError) {
        return fromStorage
      }
      console.error("[useBrowserStorageModel] Error parsing value from storage", err)
    }

    return null
  }

  const model = computed({
    get() {
      return $model.value
    },
    set(val) {
      setIntoStorage(val)
      $model.value = val
    }
  })

  function init() {
    const fromStorage = getFromStorage()

    if (fromStorage) {
      model.value = fromStorage
    } else {
      setIntoStorage(defaultValue)
    }
  }

  onBeforeMount(() => init())

  return {
    model,
  }
}
