<script>
import { defineComponent, ref, inject } from "vue";
import MenuLinkList from "@/components/specific/menu/MenuLinkList.vue";
import { useConfigurationMenu } from "@/composition/menu/use-configuration-menu";
import { CONNECTION_CATEGORIES_LINKS } from "@/composition/menu/config/connections-menu";

const ConnectionsMenu = () => import(/* webpackChunkName: "connections-menu" */ "@/components/specific/menu/ConnectionsMenu.vue");
const ConnectionsMenuPromo = () => import(/* webpackChunkName: "connections-menu-promo" */  "@/components/specific/menu/ConnectionsMenuPromo.vue");

export default defineComponent({
  name: "ConfigurationMenu",

  components: {
    MenuLinkList,
    ConnectionsMenu,
    ConnectionsMenuPromo,
  },

  setup() {
    const {
      tabs,
      objectsLinks,
      dictionaryLinks,
      outboundCategories,
      inboundCategories,
      recentlyOutboundLinks,
      recentlyInboundLinks,
      handleConnectionsClick
    } = useConfigurationMenu();

    const activeTab = ref(0);
    const { closeMenu } = inject("MENU_BTN");

    const onConnectionsClick = (tabName, category, system) => {
      handleConnectionsClick(tabName, category, system);
      closeMenu();
    };

    return {
      activeTab,
      tabs,
      inboundCategories,
      outboundCategories,
      objectsLinks,
      dictionaryLinks,
      recentlyInboundLinks,
      recentlyOutboundLinks,
      onConnectionsClick,
      closeMenu,
      CONNECTION_CATEGORIES_LINKS,
    };
  },
});
</script>

<template>
  <div class="configuration-menu">
    <v-tabs
      v-model="activeTab"
      right
    >
      <v-tab
        v-for="(tab, i) of tabs"
        :key="i"
        @mouseover="activeTab = i"
      >
        {{ tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="activeTab"
      class="configuration-menu-tabs-items"
    >
      <v-tab-item>
        <ConnectionsMenu
          v-if="outboundCategories.length"
          :categories="outboundCategories"
          :recently-used="recentlyOutboundLinks"
          @navigation-click="(category, system) => onConnectionsClick('outboundConnections', category, system)"
        />

        <ConnectionsMenuPromo
          v-else
          :categories="CONNECTION_CATEGORIES_LINKS.outbound"
        />
      </v-tab-item>

      <v-tab-item>
        <ConnectionsMenu
          v-if="$store.state.project.systemIds.length && inboundCategories.length"
          :categories="inboundCategories"
          :recently-used="recentlyInboundLinks"
          @navigation-click="(category, system) => onConnectionsClick('inboundConnections', category, system)"
        />

        <ConnectionsMenuPromo
          v-else
          :categories="CONNECTION_CATEGORIES_LINKS.inbound"
        />
      </v-tab-item>

      <v-tab-item
        v-if="objectsLinks.length || dictionaryLinks.length"
      >
        <div class="objects-menu">
          <MenuLinkList
            v-if="objectsLinks.length"
            title="Objects"
            :links="objectsLinks"
            @navigation-click="() => closeMenu()"
          />

          <MenuLinkList
            v-if="dictionaryLinks.length"
            title="Dictionary"
            :links="dictionaryLinks"
            @navigation-click="() => closeMenu()"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style scoped>
.configuration-menu {
  width: 1000px;
}

.objects-menu {
  display: flex;
  gap: 20px;
  padding: 0 30px 15px 30px;
}
</style>
