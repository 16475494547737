import { userService } from "@/api";

const _initialState = {
  orgsWithProjects: [],
};

const DEMO_PROJECT_ID = process.env.VUE_APP_DEMO_PROJECT_ID;
const DEMO_PROJECT_NAME = "Template and Demo Project";

const userModule = {
  namespaced: true,

  state: () => ({
    ..._initialState,
  }),

  getters: {
    orgsWithProjects: state => state.orgsWithProjects,
  },

  mutations: {
    setOrgsWithProjects(state, orgsWithProjectsList) {
      orgsWithProjectsList.sort((a, b) => a.name.localeCompare(b.name));

      const list = orgsWithProjectsList.map((org) => {
        if (org?.description?.startsWith("demo")) {
          org.projects.push({
            id: parseInt(DEMO_PROJECT_ID),
            name: DEMO_PROJECT_NAME,
          });
        }
        return org;
      });

      state.orgsWithProjects = list;
    },

    resetState(state) {
      Object.keys(state).forEach(key => {
        // eslint-disable-next-line no-undef
        state[key] = structuredClone(_initialState[key]);
      });
    },
  },

  actions: {
    async fetchOrgsWithProjects({ commit, state }, { isRefresh = false } = {}) {
      if (state.orgsWithProjects.length && !isRefresh) {
        return;
      }

      const list = await userService.v1.fetchOrganizationsWithProjects();
      commit("setOrgsWithProjects", list);
    }
  },
};

export default userModule;
