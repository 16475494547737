<script>
import {defineComponent, ref} from 'vue';
import cockpitTestStatusService from '@/js/services/CockpitTestStatusService';
import testStatus from '@/cfg/testStatus.json';
import {unixToDateTimeString} from '@/js/general';
import {shaKey} from '@/js/helper.js';
export default defineComponent({
  name: 'RecentlyCompletedTestsMenuPreview',
  setup() {
    const recentlyCompletedTests = ref([]);

    cockpitTestStatusService.getRecentlyCompletedTests((response) => {
      recentlyCompletedTests.value = response;
    });

    return {recentlyCompletedTests, testStatus, unixToDateTimeString };

  },
  methods:{
    shaKey:shaKey
  }
});
</script>

<template>
  <div class="recently-completed-tests-menu-preview">
    <div class="recently-completed-tests-menu-preview__title">
      Recently Completed Tests (as of {{ unixToDateTimeString(new Date().getTime() / 1000) }})
    </div>

    <ul class="recently-completed-tests-menu-preview__tests-list">
      <li
        v-for="(test, i) in recentlyCompletedTests"
        :key="i"
        class="recently-completed-tests-menu-preview__test-item"
        :title="`${unixToDateTimeString(test.endTime)}: ${test.groupId}-${test.id}: ${test.name}`"
      >
        <v-icon :color="testStatus[test.status].color">
          {{ testStatus[test.status].icon }}
        </v-icon>

        <router-link
          class="recently-completed-tests-menu-preview__test-link"
          :to="{ name: 'test-info', params: { id: test.id, idKey: shaKey(test.id) } }"
        >
       {{ unixToDateTimeString(test.endTime) }}: {{ test.groupId }}-{{ test.id }}: {{ test.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.recently-completed-tests-menu-preview {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--v-primary-base);

  &__title {
    letter-spacing: 0.08rem;
    white-space: nowrap;
  }

  &__tests-list {
    display: flex;
    flex-direction: column;
    gap: .875rem;
    list-style-type: none;
    padding-left: 0 !important;
  }

  &__test-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__test-link {
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    overflow: hidden;
  }
}
</style>
