import requests from '@/js/requests';

class Service {
    constructor(url) {
        this.url = url;
    }

    getRouteUrl(version, route, ...params) {
        for (const param of params) {
            route = route.replace(/{.+?}/, param.toString());
        }
        if (!version) return `${this.url}/${route}`;
        return `${this.url}/v${version}/${route}`;
    }

    isInCache(request, then) {
        if (cache[name] !== undefined) {
            const updateAfter = updateAfterValues[name] !== undefined ? updateAfterValues[name] : defaultUpdateAfter;
            if (new Date().getTime() / 1000 - cache[name].lastUpdate < updateAfter) {
                then(cache[name].value);
                return true;
            }
        }
        return false;
    }

    getRequest(version, route, params, then, error, ...routeParams) {
        requests.getRequest(this.getRouteUrl(version, route, ...routeParams), params, then, error);
    }

    postRequest(version, route, data, then, error, ...routeParams) {
        requests.postRequest(this.getRouteUrl(version, route, ...routeParams), data, then, error);
    }

    putRequest(version, route, data, then, error, ...routeParams) {
        requests.putRequest(this.getRouteUrl(version, route, ...routeParams), data, then, error);
    }

    putRequestWithHeaders(version, route, data, headers, then, error, ...routeParams) {
        requests.putRequestWithHeaders(this.getRouteUrl(version, route, ...routeParams), data, headers, then, error);
    }

    patchRequest(version, route, data, then, error, ...routeParams) {
        requests.patchRequest(this.getRouteUrl(version, route, ...routeParams), data, then, error);
    }

    deleteRequest(version, route, params, then, error, ...routeParams) {
        requests.deleteRequest(this.getRouteUrl(version, route, ...routeParams), params, then, error);
    }

    publicGetRequest(version, route, params, then, error, ...routeParams) {
        requests.publicGetRequest(this.getRouteUrl(version, route, ...routeParams), params, then, error);
    }

    publicPostRequest(version, route, data, then, error, ...routeParams) {
        requests.publicPostRequest(this.getRouteUrl(version, route, ...routeParams), data, then, error);
    }

    publicPatchRequest(version, route, data, then, error, ...routeParams) {
        requests.publicPatchRequest(this.getRouteUrl(version, route, ...routeParams), data, then, error);
    }
}

const cacheFromLocalStorage = JSON.parse(localStorage.getItem('cache'));
const cache = cacheFromLocalStorage !== null ? cacheFromLocalStorage : {};
const defaultUpdateAfter = 25;
const updateAfterValues = {
    getRadiusAttributes: 600,
};

export default Service;

