<template>
  <div>
    <v-tooltip right v-model="visible" content-class="pa-0">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <slot>
            <v-icon>mdi-information</v-icon>
          </slot>
        </div>
      </template>
      <v-card
        v-if="
          dialogResponse != null &&
          typeof dialogResponse.data.result != 'undefined' &&
          dialogResponse.data.callerID == additionalAttributes.n_id
        "
      >
        <dynamicComponent
          v-for="type in dialogResponse.data.result.content"
          :key="type"
          :type="type"
          :result="dialogResponse.data.result"
          v-on="$listeners"
          :additional="{ extraAttr: dialogAttributes }"
        ></dynamicComponent>
      </v-card>
      <v-card v-else>
        <v-card-text>{{ language.componentLabel.msgNoData }}</v-card-text>
      </v-card>
    </v-tooltip>
  </div>
</template>
<script>
import dynamicComponent from "@/commonComponents/dynamicComponent.vue";
import requests from '@/js/requests';

const qs = require("querystring");
export default {
  data: function () {
    return {
      visible: false,
      loaded: false,
      message: "",
      showConfirmationDialog: false,
      openDialog: false,
      dialogResponse: null,
      localLoadingDialog: false,
    };
  },
  watch: {
    visible(newVal) {
      //console.log("visible", newVal, this.loaded);
      if (newVal) {
        this.addField();
      }
    },
  },
  components: { dynamicComponent },
  computed: {
    /* localLoadingDialog: function() {
      return this.loadingDialog;
    }*/
  },
  // created: function() {
  //   console.log("created");
  //   this.addField();

  // },
  created: function () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "closeDialog") {
        this.dialogResponse = null;
      }
      let id = "";
      if (
        typeof this.additionalAttributes != "undefined" &&
        typeof this.additionalAttributes.n_id != "undefined"
      ) {
        id = this.additionalAttributes.n_id;
      }
      if (
        mutation.type === "openResultDialog" &&
        typeof state.dialogResult.submitedData!= "undefined" &&
        typeof state.dialogResult.submitedData.n_id!= "undefined" &&
        state.dialogResult.submitedData.n_id == id
      ) {
        console.log(state.dialogResult.json.error);
        this.message = state.dialogResult.json.error;
        this.showConfirmationDialog = true;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    updateResult(newResult) {
      console.log("result updated", newResult);
      this.$set(this.dialogResponse.data, "result", newResult);
      // this.dialogResponse.data.result = newResult;
    },
    addField() {
      if (!this.loaded || this.dialogResponse == null) {
        this.localLoadingDialog = true;
        this.$emit("loading-dialog", true);
        //let path = this.currentTable.tableUrl;

        let options = {};
        if (
          typeof this.additionalAttributes != "undefined" &&
          typeof this.additionalAttributes.type != "undefined" &&
          this.additionalAttributes.type == "customDialog"
        ) {
          options = { ...this.additionalAttributes, requestType: "ajax" };
        } else {
          options = { function: "getRemoteTooltip", requestType: "ajax" };
          options = { ...options, ...this.additionalAttributes };
        }

        let caller = this;
        // console.log(options);
        requests.frameworkAxiosRequest({
          method: 'post',
          url: this.path,
          data: qs.stringify(options),
          //headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }
        })
            .then(function (response) {
            //caller.visible = true;
            if (typeof response.data.result != "undefined") {
              caller.loaded = true;
              setTimeout(function () {
                caller.loaded = false;
              }, 3000);
              caller.$emit("loading-dialog", false);

              // console.log(response);
            }
            //caller.$store.commit("pagePostChanged", response);
            if(response.data) {
              caller.$set(
                  response.data,
                  "callerID",
                  caller.additionalAttributes.n_id
              );
            }
            caller.dialogResponse = response;
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      }
    },
    closeCheckDailog() {
      this.showConfirmationDialog = false;
      this.$store.commit("dialogButtonsLoading", false);
    },
  },
  props: {
    path: String,
    additionalAttributes: Object,
    dialogAttributes: Object,
  },
};
</script>