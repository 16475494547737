<template>
  <XTextField
      v-model="dataValue"
      label="Time range"
      prepend-icon="mdi-calendar"
      @input="emitInput"
      :rules="rules"
      class="date-time-range-text-field"/>
</template>

<script>
import XTextField from '@/components/basic/XTextField';
import {dateToDateTimeString, dateTimeStringToDate} from '@/js/general';

export default {
  name: 'DateTimeRangeTextField',
  components: {XTextField},
  props: {
    value: Object,
  },
  data() {
    return {
      dataValue: '',
      dateTimeStringToDate: dateTimeStringToDate,
      rules: [
        v => /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}\s{2}-\s{2}\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}$/.test(v) ||
            'YYYY-MM-DD hh:mm - YYYY-MM-DD hh:mm',
      ],
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        this.dataValue = `${dateToDateTimeString(value.from)}  -  ${dateToDateTimeString(value.to)}`;
      },
    },
  },
  methods: {
    emitInput(value) {
      if (!/^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}\s{2}-\s{2}\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}$/.test(value)) return;
      this.$emit('input', {
        from: this.dateTimeStringToDate(value.substring(0, 16)),
        to: this.dateTimeStringToDate(value.substring(21, 37)),
      });
    },
  },
};
</script>

<style scoped>
.date-time-range-text-field {
  width: 326px;
}
</style>