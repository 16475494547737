import { parseIntOrDefault } from "@/js/helper"

export const getTableParams = (route) => {
  const page = parseIntOrDefault(route.query.page, 1)
  const itemsPerPage = parseIntOrDefault(route.query.itemsPerPage, 25)
  const from = parseIntOrDefault(route.query.from, 0)
  const to = parseIntOrDefault(route.query.to, Date.now())
  const search = route.query.search || ""
  const sortBy = route.query.sortBy || ""
  const descending = route.query.descending === "true"
  const full = route.query.full === "true"
  const historyFor = parseIntOrDefault(route.query.historyFor, 0)
  const tagQuery = route.query.tagQuery || ""

  return { page, itemsPerPage, from, to, search, sortBy, descending, full, historyFor, tagQuery }
}
