<template>
  <div :id="id + '_container'" class="associated-options">
     <v-select
        v-if="type == 'select'"
        :placeholder="placeholder"
        dense
        v-bind="localFieldAttrInputArray"
        :label="localLabel"
        :items="localOptions"
        outlined
        v-model="inputValue"
        item-value="value"
        :disabled="localDisabled"
        :rules="rules"
        v-tooltip="tooltipSource(inputValue)"
        :id="id"
        :error-messages="errors"
        @input="inputChanged()"
        :hide-details="hideDetails"
    >
      <template v-slot:item="data">
        <v-list-item-content
            :id="id + '_' + data.item.value"
            :order-id="id + '_' + data.item.itemOrder"
        >
          <v-list-item-title v-html="data.item.text"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-select>
    <v-combobox
        v-else-if="type == 'tagCombo'"
        :placeholder="placeholder"
        dense
        v-bind="localFieldAttrInputArray"
        :label="localLabel"
        :items="localOptions"
        outlined
        v-model="inputValue"
        :rules="rules"
        :disabled="localDisabled"
        @keyup="emitKeyUp"
        :auto-select-first="autoselect"
        :id="id"
        :error-messages="errors"
        @input="inputChanged()"
        :hide-details="hideDetails"
        :search-input.sync="search"
        @change="search = ''"
    >
      <template v-slot:selection="data" v-if="hasSelectionSlot">
        <slot name="selection" :item="data.item" :index="data.index"></slot>
      </template>

      <template v-slot:item="data">
        <v-list-item-content
            :id="id + '_' + data.item.value"
            :order-id="id + '_' + data.item.itemOrder"
            :data-type="id + '_optionsList'"
            tabindex="-1"
        >
          <v-list-item-title v-html="data.item.text"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-combobox>

    <v-autocomplete
        v-else-if="type == 'combobox'"
        @keydown.enter.native.prevent
        :placeholder="placeholder"
        dense
        v-bind="localFieldAttrInputArray"
        :label="localLabel"
        :items="localOptions"
        outlined
        v-model="inputValue"
        :rules="rules"
        :disabled="localDisabled"
        @keyup="emitKeyUp"
        :auto-select-first="autoselect"
        :id="id"
        :error-messages="errors"
        @input="inputChanged()"
        :hide-details="hideDetails"
        :no-data-text="noDataLabel"
    >
      <template v-slot:selection="data" v-if="hasSelectionSlot">
        <slot name="selection" :item="data.item" :index="data.index"></slot>
      </template>

      <template v-slot:item="data">
        <v-list-item-content
            :id="id + '_' + data.item.value"
            :order-id="id + '_' + data.item.itemOrder"
            :data-type="id + '_optionsList'"
            tabindex="-1"
        >
          <v-list-item-title v-html="data.item.text"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <input
        v-if="typeof inputValue.value != 'object'"
        type="hidden"
        v-model="inputValue.value"
        :name="field"
        :id="id + '_value'"
    />
    <input
        v-if="
        typeof localFieldAttrInputArray.class != 'undefined' &&
        localFieldAttrInputArray.class.includes('addable') &&
        typeof localFieldAttrInputArray.multiple == 'undefined'
      "
        type="hidden"
        :value="keyUpValue"
        :name="field + '_keyup'"
    />
    <HelpButton :id="id"/>
  </div>
</template>
<script>
import {
  getfieldAttrInput,
  getPlaceholder,
  getFieldId,
  getNoDataLabel,
  tooltipSource
} from "@/js/helper.js";
import {getSelectRules} from "@/js/validators.js";
import HelpButton from '@/components/basic/HelpButton';

export default {
  name: "associatedOption",
  components: {HelpButton},
  data: function () {
    return {
      errors: [],
      /*options: this.getItems() */
      asyncOptions: null,
      keyUpValue: "",
      search: "",
      //  checkError:this.checkValue(),
      //checkColor: this.checkRequiredColor(),
    };
  },
  created() {
    this.getAsyncOptions();
    this.setVersion(this.value);
    let errors = [];
    this.rules.forEach((rule) => {
      let text = rule(this.value);
      if (typeof text == "string") {
        errors.push(text);
      }
    });
    this.errors = errors;
  },
  watch: {
    fieldAttributes: function (newVal) {
       // console.log(newVal);
      if (newVal.rerun === true) {
        //console.log(newVal, oldVal);
        this.getAsyncOptions();
      }
    },
    value: function (newVal) {
      this.setVersion(newVal);
      this.errors = [];
    },
  },
  methods: {
    inputChanged() {
      this.errors = [];
      this.$store.commit("setFieldDataMutated", true);
    },
    /* checkRequiredColor() {
      let className = "";
      if (
        typeof this.inputValue == "undefined" &&
        this.fieldAttrInput != null &&
        typeof this.fieldAttrInput.class != "undefined" &&
        this.fieldAttrInput.class.includes("required")
      ) {
        if (typeof this.value == "undefined" || this.value == "") {
          className = "requiredColor";
        }
      }
      if (typeof this.inputValue != "undefined" && this.inputValue != "") {
        className = "";
      }
      if (
        typeof this.fieldAttributes.associatedOption != "undefined" &&
        this.fieldAttributes.associatedOption != null &&
        Array.isArray(this.fieldAttributes.associatedOption)
      ) {
        if (this.fieldAttributes.associatedOption.length == 1) {
          className = "";
        }
      }
      console.log("className--", className);
      return className;
    },*/
    /*    checkValue(){
      var type = false;

      if(typeof this.inputValue=="undefined" &&
        this.value==""  &&
        this.fieldAttrInput!=null  &&
        typeof this.fieldAttrInput.class != "undefined" &&
        this.fieldAttrInput.class.includes("required")
      ){
        type = true;
      }

      if(typeof this.localOptions!='undefined' && Object.keys(this.localOptions).length==1){
        type = false;
      }
      return type;
    },*/
    tooltipSource:tooltipSource,
    emitKeyUp(event) {
      if (
          typeof this.localFieldAttrInputArray.class != "undefined" &&
          this.localFieldAttrInputArray.class.includes("addable") &&
          typeof this.localFieldAttrInputArray.multiple == "undefined"
      ) {
        // force the calculation of value for addable on keyup
        this.keyUpValue = event.target.value;
        this.$emit("changeIsValid", true);
        if (this.value == "") {
          this.$emit("input", event.target.value);
        }
      }
      this.errors = [];
      this.$emit("keyup", event);
    },
    // onChangeCombo(event){
    //   console.log(event.value);
    //   this.$emit('combo-changed',event.value);
    // },
    getSelectRules: getSelectRules,
    async getAsyncOptions() {
      if (typeof this.fieldAttributes.asyncOptions == "function") {
        //console.log("calling async funcation");
        this.asyncOptions = await this.fieldAttributes.asyncOptions(
            this.templateContent,
            this
        );
        //console.log("done async funcation", this.asyncOptions);
      }
    },
    calculateValue(givenValue) {
      let inputValue = "";
      if (typeof givenValue != "undefined" && givenValue != "") {
        // you should not send empty
        let value;
        if (
            Array.isArray(this.value)
            /* ||
            (typeof this.localFieldAttrInputArray.class != "undefined" &&
              this.localFieldAttrInputArray.class.includes("addable"))*/
        ) {
          value = givenValue;
        } else {
          if (this.options != null) {
            if (Array.isArray(this.options)) {
              this.options.forEach(function (option) {
                if (Array.isArray(option)) {
                  if (option[0] == givenValue) {
                    value = option[1];
                    return;
                  }
                } else {
                  if (option == givenValue) {
                    value = option;
                    return;
                  }
                }
              });
            } else {
              value = this.options[givenValue];
            }
          }
        }
        if (
            value != null ||
            (typeof this.localFieldAttrInputArray.class != "undefined" &&
                this.localFieldAttrInputArray.class.includes("addable"))
        ) {
          if (typeof value == "object") {
            inputValue = {
              text: value.name,
              value: givenValue.toString(),
              ...value,
            };
          } else if (typeof value != "undefined") {
            inputValue = {
              text: value.toString(),
              value: givenValue.toString(),
            };
          } else if (
              typeof this.localFieldAttrInputArray.class != "undefined" &&
              this.localFieldAttrInputArray.class.includes("addable")
          ) {
            inputValue = {
              text: givenValue.toString(),
              value: givenValue.toString(),
            };
          }
        }
      }
      //console.log(inputValue);
      return inputValue;
    },
    setValue(selectedValue) {
      //console.log(selectedValue);
      let sentValues = selectedValue;
      let sentText = selectedValue;
      if (typeof selectedValue == "object") {
        if (Array.isArray(selectedValue)) {
          sentValues = [];

          selectedValue.forEach(function (value) {
            if (typeof value == "object") {
              sentValues.push(value.value);
            } else {
              sentValues.push(value);
            }
          });
        } else {
          if (selectedValue != null) {
            sentValues = selectedValue.value;
            sentText = selectedValue.text;
          }
        }
      }
      //console.log(sentValues,sentText);
      this.$emit("input", sentValues, sentText, selectedValue);

      this.errors = [];
      this.setVersion(selectedValue);
    },
    setVersion(selectedValue) {
      if (
          typeof this.localFieldAttrInputArray.class != "undefined" &&
          this.localFieldAttrInputArray.class.search("versionSaver") > -1 &&
          selectedValue != ""
      ) {
        //console.log("versionSaver", this.options, selectedValue);
        let name = this.field;
        if (typeof this.options[selectedValue] != "undefined") {
          let selectedObject = this.options[selectedValue];
          let value = selectedValue;
          //console.log(selectedObject);
          let version = selectedObject.version;
          let data = {...this.$store.state.additionalSubmitData};
          if (typeof version != "undefined") {
            if (typeof data[name + "_version"] == "undefined") {
              data[name + "_version"] = {};
            }
            data[name + "_version"][value] = version;
          }
          this.$store.commit("additionalSubmitDataChanged", data);
        }
      }
    },
  },
  computed: {
    rules() {
      return getSelectRules(
          this.localFieldAttrInputArray,
          this.templateContent
      );
    },
    id() {
      return getFieldId(
          this.localFieldAttrInputArray,
          this.templateContent,
          this.field
      );
    },
    localLabel() {
      let localLabel = this.fieldAttributes.name;
      /*  if (
        typeof this.localFieldAttrInputArray.class != "undefined" &&
        this.localFieldAttrInputArray.class.includes("required") &&
        localLabel != ""
      ) {
        localLabel = localLabel + "*";
      }*/
      return localLabel;
    },
    autoselect() {
      let autoselect = true;
      if (
          typeof this.localFieldAttrInputArray.class != "undefined" &&
          this.localFieldAttrInputArray.class.includes("addable")
      ) {
        autoselect = false;
      }
      return autoselect;
    },
    localDisabled() {
      let localDisabled = this.disabled;

      // if (
      //   this.localFieldAttrInputArray != null &&
      //   typeof this.localFieldAttrInputArray.disabled != undefined
      // ) {
      //   localDisabled = this.localFieldAttrInputArray.disabled;
      // }

      // let disabled = false;
      if (
          (typeof this.templateContent != "undefined" &&
              typeof this.templateContent.disableAllInputs != "undefined" &&
              this.templateContent.disableAllInputs == true) ||
          (this.localFieldAttrInputArray != null &&
              typeof this.localFieldAttrInputArray.disabled != "undefined" &&
              this.localFieldAttrInputArray.disabled == true)
      ) {
        localDisabled = true;
      }
      // return disabled;

      return localDisabled;
    },
    placeholder() {
      return getPlaceholder(this.localFieldAttrInputArray);
    },
    noDataLabel() {
      let label = getNoDataLabel(this.localFieldAttrInputArray, this);
      return label;
    },
    options() {
     //console.log(this.fieldAttributes.options);
      let caller = this;
      let options;
      if (this.asyncOptions != null) {
        options = this.asyncOptions;
      } else {
        if (typeof caller.fieldAttributes.associatedOption != "undefined") {
          options = caller.fieldAttributes.associatedOption;
        } else if (typeof caller.fieldAttributes.IndependentOption != "undefined" ) {
          options = caller.fieldAttributes.IndependentOption;
          if (options != null && !Array.isArray(options)) {
            options = Object.values(options);
          }
        } else if (typeof caller.fieldAttributes.options != "undefined") {
          options = caller.fieldAttributes.options;
          //console.log(options);
        }
      }
        //console.log(options, this.field);
      return options;
    },

    hasSelectionSlot() {
      return !!this.$slots.selection || !!this.$scopedSlots.selection;
    },
    inputValue: {
      get: function () {
        let inputValue = "";

        if (
            this.localOptions != null &&
            this.localOptions.length == 1 &&
            this.localOptions[0].value != this.value &&
            typeof this.localFieldAttrInputArray.multiple == "undefined" &&
            !(
                typeof this.localFieldAttrInputArray.class != "undefined" &&
                this.localFieldAttrInputArray.class.includes("addable")
            ) &&
            typeof this.localFieldAttrInputArray.class != "undefined" &&
            this.localFieldAttrInputArray.class.includes("required")
        ) {
          // console.log("set first value", this.localFieldAttrInputArray);
          this.setValue(this.localOptions[0]);
        }
        if (Array.isArray(this.value)) {
          inputValue = [];
          let caller = this;
          this.value.forEach(function (givenValue) {
            inputValue.push(caller.calculateValue(givenValue));
          });
        } else {
          inputValue = this.calculateValue(this.value);
        }
        //console.log(inputValue, this.value, this.fieldAttributes.name);

        return inputValue;
      },
      set: function (selectedValue) {
        if (selectedValue != "") {
          //this.checkError=false;
          // this.checkColor = "";
        }
        this.setValue(selectedValue);
      },
    },
    localOptions: function () {
      let localOptions = [];
      let valueFound = false;
      if (
          (typeof this.localFieldAttrInputArray.class == "undefined" ||
              !this.localFieldAttrInputArray.class.includes("required")) &&
          typeof this.localFieldAttrInputArray.multiple == "undefined" &&
          this.fieldAttributes.ignoreEmptyAdd != true
      ) {
        localOptions.push({text: "", value: ""});
      }
      // console.log(this.fieldAttributes.associatedOption);
      if (typeof this.options != "undefined") {
        if (Array.isArray(this.options)) {
          let caller = this;
          let newText = "";
          this.options.forEach(function (value) {
            if (typeof value == "object") {
              if (Array.isArray(value)) {
                let disabledVal = false; //most probably useful in Network countries
                if (typeof value[3] != "undefined" && value[3] == "disabled") {
                  disabledVal = true;
                }
                  newText = value[1].toString();
                if(typeof caller.fieldAttributes.showValueInText!=='undefined' && caller.fieldAttributes.showValueInText===true){
                    newText = value[1].toString()+ " ("+ value[0].toString() +")";
                }
                localOptions.push({
                  text: newText,
                  value: value[0].toString(),
                  ...value,
                  disabled: disabledVal,
                  // divider: true,
                });
              }
            } else {
              localOptions.push({
                text: value.toString(),
                value: value.toString(),
                //disabled:true,
                //divider: true,
              });
            }
            //console.log(caller.value);
            if (caller.value!=null && value.toString() == caller.value.toString()) {
              valueFound = true;
            }
          });
        } else {
          if (this.options != null) {
            for (let [key, value] of Object.entries(this.options)) {
              if (typeof value == "object") {
                // console.log(value);

                localOptions.push({
                  text: value.name,
                  value: key.toString(),
                  ...value,
                });
              } else {
                localOptions.push({
                  text: value,
                  value: key.toString(),
                });
              }
              if (value.toString() == this.value.toString()) {
                valueFound = true;
              }
            }
          }
        }
      }
      if (
          typeof this.localFieldAttrInputArray.class != "undefined" &&
          this.localFieldAttrInputArray.class.includes("addable") &&
          !valueFound
      ) {
        //Adding tag into list but only new which is not already in list
        let array1 =
            typeof this.fieldAttributes.value != "undefined"
                ? this.fieldAttributes.value
                : this.fieldAttributes.associatedOption;
        let array2 = this.value;
        if (Array.isArray(array1) && Array.isArray(array2)) {
          let newArrival = array2.filter((x) => !array1.includes(x));
          newArrival.forEach(function (newVal) {
            localOptions.push({
              text: newVal,
              value: newVal,
            });
          });
        }
      }
      if (
          typeof this.fieldAttributes.sort == "undefined" ||
          this.fieldAttributes.sort == true
      ) {
        localOptions = localOptions.sort((a, b) => (a.text.toLowerCase().localeCompare(b.text.toLowerCase())/*a.text > b.text ? 1 : -1*/));
      }
      localOptions = localOptions.map((x, index) => {
        x.itemOrder = index;
        return x;
      });
      //console.log(localOptions);
      return localOptions;
    },
    localFieldAttrInputArray() {
      let localFieldAttrInputArray = {};
      if (
          typeof this.fieldAttrInput == "undefined" ||
          this.fieldAttrInput == "" ||
          this.fieldAttrInput == null
      ) {
        localFieldAttrInputArray = getfieldAttrInput(this.fieldAttributes);
      } else {
        localFieldAttrInputArray = this.fieldAttrInput;
      }
      // console.log(localFieldAttrInputArray);
      return localFieldAttrInputArray;
    },
  },
  props: {
    fieldAttrInput: {
      type: Object,
      default() {
        return null;
      },
    },
    fieldAttributes: Object,
    type: String,
    field: String,
    value: {default: ""},
    disabled: Boolean,
    templateContent: {},
    hideDetails: {type: Boolean, default: false}
  },
};
</script>
<style scoped>
.associated-options {
  display: flex;
}
.associated-options >>> .help-button {
  margin-top: 2px;
}

.requiredColor.v-text-field--outlined fieldset {
  color: red !important;
}
</style>

