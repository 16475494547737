import { useRoute, useRouter } from "vue-router/composables"
import { unref, readonly } from "vue";
import useBrowserStorage from "@/composition/browser-storage";
import { getUpdatedSortBy } from "@/composition/filtering-components/use-sorting-w-router"

export const ITEMS_PER_PAGE_OPTIONS_DEFAULT = [10, 25, 50, 100, 250, 500, 1000]
export const DEFAULT_SETTINGS = {
  sortBy: '',
  descending: false,
  itemsPerPage: 25,
}

/**
 * @param {Object} options
 * @param {string} options.key
 * @param {Object=} options.defaultSettings
 * @param {Object=} options.userSettings
 */
export const useTableSettings = ({ key, defaultSettings = DEFAULT_SETTINGS, userSettings = null }) => {
  const {
    model: settings,
  } = useBrowserStorage('localStorage', key, { ...defaultSettings, ...(userSettings || {}) })

  const updateSettings = (fn) => {
    const newSettings = fn(unref(settings))
    settings.value = newSettings
  }

  /**
   * @param {number} itemsPerPage
   */
  const updateItemsPerPage = (itemsPerPage) => {
    updateSettings((settings) => ({
      ...settings,
      itemsPerPage,
    }))
  }

  /**
   * @param {string} headerValue
   */
  const updateSortBy = (headerValue) => {
    updateSettings((settings) => ({
      ...settings,
      ...getUpdatedSortBy(headerValue, settings),
    }))
  }

  return {
    settings: readonly(settings),
    updateSettings,
    updateSortBy,
    updateItemsPerPage,
  }
}

export function useTableSettingsWithRouter({ key, defaultSettings = DEFAULT_SETTINGS, userSettings = null }) {
  const {
    settings,
    updateSettings,
    updateSortBy,
    updateItemsPerPage,
  } = useTableSettings({ key, defaultSettings, userSettings})
  const route = useRoute()
  const router = useRouter()

  const updateRouter = () => {
    const newQuery = { ...route.query }
    for (const [s, v] of Object.entries(settings.value)) {
      newQuery[s] = v.toString()
    }

    router.replace({ ...route, query: newQuery })
      .catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          throw err
        }
      })
  }

  const updateSettingsWithRouter = (fn) => {
    updateSettings(fn)
    updateRouter()
  }
  /**
   * @param {string} headerValue
   */
  const updateSortByWithRouter = (headerValue) => {
    updateSortBy(headerValue)
    updateRouter()
  }
  /**
   * @param {number} itemsPerPage
   */
  const updateItemsPerPageWithRouter = (itemsPerPage) => {
    updateItemsPerPage(itemsPerPage)
    updateRouter()
  }

  return {
    settings,
    updateSortBy: updateSortByWithRouter,
    updateSettings: updateSettingsWithRouter,
    updateItemsPerPage: updateItemsPerPageWithRouter,
  }
}

