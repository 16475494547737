<template>
  <div>
    <v-form
        ref="form"
        v-model="valid"
        class="explorer-configuration"
        @input="$emit('valid-change', $event);"
        :disabled="disabled">
      <XSwitch :disabled="disabled" v-model="dataValue.active" label="Explorer active" type="numberString" @input="emitInput" />
      <div class="top-part">
        <div class="top-left-part">
          <InputRow>
            <XTextField
                :disabled="disabled"
                v-model="dataValue.alias"
                label="Alias"
                class="explorer-configuration-input-row-child"
                @input="emitInput"/>
            <XSelect
                :disabled="disabled"
                :value="dataValue.devicePool"
                label="Device Pool"
                :items="devicePools"
                :item-text="formatDevicePool"
                required
                class="explorer-configuration-input-row-child"
                @input="checkDevicePoolChange"/>
          </InputRow>
          <TagSelect  :disabled="disabled" v-model="dataValue.tags" :items="tags" @input="emitInput"/>
          <InputRow expand-children>
            <XTextField
                :disabled="disabled"
                type="float"
                v-model="dataValue.configuration.location.latitude"
                label="Latitude"
                class="explorer-configuration-input-row-child"
                @input="emitInput"/>
            <XTextField
                :disabled="disabled"
                type="float"
                v-model="dataValue.configuration.location.longitude"
                label="Longitude"
                class="explorer-configuration-input-row-child"
                @input="emitInput"/>
          </InputRow>
        </div>
        <XTextarea
            v-model="dataValue.description"
            :class="`description ${dataValue.description ? '' : 'empty'}`"
            label="Description"
            @input="emitInput"/>
      </div>
      <HeadlineBox headline="IP Configuration" padded v-if="value?.type!=='sim-server'">
        <div class="ip-configuration">
          <object-input-row v-model="dataValue.configuration.testing" :columns="testingColumns" @input="emitInput"/>
          <object-input-row v-model="dataValue.configuration.network" :columns="ipTypeColumns" @input="emitInput"/>
          <div v-if="dataValue.configuration.network.type === 'static'" class="ip-types">
            <object-input-row v-model="dataValue.configuration.network.ipv4" :columns="ipV4Columns" @input="emitInput"/>
            <object-input-row v-model="dataValue.configuration.network.ipv6" :columns="ipV6Columns" @input="emitInput"/>
          </div>
        </div>
      </HeadlineBox>
    </v-form>
    <YesNoDialog
        v-model="selectBusinessStatusDialog"
        title="Select Business Status"
        @yes="setNewDevicePool"
        yes-text="Select"
        yes-color="primary"
        no-text="Cancel">
      <div class="select-business-status-dialog-form">
        <div>
          The new device pool has a different business workflow.
        </div>
        <div>
          Please select a business status from the new business workflow to assign to the explorer.
        </div>
        <XSelect v-model="newBusinessStatus" :items="businessStatuses" item-value="value" item-text="text" required/>
      </div>
    </YesNoDialog>
    <LoadingDialog v-model="loading"/>
  </div>
</template>

<script>
import XTextField from '@/components/basic/XTextField.vue';
import XTextarea from '@/components/basic/XTextarea.vue';
import ObjectInputRow from '@/components/basic/ObjectInputRow.vue';
import XSwitch from '@/components/basic/XSwitch.vue';
import HeadlineBox from '@/components/basic/HeadlineBox.vue';
import TagSelect from '@/components/specific/Explorer/TagSelect.vue';
import {deepCopy, getRequiredMessage, setDefaultValues} from '@/js/general';
import XSelect from '@/components/basic/XSelect.vue';
import cockpitExplorerService from '@/js/services/CockpitExplorerService';
import InputRow from '@/components/basic/InputRow.vue';
import LoadingDialog from '@/components/basic/LoadingDialog.vue';
import YesNoDialog from '@/components/extended/YesNoDialog.vue';

export default {
  name: 'ExplorerConfiguration',
  components: {
    YesNoDialog,
    LoadingDialog,
    InputRow,
    XSelect,
    TagSelect,
    HeadlineBox,
    ObjectInputRow,
    XSwitch,
    XTextField,
    XTextarea,
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      dataValue: null,
      getRequiredMessage: getRequiredMessage,
      tags: [],
      testingColumns: [
        {
          type: 'checkbox',
          value: 'enableIPv4',
          label: 'Enable IPv4 Testing',
        },
        {
          type: 'checkbox',
          value: 'enableIPv6',
          label: 'Enable IPv6 Testing',
        },
      ],
      ipTypeColumns: [
        {
          type: 'radio',
          value: 'type',
          direction: 'row',
          items: [
            {
              value: 'dhcp',
              text: 'DHCP',
            },
            {
              value: 'static',
              text: 'Static',
            },
          ],
        },
        {
          type: 'spacer',
        },
        {
          type: 'span',
          value: 'Fallback to DHCP after',
          condition: () => this.dataValue.configuration.network.type === 'static',
        },
        {
          type: 'int',
          value: 'fallback',
          width: 60,
          required: true,
          condition: () => this.dataValue.configuration.network.type === 'static',
        },
        {
          type: 'span',
          value: 's.',
          condition: () => this.dataValue.configuration.network.type === 'static',
        },
      ],
      ipV4Columns: [
        {
          type: 'span',
          value: 'v4',
        },
        {
          type: 'text',
          value: 'ip',
          label: 'IP address',
          width: '20%',
          required: true,
          rules: ['ipv4'],
        },
        {
          type: 'text',
          value: 'maskOrCidr',
          label: 'Mask/CIDR',
          width: '20%',
          rules: ['maskOrCidr'],
        },
        {
          type: 'text',
          value: 'gateway',
          label: 'Gateway',
          required: true,
          rules: ['ipv4'],
        },
        {
          type: 'text',
          value: 'dns1',
          label: 'DNS1',
          rules: ['ipv4'],
        },
        {
          type: 'text',
          value: 'dns2',
          label: 'DNS2',
          rules: ['ipv4'],
        },
      ],
      ipV6Columns: [
        {
          type: 'span',
          value: 'v6',
        },
        {
          type: 'text',
          value: 'ip',
          label: 'IP address',
          width: 'calc(40% + 10px)',
          rules: ['ipv6'],
        },
        {
          type: 'text',
          value: 'gateway',
          label: 'Gateway',
          rules: [v => !this.dataValue.configuration.network.ipv6.ip || !!v || this.getRequiredMessage(), 'ipv6'],
        },
        {
          type: 'text',
          value: 'dns1',
          label: 'DNS1',
          rules: ['ipv6'],
        },
        {
          type: 'text',
          value: 'dns2',
          label: 'DNS2',
          rules: ['ipv6'],
        },
      ],
      setDefaultValues: setDefaultValues,
      valid: false,
      devicePools: [],
      loading: false,
      businessStatuses: [],
      selectBusinessStatusDialog: false,
      newDevicePool: null,
      newBusinessStatus: '',
      showNewBusinessStatus: false,
    };
  },
  created() {
    cockpitExplorerService.getDevicePools((devicePools) => {
      this.devicePools = devicePools;
    });
  },
  mounted() {
    cockpitExplorerService.getExplorerTags((tags) => {
      this.tags = tags;
    });
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.$refs.form.validate();
        this.$nextTick(() => {
          this.$emit('valid-change', this.valid);
        });
      });
    });
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value !== this.dataValue) {
          this.setDefaultValues(value, this.createDataValue());
          this.dataValue = value;
        }
      },
    },
  },
  methods: {
    createDataValue() {
      return {
        alias: '',
        tags: [],
        description: '',
        active: true,
        configuration: {
          network: {
            type: 'dhcp',
            fallback: 60,
            ipv4: {},
            ipv6: {},
          },
          testing: {
            enableIPv4: true,
            enableIPv6: true,
          },
          location: {
            latitude: '',
            longitude: '',
          },
        },
      };
    },
    emitInput() {
      this.$emit('input', deepCopy(this.dataValue));
      this.$refs.form.validate();
    },
    checkDevicePoolChange(devicePool) {
      this.loading = true;
      this.newDevicePool = devicePool;
      cockpitExplorerService.getBusinessStatusesByDevicePool(
          devicePool.id,
          this.dataValue.devicePool.id,
          (businessStatuses) => {
            if (businessStatuses.length) {
              this.businessStatuses = businessStatuses;
              this.selectBusinessStatusDialog = true;
            } else {
              this.setNewDevicePool(true);
            }
            this.loading = false;
          },
      );
    },
    setNewDevicePool(noBusinessStatus) {
      this.dataValue.devicePool = this.newDevicePool;
      if (!noBusinessStatus) {
        this.showNewBusinessStatus = true;
        this.dataValue.status.current = this.newBusinessStatus;
      }
      this.emitInput();
    },
    formatDevicePool(devicePool) {
      let formattedDevicePool = devicePool.name;
      if (this.showNewBusinessStatus) {
        formattedDevicePool += ` (${this.businessStatuses.find(x => x.value === this.newBusinessStatus).text})`;
      }
      return formattedDevicePool;
    },
  },
};
</script>

<style scoped>
.explorer-configuration {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.top-part {
  display: flex;
  gap: 16px;
}

.top-part > * {
  flex: 1 1 50%;
}

.top-left-part {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.explorer-configuration-input-row-child {
  flex-basis: 50%;
}

.location > * {
  flex: 1 1 auto;
}

.description >>> .v-input__control {
  height: calc(100% + 4px);
}

.description >>> .v-input__slot {
  height: calc(100% + 4px);
}

.ip-configuration, .ip-types {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.select-business-status-dialog-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>