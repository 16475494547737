<script setup>
import { inject, defineAsyncComponent } from 'vue';
import AppBar from '@/components/specific/menu/AppBar.vue';
const SystemBar = defineAsyncComponent(() => import(/* webpackChunkName: "system-bar-comp" */ "@/components/specific/menu/SystemBar.vue"));

const IS_AUTH_PROVIDE = 'IS_AUTH';

const { isAuth } = inject(IS_AUTH_PROVIDE);
</script>

<template>
  <div>
    <SystemBar v-if="isAuth"/>

    <AppBar/>
  </div>
</template>

<style scoped>

</style>
