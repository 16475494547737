import { deepMerge } from "@/js/helper";

const readFromStorage = (savedState, key) => {
  const data = localStorage.getItem(key);

  if (!data) return savedState;

  try {
    savedState[key] = JSON.parse(data);
  } catch (err) {
    if (err instanceof SyntaxError) {
      savedState[key] = data;
    }

    throw err;
  }

  return savedState;
};

const writeToStorage = (key, data) => {
  if (data == null) return;

  if (typeof data === "string") {
    localStorage.setItem(key, data);
    return;
  }

  localStorage.setItem(key, JSON.stringify(data));
};

const clearStorage = (key) => {
  localStorage.removeItem(key)
};

const keyToStorageKey = (key) => key.split(".").join("-");

export const createPersistentStatePlugin = ({ reducer, filter = [], resetMutationName }) => {
  const keys = Array.from(reducer.keys());
  // key1-key2
  const storageKeys = keys.map(keyToStorageKey);
  const savedState = storageKeys.reduce(readFromStorage, {});

  return (store) => {
    // if there is data in localStorage
    if (Object.keys(savedState).length) {
      // prepare stored state for mergin with Vuex
      const newState = Object.entries(savedState).reduce((acc, [key, value]) => {
        if (!key.includes("-")) {
          acc[key] = value;
          return acc;
        }
        const path = key.split("-");
        let current = acc;
        for (let i = 0; i < path.length - 1; i++) {
          if (!current[path[i]]) {
            current[path[i]] = {};
          }
          current = current[path[i]];
        }

        current[path[path.length - 1]] = value;
        return acc;
      }, {});
      // merging, preserving values that are not stored in localStorage, but in Vuex
      store.replaceState(deepMerge({ ...store.state }, newState));
    }

    // subscribe to any mutation
    store.subscribe((mutation, state) => {
      if (mutation.type === resetMutationName) {
        for (const key of keys) {
          clearStorage(key);
        }

        return;
      }

      // if mutation in filter list, do nothing
      if (filter.length && filter.includes(mutation.type)) return;

      // copy data in localStorage
      for (const key of keys) {
        const data = reducer.get(key)(state);
        writeToStorage(keyToStorageKey(key), data);
      }
    });
  }
}
