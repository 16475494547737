const _initialState = {
  isEnabled: false,
};

const fiberNet = {
  namespaced: true,

  state: () => ({
    ..._initialState,
  }),

  getters: {
    isEnabled: state => state.isEnabled,
  },

  mutations: {
    toggleFiberNet(state, _isEnabled) {
      state.isEnabled = _isEnabled;
    },

    resetState(state) {
      Object.keys(state).forEach(key => {
        // eslint-disable-next-line no-undef
        state[key] = structuredClone(_initialState[key]);
      });
    },
  },

  actions: {},
};

export default fiberNet;
