<template>
    <v-menu top>
        <template v-slot:activator="{ on }">
            <v-btn
                    text
                    x-small
                    class="white--text"
                    v-on="on"
                    title="Show Project Members"
                    @click="checkProjectMembers">
                <v-icon  color="grey">mdi-account-group</v-icon>
            </v-btn>
        </template>
        <v-card
                max-width="500"
                class="mx-auto"
        ><v-toolbar
                color="boxHeader"
                height=40
            ><v-toolbar-title
                class="body-2 white--text"
             >{{languagePack('footer','titleProjectMembers')}} ({{Object. keys(members). length}})</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon  class="white--text" style="cursor: pointer;position: relative;left: 10px;">mdi-close</v-icon>
        </v-toolbar>

        <v-list style="min-height: 250px!important;overflow-y: scroll; max-height: 350px;">
            <div class="pl-3" v-if="Object. keys(members). length==0">
                {{languagePack('footer','labelNoPM')}}
            </div>
            <v-list-item
                    v-else
                   v-for="member in members"
                   :key="member.n_id"
            >
                 <v-list-item-icon>
<!--                   <v-icon
                       :color="member.c_status=='on'?'green':'grey'"
                       :title="member.c_status=='on'?'Online':'Offline'">mdi-star</v-icon>-->
                 <v-icon
                         color="gre'"
                         >mdi-star</v-icon>
                 </v-list-item-icon>
                 <v-list-item-content>
                     {{member.c_firstname}}
                    <!-- <v-tooltip bottom>
                         <template v-slot:activator="{ on, attrs }">
                             <div
                                     v-bind="attrs"
                                     v-on="on"
                                     style="cursor: pointer;"
                             >
                                 {{member.c_firstname}}
                             </div>
                         </template>
                         <div>
                             <v-row>
                                 <v-col>
                                     <avatar
                                             :result="member.c_avatar.url"
                                             :edit=false
                                             :textName="member.c_firstname+' '+member.c_lastname"
                                     ></avatar>
                                 </v-col>
                                 <v-col style="min-width:fit-content ">
                                     <div>{{language.componentLabel.labelName}}: {{ member.c_firstname }}  {{ member.c_lastname}} </div>
                                     <div>{{language.componentLabel.labelEmail}}: {{ member.c_email }}  </div>
                                     <div>{{language.componentLabel.labelPhone}}: {{ member.c_phone }}  </div>
                                     <div>{{language.componentLabel.labelRole}}: {{ member.role }}  </div>
                                 </v-col>
                             </v-row>
                         </div>
                     </v-tooltip>-->

                 </v-list-item-content>
              <!--    <v-list-item-avatar>
                       <avatar
                              :result="member.c_avatar.url"
                              :edit=false
                              :textName="member.c_firstname+' '+member.c_lastname"
                      ></avatar>
                 </v-list-item-avatar>-->
                </v-list-item>
            </v-list>

        </v-card>
    </v-menu>

</template>
<script>
  //import {getAvatarText} from "@/js/helper.js";
 //  import avatar from "@/components/avatar.vue";

  import requests from '@/js/requests';

  export default {
    components: {
      // avatar,
    },
    data:function(){
      return {
        members: "",
      };
    },
    methods:{

      checkProjectMembers(){
        let request = {
          function: "whoIsAwake",
          requestType: "ajax",
        };
        let caller = this;
        requests.frameworkAxiosRequest({
          method: 'post',
          url: 'serve.php?f=administration&f2=userSelfService&f3=myAccount',
          data: request,
        })
            .then(function (response) {
              caller.members=response.data.result.json;
          })
          .catch(function () {
            //handle error
          });
      }
    }
  }
</script>