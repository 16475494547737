<script>
import { defineComponent, inject } from "vue";
import MenuLinkList from "@/components/specific/menu/MenuLinkList.vue";
import RecentlyCompletedTestsMenuPreview from "@/components/specific/menu/RecentlyCompletedTestsMenuPreview.vue";
import { useTestingMenu } from "@/composition/menu/use-testing-menu";

export default defineComponent({
  name: "TestingMenu",

  components: {
    RecentlyCompletedTestsMenuPreview,
    MenuLinkList
  },

  setup() {
    const { menuSections } = useTestingMenu();
    const { closeMenu } = inject("MENU_BTN");

    return {
      menuSections,
      closeMenu,
    };
  },
});
</script>

<template>
  <div class="testing-menu">
    <div class="testing-menu__menu-links-box">
      <MenuLinkList
        title="Testing"
        :links="menuSections.configuration"
        class="testing-menu__menu-links"
        @navigation-click="() => closeMenu()"
      />

      <v-divider
        class="testing-menu__divider"
      />

      <MenuLinkList
        :links="menuSections.monitoring"
        class="testing-menu__menu-links"
        @navigation-click="() => closeMenu()"
      />

      <template v-if="menuSections.messaging">
        <v-divider
          class="testing-menu__divider"
        />

        <MenuLinkList
          :links="menuSections.messaging"
          class="testing-menu__menu-links"
          @navigation-click="() => closeMenu()"
        />
      </template>
    </div>

    <RecentlyCompletedTestsMenuPreview class="testing-menu__recently-completed-tests" />
  </div>
</template>

<style lang="scss">
$menu-width: 720px;
$menu-links-box-width: 250px;
$menu-links-component-width: 180px;
$recently-completed-tests-width: 430px;


.testing-menu {
  display: flex;
  width: $menu-width;
  gap: 20px;
  padding: 5px 15px 30px 15px;

  &__menu-links-box {
    max-width: $menu-links-box-width;
    width: 100%;
  }

  &__menu-links {
    max-width: $menu-links-component-width;
    width: 100%;
  }

  &__recently-completed-tests {
    max-width: $recently-completed-tests-width;
    width: 100%;
  }

  &__divider {
    margin: 8px 0;
  }
}
</style>
