import { MenuItem } from "./MenuItem"
import { computed } from "vue";
import { useStore } from "@/store";
import { MENU_ITEMS, MENU_SECTIONS, RESTRICTED_ORGANIZATIONS, ROLE_GROUPS } from "./config/testing-menu";

const createMenuItem = (key) => {
  const { routeName, text } = MENU_ITEMS[key]
  return MenuItem({ routeName, text })
};

const getMenuConfig = (isSuperAdmin, orgaRole) => {
  if (isSuperAdmin) {
    return MENU_SECTIONS.get(ROLE_GROUPS.FIRST_GROUP);
  }

  for (const [roleGroup, menuConfig] of MENU_SECTIONS) {
    if (roleGroup.includes(orgaRole)) {
      return menuConfig;
    }
  }

  return MENU_SECTIONS.get(ROLE_GROUPS.FIRST_GROUP);
};

export function useTestingMenu() {
  const store = useStore();
  const orgaRole = computed(() => store.getters["orgaRole"]);
  const isSuperAdmin = computed(() => store.getters["isSuperAdmin"]);
  const orgName = computed(() => store.getters["organizationName"]);

  const menuSections = computed(() => {

    const menuConfig = getMenuConfig(isSuperAdmin.value, orgaRole.value);

    return Object.entries(menuConfig).reduce((acc, [sectionKey, items]) => {
      let sectionItems = items.map(createMenuItem);

      if (RESTRICTED_ORGANIZATIONS.has(orgName.value)) {
        sectionItems = sectionItems.filter(item => item.routeName !== "previous-tests");
      }

      if (sectionItems.length > 0) {
        acc[sectionKey] = sectionItems;
      }

      return acc;
    }, {});
  });

  return {
    menuSections
  };
}
