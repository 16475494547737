<template>
  <v-btn
      :key="name"
      v-bind="attributesArray"
      :type="type"
      :value="value"
      @click="$emit('click', $event)"
      :elevation="elevation"
      :min-width="minWidth"
      min-height="40"
      class="white--text"
      :color="color"
      :disabled="disabled || (isFiberNetEnabled && label.includes('Save'))"
      :loading="loading"
      :href="href"
      :width="width"
      :fab="showIcon"
      :small="showIcon">
    <v-icon :left="!showIcon" v-if="icon">{{ icon }}</v-icon>
    <template v-if="shrink == '' || !$vuetify.breakpoint[shrink]">
      {{ label }}
    </template>
  </v-btn>
</template>

<script>
import { defineComponent } from "vue";
import { useFiberNet } from "@/composition/menu/use-fiber-net";

export default defineComponent({
  name: 'primaryButton',
  props: {
    type: String,
    attributesArray: Object,
    name: String,
    value: String,
    label: {
      Number,
      String,
    },
    icon: String,
    color: {
      type: String,
      default: 'primary',
    },
    disabled: Boolean,
    loading: Boolean,
    href: String,
    width: {
      Number,
      String,
    },
    elevation: {default: 0},
    shrink: {
      type: String,
      default: 'xsOnly',
    },
  },

  setup() {
    const { isEnabled: isFiberNetEnabled } = useFiberNet();

    return {
      isFiberNetEnabled,
    }
  },

  computed: {
    minWidth() {
      let minWidth = 130;
      if (this.shrink != '' && this.$vuetify.breakpoint[this.shrink]) {
        minWidth = 0;
      }
      return minWidth;
    },

    localWidth() {
      let localWidth = this.width;
      if (this.shrink != '' && this.$vuetify.breakpoint[this.shrink]) {
        localWidth = 40;
      }
      return localWidth;
    },

    showIcon() {
      let icon = false;
      if (this.shrink != '' && this.$vuetify.breakpoint[this.shrink]) {
        icon = true;
      }
      return icon;
    },
  },
});
</script>
