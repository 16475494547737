<template>
  <div>
    <FormDialog
        :value="value"
        :item="item"
        :item-id="item.id"
        :item-name="itemName"
        :title="computedTitle"
        :width="width"
        :height="height"
        :unpadded="unpadded"
        :disabled="viewingHistory"
        :save-button="saveButton"
        :custom-valid="externalIsValid"
        @input="$emit('input', $event)"
        @save="$emit('save', $event)"
    >

      <template #dialog-content="{valid, element, handleScrollRequest }">
        <slot name="dialog-content" :valid="valid" :element="element" :handle-scroll-request="handleScrollRequest"/>
      </template>

      <template #dialog-bottom="{save, close, valid}">
        <InputRow full-width>
          <XTextField
              v-if="historyMode"
              v-model="comment"
              label="Comment"
              class="history-form-dialog-comment"
              :disabled="!!viewingHistory"
          />

          <XBtn
              v-if="!viewingHistory"
              text="Save"
              icon="mdi-content-save"
              color="save"
              :loading="isSaveLoading"
              text-color="white"
              :disabled="!valid"
              @click="save(comment)"
          />

          <XBtn
              v-else
              text="Restore"
              icon="mdi-backup-restore"
              color="save"
              text-color="white"
              :disabled="!valid || $store.state.role > 2"
              @click="showNotificationAndSave(save)"/>
          <XBtn text="Cancel" icon="mdi-cancel" color="secondary" text-color="white" @click="close"/>
        </InputRow>
      </template>
    </FormDialog>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import FormDialog from '@/components/extended/FormDialog.vue';
import XBtn from '@/components/basic/XBtn.vue';
import XTextField from '@/components/basic/XTextField.vue';
import InputRow from '@/components/basic/InputRow.vue';

export default defineComponent({
  name: 'HistoryFormDialog',

  components: {
    InputRow,
    XTextField,
    XBtn,
    FormDialog,
  },

  props: {
    value: Boolean,
    item: Object,
    itemId: Number,
    title: String,
    width: [Number, String],
    height: [Number, String],
    unpadded: Boolean,
    historyMode: Boolean,
    viewingHistory: Boolean,
    itemName: String,
    nameHeader: {
      type: String,
      default: 'name',
    },
    isSaveLoading: {
      type: Boolean,
      default: false,
    },
    externalIsValid: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      comment: '',
    };
  },

  watch: {
    'item.history.comment': {
      immediate: true,
      handler(value) {
        if (this.viewingHistory) this.comment = value;
        else this.comment = '';
      },
    },
  },
  computed: {
    computedTitle() {
      let computedTitle = '';
      if (this.viewingHistory && this.item && this.item.history) {
        if (this.itemName) computedTitle += `${this.itemName}: `;
        computedTitle += `${this.item[this.nameHeader]} (version: ${this.item.history.version})`;
      } else {
        computedTitle = this.title;
      }
      return computedTitle;
    },
    saveButton() {
      if (this.viewingHistory) {
        return {};
      }
      return null;
    },
  },
  methods: {
    showNotificationAndSave(save) {
      this.showNotification(
        `Successfully restored version ${this.item.history.version} of ${this.item[this.nameHeader]}.`,
        3000,
      );
      save(null);
    },
  },
});
</script>

<style scoped>
.history-form-dialog-comment {
  flex-basis: 500px;
}
</style>
