<template>
  <div :id="id" :data-help="computedHelp" :class="!externalIsValid || (!valid && !contentEnabled) ? 'headline-box-invalid' : ''">
    <div :style="style" :class="headlineClass" @click="toggle">
      <!--
        When the content toggle switch is clicked.
        @event input
        @property {boolean} value - Content toggle switch value.
      -->
      <x-switch
          v-if="toggleable" v-model="dataValue" class="switch" color="success" @change="$emit('input', $event)"/>
      <div class="headline-text" v-text="headline"/>
      <HelpButton :help="computedHelp"/>
      <div class="after-headline">
        <!-- @slot The content of the headline box. -->
        <slot name="after-headline"/>
      </div>
      <v-icon v-if="collapsible && !contentEnabled" :color="!light ? 'white' : 'black'">
        mdi-chevron-down
      </v-icon>
      <v-icon v-if="collapsible && contentEnabled" :color="!light ? 'white' : 'black'">
        mdi-chevron-up
      </v-icon>
    </div>
    <div v-if="contentEnabled && !collapsible" :style="contentStyle" :class="!noBorder ? 'content' : ''">
      <!-- @slot The area after the headline text. -->
      <slot :handle-validate="handleValidate"/>
    </div>
    <div v-else-if="collapsible" v-show="contentEnabled" :style="contentStyle" :class="!noBorder ? 'content' : ''">
      <!-- @slot The area after the headline text. -->
      <slot :handle-validate="handleValidate"/>
    </div>
  </div>
</template>

<script>
import XSwitch from '@/components/basic/XSwitch';
import HelpButton from '@/components/basic/HelpButton.vue';
import {containsFalse} from '@/js/general';

/**
 * A box with a headline and a content area.
 *
 * ![HeadlineBox](../../../img/HeadlineBox.png?raw=true)
 */
export default {
  name: 'HeadlineBox',
  components: {
    HelpButton,
    XSwitch,
  },
  props: {
    id: String,
    /**
     * Whether the content area is shown or not. Only works if toggleable is true.
     * @model
     */
    value: Boolean,
    /**
     * The text displayed as the headline.
     */
    headline: String,
    /**
     * Whether the content area can be displayed and hidden with a switch.
     */
    toggleable: Boolean,
    collapsible: Boolean,
    /**
     * The padding of the content area. Overwrites standard padding. Examples: 20px, 10%.
     */
    contentPadding: [String, Number],
    /**
     * Removes padding of the content area.
     */
    noPadding: Boolean,
    /**
     * Removes border of the content area.
     */
    noBorder: Boolean,
    light: Boolean,
    help: String,
    externalIsValid: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      dataValue: false,
      valid: true,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value !== this.dataValue) {
          this.dataValue = value;
        }
      },
    },
  },
  computed: {
    contentEnabled() {
      if (!this.toggleable && !this.collapsible) return true;
      return this.dataValue;
    },
    style() {
      return this.contentEnabled ? 'border-top-left-radius: 4px; border-top-right-radius: 4px;' :
          'border-radius: 4px;';
    },
    contentStyle() {
      let style = '';
      if (this.noPadding) return style;
      let padding = '20px';
      if (this.contentPadding) {
        if (typeof this.contentPadding !== 'string' || !this.contentPadding.includes('%') ||
            !this.contentPadding.includes('px')) {
          padding = `${this.contentPadding}px`;
        } else {
          padding = this.contentPadding;
        }
      }
      style += `padding: ${padding};`;
      if (this.light) {
        style += 'border-top: none;';
      }
      return style;
    },
    headlineClass() {
      let headlineClass = 'headline-box-headline';
      if (this.collapsible) {
        headlineClass += ' clickable';
      }
      if (!this.light) {
        headlineClass += ' primary';
      } else {
        headlineClass += ' light';
      }
      return headlineClass;
    },
    computedHelp() {
      if (this.help) return this.help;
      return this.id;
    },
  },
  methods: {
    toggle() {
      if (this.collapsible) {
        this.dataValue = !this.dataValue;
        this.$emit('input', this.dataValue);
      }
    },
    handleValidate(value) {
      this.valid = !containsFalse(value);
    },
  },
};
</script>

<style scoped>
.headline-box-headline {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 5px 11px;
  color: white;
  min-height: 40px;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.headline-text {
  flex: 1 0 auto;
  font-weight: 500;
}

.light {
  color: black;
  background-color: var(--v-headline-box-light-base);
}

.after-headline {
  flex: 1 1 100%;
}

.headline-text:first-child {
  padding-left: 9px;
}

.switch {
  max-height: 30px;
  margin: 0;
  padding-top: 3px;
}

.content {
  border: 1px solid var(--v-box-border-base);
  border-radius: 0 0 4px 4px;
}

.headline-box-invalid {
  outline: 2px solid var(--v-error-base);
  border-radius: 4px;
}
</style>
