<template>
  <div
      v-if="!computedTooltip"
      @click="handleClick"
      :class="`icon-link ${computedDisabled ? 'icon-link-disabled' : ''}`"
  >
    <v-icon
      v-if="computedIcon"
      :disabled="computedDisabled"
      :color="computedIconColor"
    >
      {{ computedIcon }}
    </v-icon>

    <span v-if="computedText">
      {{ computedText }}
    </span>
  </div>

  <v-tooltip v-else top>
    <template #activator="{ on, attrs }">
      <div
          @click="handleClick"
          :class="`icon-link ${computedDisabled ? 'icon-link-disabled' : ''}`"
          v-bind="attrs"
          v-on="on"
      >
        <v-icon v-if="computedIcon" :disabled="computedDisabled" :color="computedIconColor">
          {{ computedIcon }}
        </v-icon>

        <span v-if="computedText">
          {{ computedText }}
        </span>
      </div>
    </template>

    {{ computedTooltip }}
  </v-tooltip>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: 'ClickableText',

  props: {
    value: Object,
    text: [String, Number],
    icon: String,
    iconColor: String,
    tooltip: String,
    disabled: Boolean,
  },

  methods: {
    handleClick() {
      if (this.disabled) return;
      this.$emit('click');
    },
  },

  computed: {
    computedText() {
      if (this.text) return this.text;
      if (this.value) return this.value.text;
      return undefined;
    },

    computedIcon() {
      if (this.icon) return this.icon;
      if (this.value) return this.value.icon;
      return undefined;
    },

    computedIconColor() {
      if (this.iconColor) return this.iconColor;
      if (this.value) return this.value.iconColor;
      return undefined;
    },

    computedTooltip() {
      if (this.tooltip) return this.tooltip;
      if (this.value) return this.value.tooltip;
      return undefined;
    },

    computedDisabled() {
      if (this.disabled) return this.disabled;
      if (this.value) return this.value.disabled;
      return undefined;
    },
  },
});
</script>

<style scoped>
.icon-link {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  color: var(--v-anchor-base);
}

.icon-link.icon-link-disabled {
  cursor: default;
  color: var(--v-disabled-base);
}
</style>
