<template>
  <v-form v-model="valid">
    <div>
      <v-tabs v-model="tab">
        <v-tab v-for="(localService, i) of localServices" :key="i">{{ localService.text }} ({{
            getLength(localService.value)
          }})
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="tab-items">
        <v-tab-item v-for="(localService, i) of localServices" :key="i">
          <AccordionListEditor
              :disabled="disabled"
              :value="value[localService.value]"
              :item-name="localService.text"
              @input="handleInput($event, localService.value)"
              :max="localService.value === 'PNSolDeltaQ' ? 1 : undefined"
              :default-value="defaultValue">
            <template #body="{value, input}">
              <ExplorerLocalServiceForm :value="value" :service="localService" @input="input"/>
            </template>
          </AccordionListEditor>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-form>
</template>

<script>
import AccordionListEditor from '@/components/basic/AccordionListEditor.vue';
import ExplorerLocalServiceForm from '@/components/specific/Explorer/Dialog/ExplorerLocalServiceForm.vue';
import {deepCopy} from '@/js/general';

const pnsol = 'PNSolDeltaQ';

export default {
  name: 'ExplorerLocalServices',
  components: {
    ExplorerLocalServiceForm,
    AccordionListEditor,
  },
  props: {
    value: Object,
    disabled:Boolean
  },
  data() {
    return {
      tab: 0,
      localServices: [
        {
          value: pnsol,
          text: 'PNSOL DeltaQ',
        },
        {
          value: 'iperf',
          text: 'iPerf',
        },
        {
          value: 'udpst',
          text: 'UDPST',
        },
      ],
      defaultValue: {
        enabled: true,
        restart: {
          type: 'always',
          timer: '60s-300s',
        },
      },
      valid: true,
    };
  },
  computed: {
    pnsolCount() {
      return Array.isArray(this.value) ? this.value.filter(x => x.service === pnsol).length : 0;
    },
  },
  watch: {
    pnsolCount(value) {
      this.$emit('valid', Array.isArray(value) ? value <= 1 : true);
    },
    valid(value) {
      this.$emit('valid', value);
    },
  },
  methods: {
    handleInput(value, index) {
      const newValue = deepCopy(this.value);
      newValue[index] = value;
      this.$emit('input', newValue);
    },
    getLength(index) {
      const value = this.value[index];
      if (!value) return 0;
      if (!Array.isArray(value)) return 1;
      return value.length;
    },
  },
};
</script>

<style scoped>
.tab-items {
  padding: 10px 12px;
}

.count-error {
  color: var(--v-error-base);
  font-size: 12px;
}
</style>