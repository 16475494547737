import { ref, computed } from "vue";
import { getUnixFromToByRange } from "@/js/general";
import { useRoute, useRouter } from "vue-router/composables"


/**
 * @typedef {Object} TimeRange
 * @property {Date} from
 * @property {Date} to
 * @property {number} seconds
*/

export const HOUR_IN_SECONDS = 3600
export const DAY_IN_SECONDS = 86400

/**
 * @type {TimeRange}
 */
export const DEFAULT_RANGE = {
  from: new Date((Date.now() / 1000 - DAY_IN_SECONDS) * 1000),
  to: new Date(),
  seconds: DAY_IN_SECONDS,
}

export const RANGE_QURY_PARAMS = {
  from: 'from',
  to: 'to',
  seconds: 'seconds',
}

export function useTimeRangeSelect({ defaultRange = DEFAULT_RANGE } = {}) {
  /**
   * @type {import("vue").Ref<TimeRange>}
   */
  const range = ref(defaultRange)

  /**
   * @type {import("vue").ComputedRef<{from: number, to: number}>}
   */
  const rangeAsReqParams = computed(() => {
    return getUnixFromToByRange(range.value)
  })

  return {
    range,
    rangeAsReqParams,
  }
}

export function useTimeRangeSelectWithRouter({ defaultRange = DEFAULT_RANGE } = {}) {
  const route = useRoute()
  const router = useRouter()
  /**
   * @returns {TimeRange}
   */
  const getRangeFromQuery = () => {
    const q = route.query
    const from = q.from ? new Date(parseInt(`${q.from}`)) : defaultRange.from
    const seconds = q.seconds ? parseInt(`${q.seconds}`) : defaultRange.seconds
    const to = q.to ? new Date(parseInt(`${q.to}`)) : defaultRange.to

    return {
      from,
      seconds,
      to,
    }
  }
  const { range, rangeAsReqParams } = useTimeRangeSelect({ defaultRange: getRangeFromQuery() })

  const rangeWithRouter = computed({
    get() {
      return range.value
    },
    /**
    * @param {TimeRange} newRange
    */
    set(newRange) {
      const newQuery = { ...route.query }

      for (const key of Object.keys(RANGE_QURY_PARAMS)) {
        delete newQuery[key]
      }

      if (newRange.seconds) {
        newQuery[RANGE_QURY_PARAMS.seconds] = newRange.seconds.toString()
      } else {
        newQuery[RANGE_QURY_PARAMS.from] = newRange.from.getTime().toString()
        newQuery[RANGE_QURY_PARAMS.to] = newRange.to.getTime().toString()
      }

      router.replace({ ...route, query: newQuery })
        .catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err
          }
        })

      range.value = newRange
    }
  })

  return {
    range: rangeWithRouter,
    rangeAsReqParams,
  }
}
