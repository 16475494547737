<template>
  <div class="split-button">
    <template v-if="values">

      <template v-if="!toPath">
        <button
          v-for="(split, i) of splits"
          :key="i"
          :style="{ width: btnsWidths.get(split.key) || '100%' }"
          :class="['split-button__child-btn', getSplitColor(split)]"
          @click="() => handleClick(split)"
        >
          <span class="split-button__btn-txt">
            {{ getButtonText(split) }}
          </span>

          <v-tooltip top>
            <template #activator="{ attrs, on }">
              <div
                v-bind="attrs"
                v-on="on"
                class="split-button__tooltip-trigger">&nbsp;</div>
            </template>

            <span class="split-button__btn-tooltip-txt">
              {{ getButtonText(split) }}
            </span>
          </v-tooltip>
        </button>
      </template>

      <template v-else>
        <router-link
          v-for="(split, i) of splits"
          :key="i"
          :to="typeof toPath === 'function' ? toPath(split) : toPath"
          :style="{ width: btnsWidths.get(split.key) || '100%' }"
          :class="[
            'split-button__child-btn',
            'split-button__child-btn--link',
            getSplitColor(split)
          ]"
        >
          <span class="split-button__btn-txt">
            {{ getButtonText(split) }}
          </span>

          <v-tooltip top>
            <template #activator="{ attrs, on }">
              <div
                v-bind="attrs"
                v-on="on"
                class="split-button__tooltip-trigger">&nbsp;</div>
            </template>

            <span class="split-button__btn-tooltip-txt">
              {{ getButtonText(split) }}
            </span>
          </v-tooltip>
        </router-link>
      </template>
    </template>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue"
import { DEFAULT_STATUS } from "@/composition/filtering-components/use-status-bar-select"

export default defineComponent({
  name: 'SplitButton',
  props: {
    value: {
      type: [Number, Array],
      default: undefined,
    },
    values: Object,
    splits: Array,
    total: Number,
    groupLink: String,
    noClick: Boolean,
    multiple: {
      type: Boolean,
      default: false,
    },
    toPath: {
      type: [String, Object, Function],
      default: undefined,
    }
  },
  setup(props) {
    const computedTotal = computed(() => {
      if (props.total) return props.total;
      return props.splits.reduce((acc, split) => acc + props.values[split.key], 0)
    })

    const btnsWidths = computed(() => {
      const m = new Map
      for (const split of props.splits) {
        const key = split.key
        const value = props.values[key]
        if (!value) continue;

        m.set(key, `${value / computedTotal.value * 100}%`)
      }
      return m
    })

    return {
      computedTotal,
      btnsWidths,
    }
  },

  methods: {
    getSplitText({key, value}) {
      const split = this.splits.find(x => x.key === key);

      if (!split) return ''

      if (!split.text) return this.values[key].toString();

      if (typeof split.text === 'function') return split.text(value, key).toString();

      return split.text
    },

    getButtonText(split) {
      if (!split.text) return this.values[split.key];

      if (typeof split.text === 'function') return split.text(this.values[split.key], split.key);

      return split.text
    },

    handleMultipleSelection(split) {
      // eslint-disable-next-line no-undef
      const currentValues = structuredClone(this.value);
      const idx = currentValues.indexOf(split.value);
      if (idx === -1) {
        currentValues.push(split.value);
      } else {
        currentValues.splice(idx, 1);
      }

      this.$emit("input", currentValues);
    },

    handleSingleSelection(split) {
      this.$emit('input', split.value);
      const clickParams = split.clickParams ? split.clickParams : [];
      this.$emit('split-click', split.key, this.values[split.key], ...clickParams);
      if (split.click) split.click(split.key, this.values[split.key], ...clickParams);
    },

    handleClick(split) {
      if (this.noClick) return;

      if (this.multiple) {
        this.handleMultipleSelection(split);
      } else {
        this.handleSingleSelection(split);
      }
    },

    getSplitColorForMultiple(split) {
      if (!this.value.length) {
        return split.color;
      }

      const isSelected = this.value.includes(split.value);
      if (isSelected) {
        return `${split.color}`;
      }
      return `${split.color} lighten-3`;
    },

    getSplitColorForSingle(split) {
      const splitKey = split.key;
      const splitColor = split.color;
      const activeSplit = this.splits.find(x => x.value === this.value);

      if (this.value === DEFAULT_STATUS.value || this.value === undefined || (activeSplit && activeSplit.key === splitKey)) {
        return splitColor;
      }
      return `${splitColor} lighten-3`;
    },

    getSplitColor(split) {
      if (this.multiple) {
        return this.getSplitColorForMultiple(split);
      }
      return this.getSplitColorForSingle(split);
    },
  },
});
</script>

<style lang="scss">
.split-button {
  $container-height: 25px;
  $font-size: 12px;
  $root: &;

  width: 100%;
  max-width: 100%;
  display: flex;
  gap: 3px;
  height: $container-height;
  cursor: pointer;

  &__child-btn {
    display: inline-block;
    height: 100%;
    width: 100%;
    padding-top: 2px;
    border: 1px solid black;
    border-radius: 4px 4px 4px 4px;
    overflow: hidden;
    white-space: nowrap;
    color: #fff;
    font-weight: 500;
    font-size: $font-size;
    cursor: pointer;
    container-type: inline-size;
    container-name: status-btn;

    #{ $root } &--link {
      text-align: center;
      color: #fff;
      text-decoration: none;

      &:active {
        color: #fff;
      }
    }
  }

  &__btn-txt {
    vertical-align: middle;
    cursor: pointer;
    text-transform: uppercase;

    //@container status-btn (max-width: var(--hide-text-width)) {
    @container status-btn (max-width: 100px) {
      display: none;
    }
  }

  &__tooltip-trigger {
    visibility: hidden;
    cursor: pointer;

    @container status-btn (max-width: 100px) {
      visibility: visible;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
