<template>
  <div class="explorers-status">
    <div>
      <v-menu min-width="245" offset-y open-on-hover top>
        <template v-slot:activator="{ on, attrs }">
          <div class="connection" v-bind="attrs" v-on="on">
            <div>
              <v-icon v-if="computedValue.online === 1" color="green">mdi-lan-connect</v-icon>
              <v-icon v-else-if="computedValue.online === 0" color="red">mdi-lan-disconnect</v-icon>
              <v-icon v-else-if="computedValue.online === 2" color="orange">mdi-lan-pending</v-icon>
            </div>
            <div>
              {{ pascalCaseToSpaced(computedValue.current) }}
            </div>
          </div>
        </template>
        <v-list dense flat>
          <div class="subtitle-1 ml-2">
            {{ language.componentLabel.explorerStatus }}
          </div>
          <div class="caption ml-2">
            {{ language.componentLabel.explorerStatusUpdateTime }}:
            {{ unixToDate(computedValue.updateTime) }}
          </div>
          <div class="caption ml-2">
            {{ language.componentLabel.explorerStatusEventTime }}: {{
              unixToDate(new Date(computedValue.eventTime))
            }}
          </div>
          <div class="caption ml-2">
            {{ language.componentLabel.explorerStatusPreviousStatus }}:
            {{ pascalCaseToSpaced(computedValue.previous) }}
          </div>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import explorerStatusService from '@/js/services/ExplorerStatusService';

export default {
  name: 'ExplorerStatus',
  props: {
    explorerName: String,
    value: Object,
  },
  data: function () {
    return {
      dataValue: {},
    };
  },
  created() {
    if (!this.value) {
      explorerStatusService.getStatus(this.explorerName, (status) => {
        this.dataValue = status;
      });
    }
  },
  methods: {
    unixToDate(tms) {
      return moment.unix(tms).format('YYYY-MM-DD HH:mm');
    },
    pascalCaseToSpaced(str) {
      if (typeof str !== 'string' || str.length === 0) {
        return '';
      }
      return str.replace(/([a-z])([A-Z])/g, '$1 $2');
    },
  },
  computed: {
    computedValue() {
      if (this.value) return this.value;
      return this.dataValue;
    },
  },
};
</script>

<style scoped>
.explorers-status >>> .connection {
  display: flex;
  gap: 5px;
  align-items: center;
}
</style>