import Service from "@/js/service.js"

const service = new Service(`${process.env.VUE_APP_AUTH_SERVICE}/auth-service`)

const authService = {
  switchContext(projectId, organizationId) {
    return new Promise((resolve, reject) => {
      service.postRequest(
        1,
        "context-switch",
        {
          project: parseInt(projectId),
          organisation: parseInt(organizationId),
        },
        resolve,
        reject
      )
    })
  },

  resetPasswordCockpit(email, code, temporaryPassword, newPassword) {
    return new Promise((resolve, reject) => {
      service.publicPatchRequest(
        1,
        "reset-password/cockpit",
        {
          email,
          code,
          temporaryPassword,
          newPassword,
        },
        resolve,
        reject
      )
    })
  },
}

export default authService
