<template>
  <div class="x-btn">
    <v-btn
        v-if="!tooltip"
        ref="button"
        :class="medium ? 'x-btn-medium' : ''"
        :color="color"
        :disabled="computedDisabled"
        :height="height"
        :icon="!!icon && !text && !floating"
        :fab="floating"
        :x-small="xSmall"
        :small="floating || small"
        :text="flat"
        :depressed="floating"
        :large="large"
        :elevation="elevation"
        :block="block"
        :min-width="minWidth"
        :min-height="minHeight"
        :style="`${computedTextColor ? `color: ${computedTextColor};` : ''}
${width ? `width: ${typeof width === 'number' ? `${width}px` : width};`: ''}`"
        :to="to"
        :loading="loading"
        @click="$emit('click', $event)">
      <v-icon v-if="icon && !iconAfterText" :left="!!text">{{ icon }}</v-icon>
      {{ text }}
      <v-icon :color="color" v-if="icon && iconAfterText" :right="!!text"> {{ icon }}</v-icon>
    </v-btn>
    <v-tooltip v-else v-model="showTooltip" v-bind="tooltipProps">
      <template v-slot:activator="{}">
        <v-btn
            ref="button"
            :class="medium ? 'x-btn-medium' : ''"
            :loading="loading"
            :color="color"
            :disabled="computedDisabled"
            :height="height"
            :icon="!!icon && !text && !floating"
            :fab="floating"
            :x-small="xSmall"
            :small="floating || small"
            :text="flat"
            :depressed="floating"
            :large="large"
            :elevation="elevation"
            :block="block"
            :min-width="minWidth"
            :min-height="minHeight"
            :style="`${computedTextColor ? `color: ${computedTextColor};` : ''}
            ${width ? `width: ${typeof width === 'number' ? `${width}px` : width};`: ''}`"
            :to="to"
            @click="$emit('click', $event)"
            @mouseenter="showTooltip = true"
            @mouseleave="showTooltip = false">
          <v-icon v-if="icon && !iconAfterText" :left="!!text">{{ icon }}</v-icon>
          {{ text }}
          <v-icon v-if="icon && iconAfterText" :right="!!text">{{ icon }}</v-icon>
        </v-btn>
      </template>

      <span v-if="tooltip" v-html="tooltip"/>
    </v-tooltip>
  </div>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: 'XBtn',
  inject: {
    formState: {
      type: Object,
      default: {
        disabled: false,
      },
    },
  },
  props: {
    tooltipProps: {
      type: Object,
      default: () => ({
        top: true,
      })
    },
    text: [String, Number],
    icon: String,
    color: String,
    textColor: String,
    tooltip: [Number, String],
    width: [Number, String],
    minWidth: [Number, String],
    minHeight: [Number, String],
    height: [Number, String],
    xSmall: Boolean,
    small: Boolean,
    medium: Boolean,
    large: Boolean,
    disabled: Boolean,
    flat: Boolean,
    elevation: [Number, String],
    to: [String, Object],
    floating: Boolean,
    iconAfterText: Boolean,
    block: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      showTooltip: false,
      whitelist: [
        'save',
        'pendingGray',
        'testStatusRunning',
        'ok',
        'testStatusWarning',
        'testStatusError',
        'testStatusAborted',
        'testStatusRuntimeWarning',
        'testStatusRuntimeError',
      ],
    };
  },
  computed: {
    computedTextColor() {
      if (this.textColor) return this.textColor;
      if (this.whitelist.includes(this.color)) return 'white';
      return undefined;
    },
    computedDisabled() {
      return this.disabled || this.formState.disabled;
    },
  },
});
</script>

<style lang="scss">
.x-btn .x-btn-medium {
  height: 40px;
  padding: 0 20px;
}

.white--text .x-btn * {
  color: white;
  caret-color: white;
}
</style>
