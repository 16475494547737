<template>
  <div class="input-row">
    <div
        v-for="(column, i) of filteredColumns" :key="i" :class="getColumnWidthClass(column)" :style="
                  `${column.visibility === 'hidden' ? 'visibility: hidden;' : ''}
                   ${column.width
                   ? `width: ${typeof column.width === 'number' ? `${column.width}px` : column.width};` : ''}`">
      <x-text-field
          v-if="getType(column) === 'text' || getType(column) === 'int' || getType(column) === 'float'"
          :ref="`column${i}`"
          :color="column.color"
          :disabled="typeof column.disabled === 'function' ? column.disabled(dataValue) : column.disabled"
          :input-type="column.inputType"
          :label="column.label"
          :output-type="column.outputType"
          :required="column.required"
          :rules="column.rules"
          :type="getType(column) === 'int' || getType(column) === 'float' ? 'number' : ''"
          :validate-immediately="validateImmediately"
          :value="dataValue[column.value ? column.value : 'value']"
          :keep-value="column.keepValue"
          style="min-width: 110px"
          @input="handleInput($event, column)"
          @validate="handleValidate($event, column)"
      />

      <XSelect
          v-else-if="getType(column) === 'select'"
          :ref="`column${i}`"
          :color="column.color"
          :disabled="typeof column.disabled === 'function' ? column.disabled(dataValue, i) : column.disabled"
          :input-type="column.inputType"
          :item-text="column.itemText"
          :item-value="column.itemValue"
          :items="typeof column.items !== 'function' ? column.items : column.items(dataValue, i)"
          :label="column.label"
          :output-type="column.outputType"
          :required="column.required"
          :rules="column.rules"
          :validate-immediately="validateImmediately"
          :value="dataValue[column.value ? column.value : 'value']"
          :combobox="column.combobox"
          :multiple="column.multiple"
          :chips="column.chips"
          :no-preselect="column.noPreselect"
          @input="handleInput($event, column)"
          :help="help"
      />

      <x-radio-group
          v-else-if="getType(column) === 'radio'"
          :ref="`column${i}`"
          :color="column.color"
          :column="column.direction ? column.direction === 'column' : undefined"
          :disabled="typeof column.disabled === 'function' ? column.disabled(dataValue, i) : column.disabled"
          :input-type="column.inputType"
          :item-text="column.itemText"
          :item-value="column.itemValue"
          :items="typeof column.items !== 'function' ? column.items : column.items(dataValue, i)"
          :label="column.label"
          :output-type="column.outputType"
          :required="column.required"
          :row="column.direction ? column.direction === 'row' : undefined"
          :rules="column.rules"
          :validate-immediately="validateImmediately"
          :value="dataValue[column.value ? column.value : 'value']"
          class="input-row-radio-group"
          @input="handleInput($event, column)"
          :help="help"
      />

      <x-switch
          v-else-if="getType(column) === 'switch'"
          :ref="`column${i}`"
          :color="column.color"
          :disabled="typeof column.disabled === 'function' ? column.disabled(dataValue, i) : column.disabled"
          :input-type="column.inputType"
          :label="column.label"
          :output-type="column.outputType"
          :validate-immediately="validateImmediately"
          :value="dataValue[column.value ? column.value : 'value']"
          class="mt-2"
          @input="handleInput($event, column)"
          :help="help"
      />

      <x-checkbox
          v-else-if="getType(column) === 'checkbox'"
          :ref="`column${i}`"
          :disabled="typeof column.disabled === 'function' ? column.disabled(dataValue, i) : column.disabled"
          :input-type="column.inputType"
          :label="column.label"
          :output-type="column.outputType"
          :validate-immediately="validateImmediately"
          :value="dataValue[column.value ? column.value : 'value']"
          @input="handleInput($event, column)"
          :help="help"
      />


      <x-btn
          v-else-if="getType(column) === 'button'"
          :color="column.color"
          :disabled="typeof column.disabled === 'function' ? column.disabled(dataValue, i) : column.disabled"
          :icon="column.icon"
          :text="column.text"
          class="input-row-button"
          v-on="column.click ? {click: column.click} : {}"/>

      <span
          v-else-if="getType(column) === 'span'"
          :style="column.color ? `color: ${column.color};` : ''"
          class="input-row-span"
          v-text="column.value"/>
      <div v-else-if="getType(column) === 'spacer'" class="fixed-width"/>
    </div>
  </div>
</template>

<script>
import XTextField from '@/components/basic/XTextField';
import XSelect from '@/components/basic/XSelect';
import XSwitch from '@/components/basic/XSwitch';
import XRadioGroup from '@/components/basic/XRadioGroup';
import XBtn from '@/components/basic/XBtn';
import XCheckbox from '@/components/basic/XCheckbox';
import {deepCopy, deepEquals} from '@/js/general';

export default {
  name: 'ObjectInputRow',
  components: {
    XCheckbox,
    XBtn,
    XRadioGroup,
    XSwitch,
    XSelect,
    XTextField,
  },
  props: {
    value: Object,
    id: Number,
    columns: Array,
    validateImmediately: Boolean,
    focusedElementIndex: {
      type: Number,
      default: -1,
    },
    help: String,
  },
  data() {
    return {
      dataValue: null,
      validColumns: {},
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!deepEquals(value, this.dataValue)) {
          if (!value) value = {};
          this.dataValue = deepCopy(value);
        }
      },
    },
    focusedElementIndex: {
      immediate: true,
      handler(value) {
        if (value >= this.id * this.columns.length && value < (this.id + 1) * this.columns.length) {
          const element = this.$refs[`column${parseInt(value % this.columns.length)}`];
          if (element) Object.values(element[0].$refs)[0].$refs['input'].focus();
        }
      },
    },
  },
  computed: {
    filteredColumns() {
      return this.columns.filter(x => x.condition === undefined
          || typeof x.condition === 'function' && x.condition(this.dataValue)
          || typeof x.condition !== 'function' && x.condition);
    },
  },
  methods: {
    getColumnWidthClass(column) {
      switch (this.getType(column)) {
        case 'text':
        case 'int':
        case 'float':
          if (!column.width) return 'flexible-width';
          else return 'fixed-width';
        case 'select':
          return 'flexible-width';
        case 'radio':
        default:
          return 'fixed-width';
      }
    },
    handleInput(value, column) {
      const oldDataValue = deepCopy(this.dataValue);
      this.$set(this.dataValue, column.value ? column.value : 'value', value);
      this.$emit('input', deepCopy(this.dataValue));
      this.$emit('revalidate');
      if (column.editable === false) {
        this.$nextTick(() => {
          this.$emit('input', oldDataValue);
          this.$emit('revalidate');
        });
      }
    },
    handleValidate(value, column) {
      this.validColumns[column.value] = value;
      this.$emit('validate', this.validColumns);
    },
    getType(column) {
      if (typeof column.type === 'function') {
        return column.type(this.value);
      }
      return column.type;
    },
  },
};
</script>

<style scoped>
.input-row {
  display: flex;
  gap: 10px;
}

.input-row-radio-group {
  margin-top: 3px;
}

.input-row >>> .v-input--radio-group.v-input--radio-group--row .v-radio:last-child {
  margin-right: 0;
}

.input-row-span {
  font-size: 16px;
  display: inline-block;
  margin-top: 7px;
}

.input-row-button {
  margin-top: 2px;
}

.flexible-width {
  flex: 1 1 100%;
}

.fixed-width {
  flex: 0 0 auto;
}
</style>
