import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Ripple from 'vuetify/lib/directives/ripple';

Vue.use(Vuetify, {directives: {Ripple}});

export const vuetifyConfig = {
  breakpoint: {
    mobileBreakpoint: 'md', // This is equivalent to a value of 960
  },
  theme: {
    options: {customProperties: true},
    themes: {
      light: {
        primary: '#2b5593',
        secondary: '#607d8b',
        accent: '#4caf50',
        error: '#ff5722',
        errorLight: '#ffc0ac',
        warning: '#ff9800',
        warningLight: '#ffd9a1',
        info: '#ffc107',
        success: '#8bc34a',
        save: '#80A957',
        upperHeader: '#184a7b',
        pageTitleHeader: '#f5f8f9',
        boxHeader: '#0e83ea',
        stepHeadColor: '#dfeffc',
        activeRow: '#bcdeab',
        pending: '#313131',
        pendingGray: '#656565',
        dark: '#000000',
        testStatusRunning: '#184a7b',
        ok: '#408e43',
        testStatusWarning: '#eeb024',
        testStatusError: '#ff0000',
        testStatusAborted: '#256025',
        red: '#ff0000',
        expectColor: '#78909c',
        disabled: '#aaaaaa',
        tableHeader: '#666666',
        tableHeaderActive: '#383838',
        tableHeaderSortArrow: '#9e9e9e',
        'table-border': '#e0e0e0',
        tableBorderDeactivated: '#8b8b8b',
        tableRowHover: '#eeeeee',
        tableRowDeactivated: '#9e9e9e',
        menuItemHover: '#e0e0e0',
        testStatusRuntimeWarning: '#FF9800',
        testStatusRuntimeError: '#FF5722',
        testStatusMaxRuntime: '#cbcb5d',
        delete: '#b82519',
        white: '#ffffff',
        'no-records': '#9e9e9e',
        'list-item-cursor': '#dcdcdc',
        'list-item-hover': '#f6f6f6',
        'gray-text': '#666666',
        'gray-text-background': '#dddddd',
        'text-on-gray-text-background': '#585858',
        text: '#ffffff',
        'text-inverted': '#ffffff',
        'tree-text': '#666666',
        'tree-content-border': '#5c9ccc',
        'tree-content-background': '#dbedff',
        separator: '#8e8e8e',
        'box-border': '#9e9e9e',
        'row-item-hover': '#5c9ccc',
        'headline-box-light': '#dfeffc',
        'system-bar': '#184a7b',
        'background': '#ffffff',
      },
      dark: {
        primary: '#525050',
      },
    },
  },
};

export default new Vuetify(vuetifyConfig);
