import axios from "axios"
import { useFiberNet } from "@/composition/menu/use-fiber-net";
import { nullEmptyObjects } from "@/js/general"
import { QueueController } from "@acctopus-fe/queue-controller"
import { tokenRefresher, TokenLifetimeKeeper } from "@/auth-tools"
import { HTTPStatusCodes } from "@acctopus-fe/http-client"
import {useStore} from '@/store';

const queueController = new QueueController()

let requests = {
  async phpServiceGetRequest(url, thenHandler, errorHandler, finallyHandler) {
    if (!(await this.isJwtTokenValid())) {
      return
    }

    const headers = {
      ...tokenRefresher.authorizationHeader,
    }

    return axios
      .get(this.getServeUrl(url), {
        headers,
        withCredentials: true,
      })
      .then((response) => this.handlePhpServiceResponse(response, thenHandler))
      .catch(errorHandler)
      .finally(finallyHandler)
  },

  async phpServicePostRequest(url, data, options = {}) {
    if (!(await this.isJwtTokenValid())) {
      return
    }

    const headers = {
      ...tokenRefresher.authorizationHeader,
    };

    options.headers = {
      ...options.headers,
      ...headers,
    };

    return axios.post(this.getServeUrl(url), data, options);
  },

  async publicPhpServiceGetRequest(url, then) {
    return axios
      .get(this.getServeUrl(url), {
        withCredentials: true,
      })
      .then((response) => this.handlePhpServiceResponse(response, then))
  },

  async handlePhpServiceResponse(response, then) {
    if (response && response.data && response.status === 200 && response.data.result) {
      const result = response.data.result
      const content = result.content
      if (content && content.includes('notice')) {
        const store = useStore()
        let message = result.message
        message = message.charAt(0).toUpperCase() + message.slice(1)
        message = message.trim()
        if (message[message.length - 1] !== '.') message += '.'
        store.commit("notification", message)
        throw new Error(message)
      }
    }
    this.handleResponse(response, then)
  },

  async getRequest(url, params, then, errorHandler) {
    if (!(await this.isJwtTokenValid())) {
      return
    }

    if (url.indexOf("://") === -1) url = `http://${url}`
    const headers = {
      ...tokenRefresher.authorizationHeader,
    }
    axios
      .get(url, {
        params: { ...params },
        headers,
      })
      .then((response) => {
        this.handleResponse(response, then)
      })
      .catch(async (error) => {
        const status = error?.response?.status
        if (status && status === HTTPStatusCodes.UNAUTHORIZED) {
          await this.refreshJwtToken()
          this.getRequest(url, params, then, errorHandler)
        } else {
          this.handleError(error, errorHandler)
        }
      })
      .catch((error) => {
        this.handleError(error, errorHandler)
      })
  },

  publicGetRequest(url, params, then, errorHandler) {
    if (url.indexOf("://") === -1) url = `http://${url}`
    axios
      .get(url, {
        params: { ...params },
      })
      .then((response) => {
        this.handleResponse(response, then)
      })
      .catch((error) => {
        console.log("Error handler: ", error)
        if (errorHandler) {
          errorHandler(error)
          return
        }
        let data = error.response.data
        error = data.error
        if (data.message) error += `: ${data.message}`
        console.error(error)
      })
  },
  async frameworkGetRequest(url, func, params, then) {
    if (!(await this.isJwtTokenValid())) {
      return
    }

    params = nullEmptyObjects(params)
    const headers = {
      ...tokenRefresher.authorizationHeader,
    }
    axios
      .get(this.getServeUrl(url), {
        params: {
          function: func,
          ajax: true,
          ...params,
        },
        headers,
        withCredentials: true,
      })
      .then((response) => this.handleResponse(response, then))
      .catch(async (error) => {
        const status = error?.response?.status
        if (status && status === HTTPStatusCodes.UNAUTHORIZED) {
          await this.refreshJwtToken()
          this.frameworkGetRequest(url, func, params, then)
        }
      })
  },

  async frameworkAxiosRequest(config = {}) {
    const { replaceData } = useFiberNet();

    config.url = this.getServeUrl(config.url)
    config.withCredentials = true

    if (requests.isPublicUrl(config.url)) {
      return axios(config)
    }

    if (!(await this.isJwtTokenValid())) {
      return
    }

    const headers = {
      ...tokenRefresher.authorizationHeader,
    }
    config.headers = headers
    return axios(config)
      .then((response) => {
        response.data = replaceData(response.data);
        return response
      })
      .catch(async (error) => {
        const status = error?.response?.status
        if (status && status === HTTPStatusCodes.UNAUTHORIZED) {
          await this.refreshJwtToken()
          this.frameworkAxiosRequest(config)
        } else {
          console.log(config)
          console.log(error)
        }
      })
      .catch((error) => {
        console.log(config)
        console.log(error)
      })
  },
  async postRequest(url, data, then, errorHandler) {
    if (!(await this.isJwtTokenValid())) {
      return
    }

    if (url.indexOf("://") === -1) url = `http://${url}`
    const headers = {
      ...tokenRefresher.authorizationHeader,
    }
    const config = {
      headers,
    }
    axios
      .post(url, data, config)
      .then((response) => this.handleResponse(response, then))
      .catch(async (error) => {
        const status = error?.response?.status
        if (status && status === HTTPStatusCodes.UNAUTHORIZED) {
          await this.refreshJwtToken()
          this.postRequest(url, data, then, errorHandler)
        } else {
          this.handleError(error, errorHandler)
        }
      })
      .catch((error) => {
        this.handleError(error, errorHandler)
      })
  },
  publicPostRequest(url, data, then, errorHandler) {
    if (url.indexOf("://") === -1) url = `http://${url}`
    axios
      .post(url, data, null)
      .then((response) => this.handleResponse(response, then))
      .catch((error) => {
        this.handleError(error, errorHandler)
      })
  },
  async frameworkPostRequest(url, func, params, then) {
    if (requests.isPublicUrl(url)) {
      return axios
        .post(
          this.getServeUrl(url),
          {
            function: func,
            ajax: true,
            ...params,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => this.handleResponse(response, then))
    }

    if (!(await this.isJwtTokenValid())) {
      return
    }

    params = nullEmptyObjects(params)
    const headers = {
      ...tokenRefresher.authorizationHeader,
    }
    axios
      .post(
        this.getServeUrl(url),
        {
          function: func,
          ajax: true,
          ...params,
        },
        {
          withCredentials: true,
          headers,
        }
      )
      .then((response) => this.handleResponse(response, then))
      .catch(async (error) => {
        const status = error?.response?.status
        if (status && status === HTTPStatusCodes.UNAUTHORIZED) {
          await this.refreshJwtToken()
          this.frameworkPostRequest(url, func, params, then)
        }
      })
  },
  putRequest(url, data, then, errorHandler) {
    if (url.indexOf("://") === -1) url = `http://${url}`
    const headers = {}
    this.putRequestWithHeaders(url, data, headers, then, errorHandler)
  },
  async putRequestWithHeaders(url, data, headers, then, errorHandler) {
    if (!(await this.isJwtTokenValid())) {
      return
    }

    if (url.indexOf("://") === -1) url = `http://${url}`
    const _headers = {
      ...headers,
      ...tokenRefresher.authorizationHeader,
    }
    const config = { headers: _headers }
    axios
      .put(url, data, config)
      .then((response) => this.handleResponse(response, then))
      .catch(async (error) => {
        const status = error?.response?.status
        if (status === HTTPStatusCodes.UNAUTHORIZED) {
          await this.refreshJwtToken()
          this.putRequestWithHeaders(url, data, headers, then, errorHandler)
          return
        }

        this.handleError(error, errorHandler)
      })
      .catch((error) => {
        this.handleError(error, errorHandler)
      })
  },
  async frameworkPutRequest(url, func, params, then) {
    if (!(await this.isJwtTokenValid())) {
      return
    }

    params = nullEmptyObjects(params)
    const headers = {
      ...tokenRefresher.authorizationHeader,
    }
    axios
      .put(
        this.getServeUrl(url),
        {
          function: func,
          ajax: true,
          ...params,
        },
        {
          withCredentials: true,
          headers,
        }
      )
      .then((response) => this.handleResponse(response, then))
      .catch(async (error) => {
        const status = error?.response?.status
        if (status && status === HTTPStatusCodes.UNAUTHORIZED) {
          await this.refreshJwtToken()
          this.frameworkPutRequest(url, func, params, then)
        }
      })
  },
  async patchRequest(url, data, then, errorHandler) {
    if (!(await this.isJwtTokenValid())) {
      return
    }

    if (url.indexOf("://") === -1) url = `http://${url}`
    const headers = {
      ...tokenRefresher.authorizationHeader,
    }
    const config = { headers }
    axios
      .patch(url, data, config)
      .then((response) => this.handleResponse(response, then))
      .catch(async (error) => {
        const status = error?.response?.status
        if (status && status === HTTPStatusCodes.UNAUTHORIZED) {
          await this.refreshJwtToken()
          this.patchRequest(url, data, then, errorHandler)
        } else {
          this.handleError(error, errorHandler)
        }
      })
      .catch((error) => {
        this.handleError(error, errorHandler)
      })
  },
  async publicPatchRequest(url, data, then, errorHandler) {
    if (url.indexOf("://") === -1) url = `http://${url}`
    axios
      .patch(url, data, null)
      .then((response) => this.handleResponse(response, then))
      .catch((error) => {
        this.handleError(error, errorHandler)
      })
  },
  async frameworkPatchRequest(url, func, params, then) {
    if (!(await this.isJwtTokenValid())) {
      return
    }

    params = nullEmptyObjects(params)
    const headers = {
      ...tokenRefresher.authorizationHeader,
    }
    axios
      .patch(
        this.getServeUrl(url),
        {
          function: func,
          ajax: true,
          ...params,
        },
        {
          withCredentials: true,
          headers,
        }
      )
      .then((response) => this.handleResponse(response, then))
      .catch(async (error) => {
        const status = error?.response?.status
        if (status && status === HTTPStatusCodes.UNAUTHORIZED) {
          await this.refreshJwtToken()
          this.frameworkPatchRequest(url, func, params, then)
        }
      })
  },
  async deleteRequest(url, params, then, errorHandler) {
    if (!(await this.isJwtTokenValid())) {
      return
    }

    if (url.indexOf("://") === -1) url = `http://${url}`
    const headers = {
      ...tokenRefresher.authorizationHeader,
    }
    const config = {
      headers,
      params,
    }
    axios
      .delete(url, config)
      .then((response) => this.handleResponse(response, then))
      .catch(async (error) => {
        const status = error?.response?.status
        if (status && status === HTTPStatusCodes.UNAUTHORIZED) {
          await this.refreshJwtToken()
          this.deleteRequest(url, params, then, errorHandler)
        } else {
          this.handleError(error, errorHandler)
        }
      })
      .catch((error) => {
        this.handleError(error, errorHandler)
      })
  },
  async frameworkDeleteRequest(url, func, params, then) {
    if (!(await this.isJwtTokenValid())) {
      return
    }

    params = nullEmptyObjects(params)
    const headers = {
      ...tokenRefresher.authorizationHeader,
    }
    axios
      .delete(this.getServeUrl(url), {
        params: {
          function: func,
          ajax: true,
          ...params,
        },
        withCredentials: true,
        headers,
      })
      .then((response) => this.handleResponse(response, then))
      .catch(async (error) => {
        const status = error?.response?.status
        if (status && status === HTTPStatusCodes.UNAUTHORIZED) {
          await this.refreshJwtToken()
          this.frameworkDeleteRequest(url, func, params, then)
        }
      })
  },

  getServeUrl(url) {
    let host = process.env.VUE_APP_PHP_SERVICE
    if (host[host.length - 1] === "/") host = host.slice(0, -1)
    url = url.replace("serve.php", "")
    url = `${host}/serve.php${url}`
    return url
  },

  handleResponse(response, then) {
    if (!response) {
      then(response)
      return
    }
    const { replaceData } = useFiberNet();
    if (
      response.data !== null &&
      typeof response.data === "object" &&
      "responseCode" in response.data
    ) {
      const responseCode = response.data.responseCode
      if (responseCode === 200 || responseCode === 201 || responseCode === 304) {
        let data = response.data
        if ("response" in data) {
          data = JSON.parse(data.response)
        }
        data = replaceData(data);
        if (then) then(data)
      } else {
        let data = response.data
        if ("response" in data) data = data.response
        else if ("header" in data) data = data.header
        const apiResponse = JSON.parse(data)
        console.error(`API error: ${apiResponse.message}`)
      }
    } else if (then && (response.status === 200 || response.status === 201 || response.status === 304)) {
      let data = response.data
      data = replaceData(data);
      then(data)
    } else if (typeof then === "function") {
      then(response)
    }
  },

  async refreshJwtToken() {
    const isRefreshed = await queueController.queue({
      key: "refresh-token",
      promiseCreator: tokenRefresher.refresh.bind(tokenRefresher),
    })

    if (!isRefreshed) {
      throw new Error("[REQUESTS]: Failed to refresh token")
    }
  },

  async validateJwtToken() {
    if (!TokenLifetimeKeeper.doesItNeedRefresh()) {
      return
    }

    await this.refreshJwtToken()
  },

  async isJwtTokenValid() {
    try {
      await this.validateJwtToken()
      return tokenRefresher.isAuthenticated
    } catch (error) {
      console.error(error)
      return false
    }
  },

  isPublicUrl(url) {
    return (
      url === "/" ||
      url === "serve.php" ||
      url.includes("=global") ||
      url.includes("=login") ||
      url.includes("=logout") ||
      url.includes("=register") ||
      url.includes("=resetPassword") ||
      url.includes("=resetPasswordRequest")
    )
  },

  handleError(error, errorHandler) {
    if (errorHandler) {
      let status = undefined
      if (error.response && error.response.status) status = error.response.status
      errorHandler(error, status)
    }
    if (error.response) {
      let data = error.response.data
      error = data.error
      if (data.message) error += `: ${data.message}`
    }
    console.error(error)
  },
}
export default requests
