<template>
  <div>
    <v-footer padless fixed class="font-weight-small" :height="$vuetify.breakpoint.smAndUp?'25':'50'">
      <template v-if="$vuetify.breakpoint.smAndUp">
      <template
        v-if="
          result != null &&
          typeof result.sdt != 'undefined' &&
          result.sdt != '' &&
          result.sdt == 1
        "
      >
        <v-switch
          dense
          flat
          v-model="$vuetify.theme.dark"
          title="Theme Switch"
          style="position: absolute; left: 10px"
        ></v-switch>
     <!--   <span v-if="typeof result.gitV != 'undefined'">
          <v-icon class="gBranch">mdi-git</v-icon>
          <span class="gbPosition" title="Git Version">{{ result.gitV }}</span>
        </span>-->
        <span class="pl-12"></span>
      </template>
      <projectmate
        v-if="userInfo != null && userInfo.loggedIn == true"
      ></projectmate>
      </template>
      <v-spacer></v-spacer>
      <v-row no-gutters style="max-width: 590px" justify="center">
        <v-col style="min-width: 310px; max-width: 310px">
          {{ language.footer.copyrights }} {{ new Date().getFullYear() }} |
          {{ language.footer.companyName }}
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          style="min-width: 155px; max-width: 155px"
        >
          <template>|   <a href="https://www.acctopus.com/" target="_blank">{{ language.footer.companyWebsite }}</a></template>
        </v-col>
        <v-col style="min-width: 120px; max-width: 120px">
          <template v-if="$vuetify.breakpoint.smAndUp">| </template>
          <a href="https://www.acctopus.com/legal/" target="_blank">Legal</a>
          |

          <a href="https://www.acctopus.com/legal/" target="_blank">Contact</a>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
    </v-footer>
  </div>
</template>



<script>
import projectmate from "@/components/legacy/projectmate.vue";
//import { notifyMe } from "@/js/desktopNotify.js";

// import snackbar from "@/components/snackbar.vue";
export default {
  components: {
    projectmate,
    // snackbar
  },
  props: ["userInfo", "result"],
};
</script>
<!--
{/if}
<footer style="text-align:center;" class="footer">
<span class="fileExist" style="display: none;">{$showFile}</span>
<span class="process" style="display: none;">{$process}</span>
<span class="isNewUser" style="display: none;">{$isNewUser}</span>
<span class="newUserSession" style="display: none;">{$newUserSession}</span>
<span class="runningTestCase" style="display: none;"></span>
<span class="pageName" style="display: none;">{{pageName}}</span>
<span class="login" style="display: none;">{$login}</span>
<span class="callPage" style="display: none;">{$callPage}</span>
<span class="qID" style="display: none;">{$qID}</span>
<span class="pID" style="display: none;">{$pID}</span>
<span class="hash" style="display: none;">{$hash}</span>
{*<span class="super" style="display: none;">{if !empty($super)}{$super}{/if}</span>*}
<span class="switchMiniValue" style="display: none;">{if !empty($miniMode)}{$miniMode}{/if}</span>
<span class="switchModeValue" style="display: none;">{if !empty($switchMode)}{$switchMode}{/if}</span>
<span style="font-size: 18px;vertical-align: middle"> &copy;</span>  {$year}{language("acctopusAddress")}
</footer>
</html></template>-->
