<template>
  <div class="dashboard-map">
    <l-map
        ref="map"
        :zoom="options.zoom"
        :center="options.center"
        :roam="options.roam"
        :silent="options.silent"
        :bounds="null">
      <l-tile-layer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"></l-tile-layer>
        <l-marker
          v-for="(marker, i) of options.markers"
          :key="i"
          :icon="getIcon(marker)"
          :lat-lng="latLng(marker.position.lat, marker.position.lng)"
          :options="getOptions(marker)"
        >
          <l-tooltip>
            <table class="dashboard-map__tooltip-table">
              <tr class="dashboard-map__tooltip-table-row">
                <td>
                  {{ marker.tag.key }}
                </td>

                <td>
                  {{ marker.tag.value }}
                </td>
              </tr>

              <tr class="dashboard-map__tooltip-table-row  dashboard-map__tooltip-table-row--green">
                <td>Online:</td>

                <td>{{ marker.status.online }}</td>
              </tr>

              <tr class="dashboard-map__tooltip-table-row  dashboard-map__tooltip-table-row--yellow">
                <td>Warning:</td>

                <td>{{ marker.status.warning }}</td>
              </tr>

              <tr class="dashboard-map__tooltip-table-row  dashboard-map__tooltip-table-row--red">
                <td>Offline:</td>

                <td>{{ marker.status.offline }}</td>
              </tr>
            </table>
          </l-tooltip>
        </l-marker>
    </l-map>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted } from "vue"
import { LMap, LTileLayer, LMarker, LTooltip } from 'vue2-leaflet';
import { latLng, divIcon } from 'leaflet';

const html = (online, warning, count) => `<div style="
                          width: 40px;
                          height: 40px;
                          position: relative;
                          margin: 0;
                          outline: 0.5px solid black;
                          border-radius: 100%;
                          -webkit-transform: translateZ(0);
                          -moz-transform: translateZ(0);
                          -ms-transform: translateZ(0);
                          -o-transform: translateZ(0);
                          transform: translateZ(0);
                          background:
                            radial-gradient(
                                circle closest-side,
                                #333333 60%,
                                transparent 0%
                            ),
                            conic-gradient(
                                var(--v-ok-base) 0,
                                var(--v-ok-base) ${online}%,
                                var(--v-warning-base) 0,
                                var(--v-warning-base) ${online + warning}%,
                                var(--v-testStatusError-base) 0,
                                var(--v-testStatusError-base) 100%
                            );">
                          <div style="
                              position: absolute;
                              width: 100%;
                              height: 100%;
                              line-height: 40px;
                              text-align: center;
                              color: white;
                              text-shadow: black 1px 1px 1px;
                              -webkit-transform: translateZ(0);
                              -moz-transform: translateZ(0);
                              -ms-transform: translateZ(0);
                              -o-transform: translateZ(0);
                              transform: translateZ(0);
                          ">
                          ${count}
                          </div>
                      </div>`;


export default defineComponent({
  name: 'DashboardMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  props: {
    options: Object,
  },
  setup() {
    const map = ref(null)
    const state = reactive({
      resizeObserver: null,
    })

    const handleResize = () => {
      if (map.value) map.value.mapObject.invalidateSize();
    }

    const getClassBasedOnMaxVal = (obj) => {
      const max = Math.max(...Object.values(obj));
      const maxKey = Object.keys(obj).find(key => obj[key] === max);

      return maxKey;
    }

    onMounted(() => {
      state.resizeObserver = new ResizeObserver(handleResize);
      state.resizeObserver.observe(map.value.$el);
    })

    return {
      ...toRefs(state),
      getClassBasedOnMaxVal,
      map,
    }
  },
  methods: {
    customMarkerName(marker){
      let eName = marker.name;
      if(typeof marker.alias!=="undefined" && marker.alias!==''){
        eName = eName + " ("+ marker.alias+")";
      }
      return  eName;
    },
    latLng(lat, lng) {
      return latLng(lat, lng);
    },
    getOptions(item) {
      const propWithMaxVal = this.getClassBasedOnMaxVal(item.status);

      const options = {
        pane: 'markerPane',
        draggable: false,
        opacity: 1,
        explorer: {
          name: item.name,
          status: item.status,
        },
        className: propWithMaxVal,
      }
      return options;
    },
    getIcon(item) {
      const { online, warning, offline } = item.status;
      const total = online + warning + offline;

      const onlinePercent = (online / total * 100);
      const warningPercent = (warning / total * 100);
      return divIcon({
        className: 'marker',
        html: html(onlinePercent, warningPercent, total),
        iconSize: [32, 32],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41],
      })
    },
  },
});
</script>

<style scoped>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>

<style lang="scss">
.dashboard-map {
  $root: &;

  &__tooltip-table-row {
    font-weight: bold;

    &#{$root}__tooltip-table-row--green {
      color: var(--v-ok-base)
    }

    &#{$root}__tooltip-table-row--yellow {
      color: var(--v-warning-base)
    }

    &#{$root}__tooltip-table-row--red {
      color: var(--v-testStatusError-base)
    }
  }


  &__loading-overlay {
    position: absolute;
    z-index: 10000;
    top: 0;
    height: 100%;
    width: 100%;
  }

  &__loading-message {
    position: absolute;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 3px 5px;
  }
}

</style>
