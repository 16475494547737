<template>
  <div :class="`input-row ${fullWidth ? 'full-width' : ''} ${expandChildren ? 'expand-children' : ''}`">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'InputRow',
  props: {
    fullWidth: Boolean,
    expandChildren: Boolean,
  },
};
</script>

<style scoped>
.input-row {
  display: flex;
  gap: 10px;
}

.input-row > .x-radio-group {
  margin-top: 3px;
}

.input-row >>> .v-input--radio-group.v-input--radio-group--row .v-radio:last-child {
  margin-right: 0;
}

.input-row > span {
  font-size: 16px;
  display: inline-block;
  margin-top: 7px;
}

.input-row > .x-btn {
  margin-top: 2px;
}

.input-row > .x-switch {
  height: 40px;
}

.input-row.full-width {
  flex-grow: 1;
}

.input-row.expand-children > * {
  flex-grow: 1;
}
</style>