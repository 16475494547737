import Service from '@/js/service.js';

const service = new Service(`${process.env.VUE_APP_IMAGE_CREATOR_SERVICE}/image-creator-service`);

const imageCreatorService = {
    create(data, then, error) {
        service.postRequest(1, 'create', data, then,
            error,
        );
    },
};

export default imageCreatorService;