<template>
  <div class="test-status-legend">
    <div v-for="(statusItem, i) of statusList" :key="i" class="legend-item">
      <div class="legend-color" :style="`background-color: var(--v-${statusItem.color}-base);`"/>
      <span>{{ testStatusTranslation(statusItem.text) }}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      statusList: [
        {
          text: 'pending',
          color: 'pendingGray',
        },
        {
          text: 'running',
          color: 'testStatusRunning',
        },
        {
          text: 'ok',
          color: 'ok',
        },
        {
          text: 'aborted',
          color: 'testStatusAborted',
        },
        {
          text: 'maxRuntime',
          color: 'testStatusMaxRuntime',
        },
        {
          text: 'processingWarning',
          color: 'testStatusRuntimeWarning',
        },
        {
          text: 'processingError',
          color: 'testStatusRuntimeError',
        },
        {
          text: 'resultWarning',
          color: 'testStatusWarning',
        },
        {
          text: 'resultError',
          color: 'testStatusError',
        },
      ],
    };
  },
  methods: {
    testStatusTranslation(text) {
      return this.language.testStatusFull[text];
    },
  },
};
</script>
<style scoped>
.test-status-legend {
  padding: 2px 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  font-size: 14px;
  color: #666666;
  background-color: #eee;
  border-radius: 4px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.legend-color {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  opacity: 0.8;
}
</style>